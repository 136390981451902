/* eslint-disable prettier/prettier */
import { CContainer, CForm, CFormCheck, CFormInput, CFormTextarea } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { TNomenclatures, TSliderItem } from '../../api/mock/typesApi'
import Modal from '../common/Modal/Modal'
import SliderEditList from './SliderEditList'
import styles from './slider.module.scss'
import InputImage from '../common/InputImage/InputImage'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { CreateSlide, EditSlide, getSlider } from '../../store/slices/SliderSlice'
import { DeleteFile, SaveFile } from '../../store/slices/FileSlice'
import { openNotification } from '../common/Notification/Notification'
import JoditEditor from 'jodit-react'

interface ISliderEditModal {
  open: boolean
  handleClose: (val?: any) => void
  payload: any | null
  saveHandler: (payload: TSliderItem) => void
  nomenclatures?: any
}

const SliderEditModal: React.FC<ISliderEditModal> = ({
  open,
  handleClose,
  payload,
  saveHandler,
  nomenclatures,
}) => {
  const nomenclature = useSelector((state: RootState) => state.SliderSlice.nomenclatures)
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<any>(payload)
  const [file, setFile] = useState<any>(null)
  const [tractorsItems, setTractorsItems] = useState<{ id: number; order: number }[]>([])
  const [newId, setNewId] = useState()
  const [currentFormData, setCurrentFormData] = useState<any | null>(null)
  const saveAndCloseHandler = () => {
    if (model.titleMain == '' || undefined || model.description == '' || undefined){
      openNotification({type:'warning', text:'Заполните заголовок и описание перед сохранением!'})
    } else {
      
    if (payload === null) {
      dispatch(
        CreateSlide({
          name: model.titleMain,
          titleMain: model.titleMain,
          description: model.description,
          supportButton: model.supportButton,
          catalogButton: model.catalogButton,
          sliderId: 1,
          nomenclatures: model.itemList,
        }),
      )
        .then((e: any) => {
          if (file) {
            const formData = new FormData()
            formData.append('Files', file)
            formData.append(
              'Json',
              JSON.stringify({
                Flag: 'I',
                Id: e?.payload?.data?.value?.value,
                Type: 'Slide',
                Files: [
                  {
                    Name: file?.name,
                    Extention: file?.name.split('.')[file.name.split('.').length - 1].toUpperCase(),
                    dataType: '2',
                    Rewrite: true,
                  },
                ],
              }),
            )
            dispatch(
              SaveFile({
                formData: {
                  Files: file,
                  Json: JSON.stringify({
                    Flag: 'I',
                    Id: e.payload.data.value.value,
                    Type: 'Slide',
                    Files: [
                      {
                        Name: file?.name,
                        Extention: file?.name
                          .split('.')
                          [file.name.split('.').length - 1].toUpperCase(),
                        dataType: '2',
                        Rewrite: true,
                      },
                    ],
                  }),
                },
              }),
            ).then(() =>
              dispatch(getSlider())
            )
          }
        })
      handleClose()
      setModel('')
    } else {
      if(file !== null || undefined){
      
      dispatch(
        EditSlide({
          id: model.id,
          name: model.name,
          titleMain: model.titleMain,
          description: model.description,
          supportButton: model.supportButton,
          catalogButton: model.catalogButton,
          nomenclatures: model.itemList,
        }),
      ).then(() => (dispatch(DeleteFile({
        Id: model.id,
        Type: 'Slide',
        Flag: 'D',
        Name: model.backgroundImage.split('/')
        [model.backgroundImage.split('/').length - 1],
      })))).then(() => dispatch(
        SaveFile({
        formData: {
          Files: file,
          Json: JSON.stringify({
            Flag: 'I',
            Id: model.id,
            Type: 'Slide',
            Files: [
              {
                Name: file?.name,
                Extention: file?.name
                  .split('.')
                  [file.name.split('.').length - 1].toUpperCase(),
                dataType: '2',
                Rewrite: true,
              },
            ],
          }),
        },
      }),)).then(() => (dispatch(getSlider())))
      handleClose()
      setModel('')
    }else{
      dispatch(
        EditSlide({
          id: model.id,
          name: model.name,
          titleMain: model.titleMain,
          description: model.description,
          supportButton: model.supportButton,
          catalogButton: model.catalogButton,
          nomenclatures: model.itemList,
        }),
      ).then(() => dispatch(getSlider()))
      handleClose()
      setModel('')
    }
    }
  }
  }
  const editor = useRef(null)
  const config = {
    readonly: false,
    language: 'ru',
    style: {
      font: '12px Arial',
      color: '#0c0c0c',
      height: '190px',
    },
    toolbarSticky: false,
    ssr: false,
  }
useEffect(() =>{
  console.log(model?.titleMain)
}, [model])
  return (
    <Modal
      header={payload ? 'Редактирование слайда' : 'Создание слайда'}
      open={open}
      handleClose={handleClose}
      onOk={() => saveAndCloseHandler()}
      okText={'Сохранить'}
    >
      <CContainer fluid>
        <CForm className={styles.form}>
          {/* <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Название</span>
            <CFormInput
              value={model?.name}
              onChange={(e) => setModel({ ...model, name: e.target.value })}
              placeholder="Название"
            />
          </div> */}
          <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Заголовок</span>
            <JoditEditor
              ref={editor}
              value={model?.titleMain || ''}
              config={config}
              // tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setModel({ ...model, titleMain: newContent })} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
            {/* <CFormInput
              value={model?.titleMain}
              onChange={(e) => setModel({ ...model, titleMain: e.target.value })}
              placeholder="Заголовок"
            /> */}
          </div>
          <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Описание</span>
            <CFormTextarea
              value={model?.description}
              rows={6}
              onChange={(e) => setModel({ ...model, description: e.target.value })}
              placeholder="Описание"
            />
          </div>
          <div className={styles.form__formInput} style={{marginTop: '10px'}}>
            <span className="text-medium-emphasis">Список тракторов</span>
            <SliderEditList
              setItems={(val) => {
                setModel({
                  ...model,
                  itemList: val.map((item, idx) => ({
                    id: item?.id,
                    order: idx,
                    name: item?.name,
                  })),
                })
                console.log(model)
              }}
              items={model?.itemList}
              nomenclatures={nomenclatures}
            />
          </div>
          <div className={styles.form__checkers}>
            <div className={styles.form__checkers__checker} style={{marginTop: '10px'}}>
              <span className="text-medium-emphasis">Кнопка &quot;Связаться с нами&quot;</span>
              <CFormCheck
                checked={model?.supportButton}
                onChange={(e) => setModel({ ...model, supportButton: !model.supportButton })}
              />
            </div>
            <div className={styles.form__checkers__checker} style={{marginTop: '10px'}}>
              <span className="text-medium-emphasis">Кнопка &quot;Каталог&quot;</span>
              <CFormCheck
                checked={model?.catalogButton}
                onChange={(e) => setModel({ ...model, catalogButton: !model.catalogButton })}
              />
            </div>
          </div>
          <div className={styles.form__formInput} style={{marginTop: '10px'}}>
            <InputImage setFile={setFile} />
          </div>
        </CForm>
      </CContainer>
    </Modal>
  )
}

export default SliderEditModal
