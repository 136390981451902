import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import styles from './slider.module.scss'
import ProgressBar from '../ProgressBar/ProgressBar'
import 'swiper/css'
import SliderItem from './SliderItem'
import Button from '../common/Button/Button'
import { IContactsSlice } from '../../store/slices/ContactsSlice'
import ContactsTiles from '../ContancsTiles/ContactsTiles'
import { cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { TNomenclatures, TSliderItem } from '../../api/mock/typesApi'
import SliderEditModal from './SliderEditModal'
import { useSlider } from './useSlider'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

interface ISliderComponent {
  items?: TSliderItem[]
  // contacts: IContactsSlice['newContactInfo']
  nomenclatures?: TNomenclatures[]
}
const SliderComponent: React.FC<ISliderComponent> = ({ items, nomenclatures }) => {
  const loadings = useSelector((state: RootState) => state.SliderSlice.loadings)
  const [swiper, setSwiper] = useState<any>(null)
  const {
    deleteHandler,
    manuallyChangeSlide,
    setStopInterval,
    currentSliderKey,
    progressValue,
    currentSliderContent,
    showNewModal,
    setShowNewModal,
    newSliderHandler,
    editSliderHandler,
  } = useSlider(swiper, items)

  const [windowWidth, setWindowWidth] = useState(-1)
  const [mobile, setMobile] = useState(windowWidth < 768 && windowWidth !== -1 ? true : false)

  const handleResize = () => {
    if (window) {
      setWindowWidth(window.innerWidth)
      windowWidth <= 768 ? setMobile(true) : setMobile(false)
    }
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    if (windowWidth <= 768 && windowWidth !== -1) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }, [windowWidth])

  return (
    <SkeletonLoading style={{ minHeight: '1000px' }} loading={checkLoadings(loadings)}>
      <div
        className={styles.parentSlider}
        onMouseEnter={() => setStopInterval(true)}
        onMouseLeave={() => setStopInterval(false)}
        style={{ padding: '20px 20px 20px 20px' }}
      >
        {mobile ? (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            onInit={(val: any) => setSwiper(val)}
            onSlideChange={(val: any) => manuallyChangeSlide(val.activeIndex, false)}
            className={styles.mainSlider}
          >
            <div className={styles.mainSlider__sliders}>
              {items?.length
                ? items.map((slide: TSliderItem, index: number) => {
                    return (
                      <SwiperSlide key={index + 'mobile'}>
                        <SliderItem
                          id={slide.id}
                          name={slide.name}
                          backgroundImage={slide.backgroundImage}
                          titleMain={slide.titleMain}
                          description={slide.description}
                          itemList={slide.itemList}
                          nomenclatures={nomenclatures}
                          supportButton={slide.supportButton}
                          catalogButton={slide.catalogButton}
                          isMobile={true}
                          deleteHandler={() => deleteHandler(index)}
                          editSliderHandler={(payload: TSliderItem) => editSliderHandler(payload)}
                        />
                      </SwiperSlide>
                    )
                  })
                : null}
            </div>
            <div className={styles.pagination}>
              {items?.length
                ? items.map((_slide: TSliderItem, index: number) => {
                    return (
                      <ProgressBar
                        className={styles.progressBar}
                        key={index}
                        containerStyle={
                          currentSliderKey > index ? { backgroundColor: '#ffffff' } : {}
                        }
                        onClick={() => manuallyChangeSlide(index, true)}
                        completed={currentSliderKey === index ? progressValue : 0}
                      />
                    )
                  })
                : null}
            </div>
          </Swiper>
        ) : (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            onInit={(val: any) => setSwiper(val)}
            onSlideChange={(val: any) => manuallyChangeSlide(val.activeIndex, false)}
            className={styles.mainSlider}
          >
            <div className={styles.mainSlider__sliders}>
              {items?.length
                ? items.map((slide: TSliderItem, index: number) => {
                    return (
                      <SwiperSlide key={index + 'noMobile'}>
                        <SliderItem
                          id={slide.id}
                          name={slide.name}
                          backgroundImage={slide.backgroundImage}
                          titleMain={slide.titleMain}
                          description={slide.description}
                          itemList={slide.itemList}
                          supportButton={slide.supportButton}
                          catalogButton={slide.catalogButton}
                          isMobile={false}
                          deleteHandler={() => deleteHandler(index)}
                          editSliderHandler={(payload: TSliderItem) => editSliderHandler(payload)}
                        />
                      </SwiperSlide>
                    )
                  })
                : null}
            </div>
            <div className={styles.pagination}>
              {items?.length
                ? items.map((_slide: TSliderItem, index: number) => {
                    return (
                      <ProgressBar
                        className={styles.progressBar}
                        key={index}
                        containerStyle={
                          currentSliderKey > index ? { backgroundColor: '#ffffff' } : {}
                        }
                        onClick={() => manuallyChangeSlide(index, true)}
                        completed={currentSliderKey === index ? progressValue : 0}
                      />
                    )
                  })
                : null}
              <div className={styles.plusIcon}>
                <CIcon size="xl" onClick={() => setShowNewModal(true)} icon={cilPlus} />
              </div>
            </div>
          </Swiper>
        )}
        {mobile && currentSliderContent ? (
          <div className={styles.mobileBlock}>
            <div className={styles.mobileBlock__sliderItemMain__content}>
              <div className={styles.mobileBlock__sliderItemMain__content__title}>
                <h1>{currentSliderContent?.titleMain}</h1>
              </div>
              <div className={styles.mobileBlock__sliderItemMain__content__description}>
                <div>{currentSliderContent?.description}</div>
              </div>
              <div className={styles.mobileBlock__sliderItemMain__content__itemList}>
                <ul>
                  {currentSliderContent?.itemList?.length &&
                    currentSliderContent?.itemList.map((item: any, index: any) => {
                      return (
                        <li key={index + ' traktor'}>
                          <span>{item}</span>
                        </li>
                      )
                    })}
                </ul>
              </div>
              <div className={styles.mobileBlock__sliderItemMain__content__buttons}>
                {currentSliderContent?.supportButton ? (
                  <Button
                    className={styles.mobileBlock__sliderItemMain__content__buttons__button}
                    color={'secondary'}
                  >
                    Связаться с нами
                  </Button>
                ) : null}
                {currentSliderContent?.catalogButton ? (
                  <Button className={styles.mobileBlock__sliderItemMain__content__buttons__button}>
                    Каталог продукции
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {/* <ContactsTiles contacts={contacts} /> */}
        <SliderEditModal
          payload={null}
          open={showNewModal}
          handleClose={() => setShowNewModal(false)}
          saveHandler={newSliderHandler}
          nomenclatures={nomenclatures}
        />
      </div>
    </SkeletonLoading>
  )
}

export default SliderComponent
