import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MockApi } from '../../api/mock/mockApi'
import {
  TCount,
  TDeleteReview,
  TPostSeo,
  TPostSeoKeys,
  TPutSeo,
  TReviewItem,
  TSeoItem,
  TSeoPage,
} from '../../api/mock/typesApi'
import { TPagination } from '../types'
import { instanceAxiosClose } from '../api/instances'
import { paths } from '../api/paths'
import { getInlineParams } from '../../helpers/helper'
import { openNotification } from '../../components/common/Notification/Notification'

export interface ISeoSlice {
  loadings: {
    getSeoLoading: boolean
  }
  seoInfo: any
  getSeoPageInfo: any
}

const initialState: ISeoSlice = {
  loadings: {
    getSeoLoading: false,
  },
  seoInfo: [],
  getSeoPageInfo: [],
}

export const getSeoInfo = createAsyncThunk('seo/getSeo', async () => {
  const { data } = await instanceAxiosClose().get(paths.seoService.seo + '/pages')
  return data?.data
})
export const getSeoPageInfo = createAsyncThunk('seo/getSeoPage', async (body: TSeoPage) => {
  const { data } = await instanceAxiosClose().get(paths.seoService.seo + getInlineParams(body))
  return data?.data
})
export const putSeo = createAsyncThunk('seo/CreateSeo', async (body: TPutSeo) => {
  const data = await instanceAxiosClose().put(paths.seoService.seo + '/pages', body)
  return data?.data
})
export const postSeoPages = createAsyncThunk('seo/PostSeo', async (body: TPostSeo) => {
  const data = await instanceAxiosClose().post(paths.seoService.seo, body)
  return data?.data
})
export const postSeoKeys = createAsyncThunk('seo/PostSeoKeys', async (body: TPostSeoKeys) => {
  const data = await instanceAxiosClose().post(paths.seoService.seo + '/keys', body)
  return data?.data
})

export const SeoSlice = createSlice({
  name: 'seo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSeoInfo.pending, (state, action) => {
      state.loadings.getSeoLoading = true
    })
    builder.addCase(getSeoInfo.fulfilled, (state, action) => {
      state.loadings.getSeoLoading = true
      state.seoInfo = action?.payload?.value?.items
    })
    builder.addCase(getSeoInfo.rejected, (state, action) => {
      state.loadings.getSeoLoading = true
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getSeoPageInfo.pending, (state, action) => {
      state.loadings.getSeoLoading = true
    })
    builder.addCase(getSeoPageInfo.fulfilled, (state, action) => {
      state.loadings.getSeoLoading = true
      state.getSeoPageInfo = action?.payload?.value
    })
    builder.addCase(getSeoPageInfo.rejected, (state, action) => {
      state.loadings.getSeoLoading = true
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(putSeo.pending, (state, action) => {
      state.loadings.getSeoLoading = true
    })
    builder.addCase(putSeo.fulfilled, (state, action) => {
      state.loadings.getSeoLoading = true
      state.seoInfo = action?.payload?.value?.items
    })
    builder.addCase(putSeo.rejected, (state, action) => {
      state.loadings.getSeoLoading = true
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(postSeoPages.pending, (state, action) => {
      state.loadings.getSeoLoading = true
    })
    builder.addCase(postSeoPages.fulfilled, (state, action) => {
      state.loadings.getSeoLoading = true
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(postSeoPages.rejected, (state, action) => {
      state.loadings.getSeoLoading = true
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(postSeoKeys.pending, (state, action) => {
      state.loadings.getSeoLoading = true
    })
    builder.addCase(postSeoKeys.fulfilled, (state, action) => {
      state.loadings.getSeoLoading = true
    })
    builder.addCase(postSeoKeys.rejected, (state, action) => {
      state.loadings.getSeoLoading = true
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
  },
})
export default SeoSlice.reducer
