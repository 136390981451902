// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newCss_sidebarOverflow__oUfy1 {
  overflow: none !important;
  overflow-y: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/components/newCss.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,6BAAA;AACJ","sourcesContent":[".sidebarOverflow{\n    overflow: none !important;\n    overflow-y: hidden !important;\n}\n// .sidebarOverflow::-webkit-scrollbar {\n//     width: 6px !important;\n//     height: 6px !important;\n//     display: none !important;\n//     /* width of the entire scrollbar */\n// }\n\n// .sidebarOverflow::-webkit-scrollbar-track {\n//     background-color: #ff0000 !important;\n//     border-radius: 100px;\n// }\n\n// .sidebarOverflow::-webkit-scrollbar-thumb {\n//     background-color: #dbdbdb;\n//     border-radius: 100px;\n// }\n\n// .sidebarOverflow::-webkit-scrollbar-thumb:hover {\n//     background-color: #c9c9c9;\n//     border-radius: 100px;\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarOverflow": `newCss_sidebarOverflow__oUfy1`
};
export default ___CSS_LOADER_EXPORT___;
