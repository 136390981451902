import { TLoading } from '../store/types'

export const getInlineParams = (item: any) => {
  let result = '?'
  const keys = Object.keys(item)
  keys.forEach((key, index) => {
    if (item[key] || item[key] === 0 || item[key] === false) {
      result += key + '=' + item[key]
      if (index + 1 !== keys.length) {
        result += '&'
      }
    }
  })
  return result
}
export const getPropertiesAsArray = (object: any) => {
  let array: any[] = []
  if (object) {
    Object.values(object).map((value: any) => {
      array.push(value)
    })
  }
  return array
}
export const checkLoaders = (loadings: TLoading[]) => {
  let object: TLoading = { state: false }
  if (loadings) object = Object.values(loadings).find((value: any) => value.state) as TLoading
  return object
}
