// const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))

import FooterView from './views/footer/footer'
import Slider from './views/slider/slider'
import Contacts from './views/contacts/contacts'
import Categories from './views/categories/categories'
import EditCategories from './components/categories/CategoriesEdit'
import Reviews from './views/reviews/reviews'
import Service from './views/service/service'
import About from './views/about/about'
import TellAboutUs from './views/tellAboutUs/tellAboutUs'
import AchievementsView from './views/achievements/achievements'
import MainClientsView from './views/mainClients/mainClients'
import WorkWithUsView from './views/workWithUs/workWithUs'
import AboutMain from './views/aboutMain/aboutMain'
import Seo from './views/seo/seo'

const routes = [
  { path: '', exact: true, name: 'Главная' },
  { path: '/footer', exact: true, name: 'Нижняя панель (футер)', element: FooterView },
  { path: '/slider', exact: true, name: 'Слайдер', element: Slider },
  { path: '/contacts', exact: true, name: 'Контакты', element: Contacts },
  { path: '/categories', exact: true, name: 'Категории', element: Categories },
  { path: '/categories/:url/:id', exact: true, name: 'Редактирование', element: EditCategories },
  { path: '/reviews', exact: true, name: 'Отзывы', element: Reviews },
  { path: '/service', exact: true, name: 'Наши услуги', element: Service },
  { path: '/about', exact: true, name: 'О компании', element: About },
  { path: '/tellAboutUs', exact: true, name: 'Расскажем о нас', element: TellAboutUs },
  { path: '/achievement', exact: true, name: 'Достижения и награды', element: AchievementsView },
  { path: '/mainClients', exact: true, name: 'Основные клиенты', element: MainClientsView },
  { path: '/workWihtUs', exact: true, name: 'Работа с нами', element: WorkWithUsView },
  { path: '/aboutMain', exact: true, name: 'О компании(Главная)', element: AboutMain },
  { path: '/seo', exact: true, name: 'SEO', element: Seo },
]

export default routes
