import { CContainer, CForm, CFormCheck, CFormInput, CFormTextarea } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { TSliderItem } from '../../api/mock/typesApi'
import Modal from '../common/Modal/Modal'
import styles from './Achievements.module.scss'
import InputImage from '../common/InputImage/InputImage'
import { useDispatch } from 'react-redux'
import { DeleteFile, SaveFile } from '../../store/slices/FileSlice'
import { CreateAchievement, editAchievement, getAchievements } from '../../store/slices/AboutSlice'

interface IAchievementsModal {
  open: boolean
  handleClose: (val?: any) => void
  payload: any
  saveHandler: (payload: TSliderItem) => void
}

const AchievementsModal: React.FC<IAchievementsModal> = ({
  open,
  handleClose,
  payload,
  saveHandler,
}) => {
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<any>(payload)
  const [file, setFile] = useState<any>(null)

  useEffect(() => {
    setModel(payload)
  }, [payload])
  const saveHandlerModal = () => {
    if (payload === undefined) {
      if (file === null) {
        dispatch(
          CreateAchievement({
            typeId: 3,
            title: model?.title,
            description: model?.description,
          }),
        )
          .then(() => handleClose())
          .then(() => dispatch(getAchievements()))
      } else {
        dispatch(
          CreateAchievement({
            typeId: 3,
            title: model?.title,
            description: model?.description,
          }),
        )
          .then((e: any) => {
            if (file) {
              const formData = new FormData()
              formData.append('Files', file)
              formData.append(
                'Json',
                JSON.stringify({
                  Flag: 'I',
                  Id: e?.payload?.data?.value?.value,
                  Type: 'InformationArticle',
                  Files: [
                    {
                      Name: file?.name,
                      Extention: file?.name
                        .split('.')
                        [file.name.split('.').length - 1].toUpperCase(),
                      dataType: '2',
                      Rewrite: true,
                    },
                  ],
                }),
              )
              dispatch(
                SaveFile({
                  formData: {
                    Files: file,
                    Json: JSON.stringify({
                      Flag: 'I',
                      Id: e?.payload?.data?.value?.value,
                      Type: 'InformationArticle',
                      Files: [
                        {
                          Name: file?.name,
                          Extention: file?.name
                            .split('.')
                            [file.name.split('.').length - 1].toUpperCase(),
                          dataType: '2',
                          Rewrite: true,
                        },
                      ],
                    }),
                  },
                }),
              )
                .then(() => dispatch(getAchievements()))
                .then(() => handleClose())
            }
          })
          .then(() => handleClose())
          .then(() => dispatch(getAchievements()))
      }
    } else {
      if (file === null) {
        dispatch(
          editAchievement({
            id: payload?.id,
            title: model?.title,
            description: model?.description,
          }),
        )
          .then(() => handleClose())
          .then(() => dispatch(getAchievements()))
      } else {
        dispatch(
          editAchievement({
            title: model?.title,
            description: model?.description,
            id: payload?.id,
          }),
        )
          .then(() =>
            dispatch(
              DeleteFile({
                Id: payload?.id,
                Type: 'InformationArticle',
                Flag: 'D',
                Name: payload?.image?.split('/')[payload?.image?.split('/').length - 1],
              }),
            ),
          )
          .then(() => {
            if (file) {
              const formData = new FormData()
              formData.append('Files', file)
              formData.append(
                'Json',
                JSON.stringify({
                  Flag: 'I',
                  Id: payload?.id,
                  Type: 'InformationArticle',
                  Files: [
                    {
                      Name: file?.name,
                      Extention: file?.name
                        .split('.')
                        [file.name.split('.').length - 1].toUpperCase(),
                      dataType: '2',
                      Rewrite: true,
                    },
                  ],
                }),
              )
              dispatch(
                SaveFile({
                  formData: {
                    Files: file,
                    Json: JSON.stringify({
                      Flag: 'I',
                      Id: payload.id,
                      Type: 'InformationArticle',
                      Files: [
                        {
                          Name: file?.name,
                          Extention: file?.name
                            .split('.')
                            [file.name.split('.').length - 1].toUpperCase(),
                          dataType: '2',
                          Rewrite: true,
                        },
                      ],
                    }),
                  },
                }),
              )
                .then(() => dispatch(getAchievements()))
                .then(() => handleClose())
            }
          })
      }
    }
  }
  return (
    <Modal
      header={payload ? 'Редактирование достижений' : 'Создание достижений'}
      open={open}
      handleClose={handleClose}
      onOk={() => saveHandlerModal()}
      okText={'Сохранить'}
    >
      <CContainer fluid>
        <CForm className={styles.form}>
          <div className={styles.form__formInput} style={{ marginBottom: '10px' }}>
            <span className="text-medium-emphasis">Заголовок</span>
            <CFormInput
              value={model?.title}
              onChange={(e) => setModel({ ...model, title: e.target.value })}
              placeholder="Заголовок"
            />
          </div>
          <div className={styles.form__formInput}>
            <InputImage setFile={setFile} />
          </div>
        </CForm>
      </CContainer>
    </Modal>
  )
}

export default AchievementsModal
