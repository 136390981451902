import { CContainer, CForm, CFormCheck, CFormInput, CFormTextarea } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { TSliderItem } from '../../api/mock/typesApi'
import Modal from '../common/Modal/Modal'
import styles from './TellAboutUsComponent.module.scss'
import InputImage from '../common/InputImage/InputImage'
import JoditEditor from 'jodit-react'
import { useDispatch } from 'react-redux'
import { editAboutBlock, getAboutBlock } from '../../store/slices/AboutSlice'

interface ITellAboutUsModal {
  open: boolean
  handleClose: (val?: any) => void
  payload: any | null
  saveHandler: (payload: TSliderItem) => void
}

const TellAboutUsModal: React.FC<ITellAboutUsModal> = ({
  open,
  handleClose,
  payload,
  saveHandler,
}) => {
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<any>(payload)
  useEffect(() => {
    setModel(payload)
  }, [payload])
  const [file, setFile] = useState<any>(null)
  const config = {
    readonly: false,
    language: 'ru',
    style: {
      font: '12px Arial',
      color: '#0c0c0c',
      height: '190px',
    },
    toolbarSticky: false,
    ssr: false,
  }
  const editor = useRef(null)
  const saveAboutBlock = (model: any) => {
    dispatch(
      editAboutBlock({
        id: payload?.id,
        title: model?.title,
        description: model?.description,
      }),
    )
      .then(() => handleClose())
      .then(() => dispatch(getAboutBlock()))
  }
  return (
    <Modal
      header={payload ? 'Редактирование "Расскажем о нас"' : 'Создание "Расскажем о нас"'}
      open={open}
      handleClose={handleClose}
      onOk={() => saveAboutBlock(model)}
      okText={'Сохранить'}
    >
      <CContainer fluid>
        <CForm className={styles.form}>
          <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Заголовок</span>
            <CFormInput
              value={model.title}
              onChange={(e) => setModel({ ...model, title: e.target.value })}
              placeholder="Заголовок"
            />
          </div>
          <div className={styles.form__formInput}>
            <span className={styles.jodit}>Описание</span>
            {/* <CFormTextarea
              value={model.description}
              rows={6}
              onChange={(e) => setModel({ ...model, description: e.target.value })}
              placeholder="Описание"
            /> */}
            <JoditEditor
              ref={editor}
              value={model.description || ''}
              config={config}
              // tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setModel({ ...model, description: newContent })} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
          </div>
          {/* <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Список тракторов</span>
          </div> */}
          {/* <div className={styles.form__formInput}>
            <InputImage setFile={setFile} />
          </div> */}
        </CForm>
      </CContainer>
    </Modal>
  )
}

export default TellAboutUsModal
