import { authRefreshTokenUrl, authTokenUrl } from '../appSettings'
import { getCookie } from '../cookies'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { deAuth, getUserData, IAuthSlice, login, TUserData } from '../../store/slices/AuthSlice'
import { LoginData } from '../../api/mock/typesApi'

type TUseAuth = () => {
  authToken: string
  authTokenRefresh: string
  userData: TUserData | null
  auth: (val: LoginData) => void
  logout: () => void
  getUser: () => void
  loadings: IAuthSlice['loadings']
}

export const useAuth: TUseAuth = () => {
  const authToken: string = getCookie(authTokenUrl) || ''
  const authTokenRefresh: string = getCookie(authRefreshTokenUrl) || ''
  const userData: TUserData | null = useSelector((state: RootState) => state.AuthSlice.userData)
  const loadings: IAuthSlice['loadings'] = useSelector(
    (state: RootState) => state.AuthSlice.loadings,
  )

  const dispatch = useDispatch<any>()

  const auth: (val: LoginData) => void = (val) => {
    dispatch(login({ login: val.login, password: val.password }))
  }

  const logout: () => void = () => {
    dispatch(deAuth())
  }

  const getUser: () => void = () => {
    // dispatch(getUserData())
  }

  return { authToken, authTokenRefresh, userData, loadings, auth, logout, getUser }
}
