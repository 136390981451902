/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CButton, CContainer, CForm, CFormCheck, CFormInput, CTooltip } from '@coreui/react'
import JoditEditor from 'jodit-react'
import styles from './CategoriesEditComponent.module.scss'
import SliderGallery from '../sliderGallery/SliderGallery'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  A11y,
  FreeMode,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper/modules'
import { ImageItem } from '../../api/mock/typesApi'
import SliderItem from '../slider/SliderItem'
import { Checkbox } from '../common/checkBox/checkBox'
import TabsComponent, { TTabsItem } from '../tabs/TabsComponent'
import addBig from '../../assets/icons/addBig.svg'
import { cilCheckAlt, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  EditCategoryItem,
  EditOrderPhoto,
  getCategoryItem,
} from '../../store/slices/CategoriesSlice'
import { useDispatch, useSelector } from 'react-redux'
import EditCategoryImage from './EditCategoryImage'
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'
import { RootState } from '../../store/store'
import { DeleteFile, SaveFile } from '../../store/slices/FileSlice'
import { useParams } from 'react-router-dom'
import Trash from '../../assets/icons/trash.svg'
import Button from '../common/Button/Button'
import { Tooltip } from 'recharts'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'

interface CategoriesEditProps {
  // payload: any
}
type TGetTabs = (
  tabs: {
    id: number
    title: string
    content: {
      title: string
      description: Element[] | []
    }[]
  }[],
) => TTabsItem[] | []
const getTabs: any = (tabs: any) => {
  if (tabs?.length) {
    let newTabs: TTabsItem[] = []
    tabs.map((tab: any, index: any) =>
      newTabs.push({
        id: tab.id,
        title: tab.title,
        content: tab.content,
      }),
    )
    return newTabs
  }
  return []
}
const CategoriesEdit: React.FC<CategoriesEditProps> = () => {
  const dispatch = useDispatch<any>()
  const editor = useRef(null)
  const payload = useSelector((state: RootState) => state.CategoriesSlice.categoryItem)
  const [title, setTitle] = useState<string | undefined>('')
  const [content, setContent] = useState<string | undefined>('')
  const [url, setUrl] = useState<string | undefined>('')
  const [name, setName] = useState<string | undefined>('')
  const [payloadSecond, setPayloadSecond] = useState<any>()
  const hiddenFileInput: any = React.createRef()
  const hiddenFileInputMain: any = React.createRef()
  const [photos, setNphotos] = useState(payload?.imageList)
  const [image, setImage] = useState<File>()
  const [imageMain, setImageMain] = useState<File>()
  const [currentFormData, setCurrentFormData] = useState<any | null>(null)
  const [flagMain, setFlagMain] = useState<boolean>()
  const params = useParams()
  const prodId = params.id
  const loadings = useSelector((state: RootState) => state.CategoriesSlice.loadings)
  useEffect(() => {
    dispatch(
      getCategoryItem({
        id: prodId,
      }),
    )
  }, [])
  useEffect(() => {
    setContent(payload?.description)
    setTitle(payload?.title)
    setUrl(payload?.url)
    setName(payload?.name)
  }, [payload])
  useEffect(() => {
    if (image) {
      // if()
      hiddenFileInput.current?.focus()
      // hiddenFileInputMain
      const reader = new FileReader()
      reader.readAsDataURL(image)
    }
    const formData = new FormData()
    if (image) {
      formData.append('Files', image)
      formData.append(
        'Json',
        JSON.stringify({
          Flag: 'I',
          Type: 'Category',
          Id: payload?.id,
          IsMain: flagMain,
          Files: [
            {
              Name: image.name,
              Extention: image.name.split('.')[image.name.split('.').length - 1],
              DataType: 2,
              Rewrite: true,
            },
          ],
        }),
      )
      let FormDataNew = {
        Files: image,
        Json: JSON.stringify({
          Flag: 'I',
          Type: 'Category',
          Id: payload?.id,
          IsMain: flagMain,
          Files: [
            {
              Name: image.name,
              Extention: image.name.split('.')[image.name.split('.').length - 1],
              DataType: 2,
              Rewrite: true,
            },
          ],
        }),
      }
      dispatch(
        SaveFile({
          formData: FormDataNew,
        }),
      ).then(() =>
        dispatch(
          getCategoryItem({
            id: payload?.id,
          }),
        ),
      )
    }
  }, [image])
  useEffect(() => {
    if (imageMain) {
      hiddenFileInputMain.current?.focus()
      const reader = new FileReader()
      reader.readAsDataURL(imageMain)
    }
    const formData = new FormData()
    if (imageMain) {
      formData.append('Files', imageMain)
      formData.append(
        'Json',
        JSON.stringify({
          Flag: 'I',
          Type: 'Category',
          Id: payload?.id,
          IsMain: true,
          Files: [
            {
              Name: imageMain.name,
              Extention: imageMain.name.split('.')[imageMain.name.split('.').length - 1],
              DataType: 2,
              Rewrite: true,
            },
          ],
        }),
      )
      let FormDataNew = {
        Files: imageMain,
        Json: JSON.stringify({
          Flag: 'I',
          Type: 'Category',
          Id: payload?.id,
          IsMain: true,
          Files: [
            {
              Name: imageMain.name,
              Extention: imageMain.name.split('.')[imageMain.name.split('.').length - 1],
              DataType: 2,
              Rewrite: true,
            },
          ],
        }),
      }
      dispatch(
        SaveFile({
          formData: FormDataNew,
        }),
      ).then(() =>
        dispatch(
          getCategoryItem({
            id: payload?.id,
          }),
        ),
      )
    }
  }, [imageMain])
  const deletePhotoHandler = (flag: string) => {
    dispatch(
      DeleteFile({
        Type: 'Category',
        Flag: flag,
        Id: payload?.id,
        Name: payload?.image.split('/')[payload?.image.split('/').length - 1],
      }),
    ).then(() =>
      dispatch(
        getCategoryItem({
          id: payload?.id,
        }),
      ),
    )
  }
  const SaveTextHandler = () => {
    dispatch(
      EditCategoryItem({
        id: payload?.id,
        name: name,
        title: title,
        description: content,
        url: url,
      }),
    )
  }
  const [currentItem, setCurrentItem] = useState<any>()
  const [orderList, setOrderList] = useState<any[] | []>([])
  const [currentOrder, setCurrentOrder] = useState<any>([])
  function dragStartHandler(e: any, item: any, index: number) {
    setCurrentOrder({ item: item, index: index })
  }

  function dragLeaveHandler(e: any) {
    e.currentTarget.classList.remove(styles.item__over)
  }

  function dragEndHandler(e: any) {
    // e.target.style.background = 'white'
  }

  function dragOverHandler(e: any) {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.classList.add(styles.item__over)
  }
  useEffect(() => {
    setOrderList(
      payload?.imageList?.length
        ? payload?.imageList?.map((images: any, idx) => {
            return {
              ...images,
              idx: idx,
            }
          })
        : [],
    )
  }, [payload?.imageList])
  function dropHandler(e: any, item: any, index: number) {
    const buffer = (item: any) => {
      return { ...item, index: index }
    }
    e.currentTarget.classList.remove()
    e.preventDefault()
    let arr: any[] = []
    if (item?.id !== currentItem?.id) {
      arr = orderList.map((i) => {
        //@ts-ignore
        if (buffer(item).index < currentOrder.index) {
          if (i.idx < buffer(item).index) {
            return { ...i }
          }
          //@ts-ignore
          if (i.idx === currentOrder.index) {
            return { ...i, idx: buffer(item).index }
          }
          //@ts-ignore
          if (i.idx >= buffer(item).index && i.idx < currentOrder.index) {
            return { ...i, idx: i.idx + 1 }
          }
          return { ...i }
        } else {
          //@ts-ignore
          if (buffer(item).index > currentOrder.index) {
            if (i.idx > buffer(item).index) {
              return { ...i }
            }
            //@ts-ignore
            if (i.idx === currentOrder.index) {
              return { ...i, idx: buffer(item).index }
            }
            //@ts-ignore
            if (i.idx <= buffer(item).index && i.idx > currentOrder.index) {
              return { ...i, idx: i.idx - 1 }
            }
            return { ...i }
          }
        }
      })
    } else {
      arr = orderList
    }
    const SortOrder = (a: any, b: any) => {
      if (a.idx > b.idx) {
        return 1
      } else {
        return -1
      }
    }
    dispatch(
      EditOrderPhoto({
        dataType: 'Category',
        elementId: payload?.id,
        fileNames: arr.sort(SortOrder).map((item) => item?.name),
      }),
    ).then(
      () =>
        dispatch(
          getCategoryItem({
            id: prodId,
          }),
        ),
      setOrderList(arr),
    )
    // EditOrder({
    //   list: arr,
    //   // .map(item => { if(item.id !== 0) return ({ id:item.id,  order:item.order})} )
    // }).then(() => {
    //   VendingAdsHandlet()
    //   setOrderList(arr)
    // })
  }
  const config = {
    readonly: false,
    language: 'ru',
    style: {
      font: '12px Arial',
      color: '#0c0c0c',
      height: '190px',
    },
    toolbarSticky: false,
    ssr: false,
  }
  const categoriesTabs = getTabs(payload?.tabs)
  return (
    <>
      <SkeletonLoading style={{ minHeight: '1000px' }} loading={checkLoadings(loadings)}>
        <CContainer
          fluid
          className="bg-white border table-responsive"
          style={{ marginBottom: '10px' }}
        >
          <div className={styles.main} style={{ marginBottom: '10px' }}>
            <div className={styles.main__editors}>
              <h4>Заголовок</h4>
              <CForm>
                <CFormInput
                  value={title || ''}
                  onChange={(e) => {
                    setTitle(e.target.value)
                  }}
                />
              </CForm>
              <h4 style={{ marginTop: '10px' }}>Наименование</h4>
              <CForm>
                <CFormInput
                  value={name || ''}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
              </CForm>
              <h4 style={{ marginTop: '10px' }}>URL</h4>
              <CForm>
                <CFormInput
                  value={url || ''}
                  onChange={(e) => {
                    setUrl(e.target.value)
                  }}
                />
              </CForm>
              <h4 style={{ marginTop: '10px' }}>Описание</h4>
              <JoditEditor
                ref={editor}
                value={content || ''}
                config={config}
                // tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
              <CTooltip
                content="Сохранение заголовка, url, описания"
                placement="top"
                style={{ zIndex: '9999999' }}
              >
                {/* <Button onClick={() => SaveTextHandler()} style={{ marginTop: '10px' }}>
                Сохранить
              </Button> */}
                <CButton
                  // color="secondary"
                  onClick={() => SaveTextHandler()}
                  style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                  Сохранить
                </CButton>
              </CTooltip>
            </div>
            <div className={styles.main__sliders}>
              <div style={{ marginLeft: '50px', marginTop: '15px' }}>
                <h4>Главное фото</h4>
              </div>
              {payload?.image !== undefined ? (
                <div className={styles.main__sliders__mainPhoto}>
                  <div style={{ position: 'absolute', top: 10, right: 5 }}>
                    <img
                      onClick={() => {
                        deletePhotoHandler('D')
                      }}
                      src={Trash}
                      height={19}
                      width={19}
                      alt="delete"
                      className={styles.trashButton}
                    />
                  </div>
                  <img
                    src={payload?.image}
                    width={200}
                    height={140}
                    style={{ borderRadius: '4px' }}
                  />
                </div>
              ) : (
                <div className={styles.main__sliders__addBlock}>
                  <CIcon
                    size="xl"
                    className={styles.check}
                    icon={cilPlus}
                    onClick={(e) => {
                      e.preventDefault()
                      hiddenFileInputMain.current?.click()
                    }}
                  />
                  <input
                    type="file"
                    ref={hiddenFileInputMain}
                    onChange={(e: any) => {
                      const file = e?.target?.files[0]
                      if (file && file.type.substring(0, 5) === 'image') {
                        setImageMain(file)
                        setFlagMain(true)
                      } else {
                        setImageMain(undefined)
                      }
                    }}
                    style={{ display: 'none' }}
                  />
                </div>
              )}
              <div style={{ marginLeft: '50px', marginTop: '15px' }}>
                <h4>Колекция фото</h4>
              </div>
              <div className={styles.gridArea}>
                {payload?.imageList?.map((item: any, index: any) => (
                  <div
                    key={index + ' li'}
                    id={String(index)}
                    className={styles.liDiv}
                    draggable={true}
                    onDragStart={(e) => dragStartHandler(e, item, index)}
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragEnd={(e) => dragEndHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, item, index)}
                    title={item?.name}
                  >
                    <EditCategoryImage item={item} category={payload} idx={index} />
                  </div>
                ))}
                <div className={styles.addBlock}>
                  <CIcon
                    size="xl"
                    className={styles.check}
                    icon={cilPlus}
                    onClick={(e) => {
                      e.preventDefault()
                      hiddenFileInput.current?.click()
                    }}
                  />
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e: any) => {
                      const file = e?.target?.files[0]
                      if (file && file.type.substring(0, 5) === 'image') {
                        setImage(file)
                        setFlagMain(false)
                      } else {
                        setImage(undefined)
                      }
                    }}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CContainer>
        <div className={styles.tabs}>
          <TabsComponent items={categoriesTabs} category={payload} />
        </div>
      </SkeletonLoading>
    </>
  )
}
export default CategoriesEdit
