// import React, { useEffect, useMemo, useRef, useState } from 'react'
// import { CContainer, CForm, CFormCheck, CFormInput } from '@coreui/react'
// import JoditEditor from 'jodit-react'
// import styles from './CategoriesEditComponent.module.scss'
// import SliderGallery from '../sliderGallery/SliderGallery'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import {
//   A11y,
//   FreeMode,
//   Keyboard,
//   Mousewheel,
//   Navigation,
//   Pagination,
//   Scrollbar,
// } from 'swiper/modules'
// import { ImageItem } from '../../api/mock/typesApi'
// import SliderItem from '../slider/SliderItem'
// import Trash from '../../assets/icons/trash.svg'
// import { Checkbox } from '../common/checkBox/checkBox'
// import TabsComponent, { TTabsItem } from '../tabs/TabsComponent'
// import addBig from '../../assets/icons/addBig.svg'

// interface CategoriesCreateProps {}
// type TGetTabs = (
//   tabs: {
//     title: string
//     content: {
//       title: string
//       description: Element[] | []
//     }[]
//   }[],
// ) => TTabsItem[] | []
// const getTabs: TGetTabs = (tabs) => {
//   if (tabs?.length) {
//     let newTabs: TTabsItem[] = []
//     tabs.map((tab, index) =>
//       newTabs.push({
//         title: tab.title,
//         content: (
//           <>
//             {tab?.content?.length
//               ? tab.content.map((content) => {
//                   return (
//                     <div
//                       key={index + ' contentTab'}
//                       className={styles.tabs__tabPanel__contentPanel}
//                     >
//                       <div className={styles.tabs__tabPanel__contentPanel__title}>
//                         {content.title}
//                       </div>
//                       <div
//                         dangerouslySetInnerHTML={{
//                           //@ts-ignore
//                           __html: content.description,
//                         }}
//                         className={styles.tabs__tabPanel__contentPanel__content}
//                       ></div>
//                     </div>
//                   )
//                 })
//               : ''}
//           </>
//         ),
//       }),
//     )
//     return newTabs
//   }
//   return []
// }
// const CategoriesCreate: React.FC<CategoriesCreateProps> = () => {
//   const editor = useRef(null)
//   const [content, setContent] = useState('')
//   const [tabs, setTabs] = useState<any>()
//   const [images, setImages] = useState([])
//   const hiddenFileInput: any = React.createRef()
//   const categoriesTabs = getTabs(tabs)
//   return (
//     <CContainer fluid className="bg-white border table-responsive">
//       <div className={styles.main}>
//         <div className={styles.main__editors}>
//           <h4>Заголовок</h4>
//           <CForm>
//             <CFormInput value={''} />
//           </CForm>
//           <h4>Описание</h4>
//           <JoditEditor
//             ref={editor}
//             value={''}
//             // config={config}
//             // tabIndex={1} // tabIndex of textarea
//             onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
//             onChange={(newContent) => {}}
//           />
//         </div>
//         <div className={styles.main__sliders}>
//           <div className={styles.gridArea}>
//             {images?.map((item: any, index: any) => (
//               <>
//                 <div className={styles.photoCard}>
//                   <div style={{ position: 'absolute', top: 4, right: 4 }}>
//                     <img
//                       onClick={() => console.log()}
//                       src={Trash}
//                       height={19}
//                       width={19}
//                       alt="delete"
//                       className={styles.button}
//                     />
//                   </div>
//                   <img className={styles.photoCardImg} src={item.url} />
//                   <div className={styles.cardOption}>
//                     <Checkbox
//                       checkboxName={'checkbox1' + item.id}
//                       style={{ width: 18, height: 18 }}
//                       value={item.isMain}
//                       setValue={(bool) => {
//                         console.log({ index: index, id: item.id })
//                         console.log({ index: index, id: item.id })
//                         // photos[index] = {
//                         //     ...photos[index],
//                         //     // setChosenMainPhoto
//                         //     isMain: checkId.id === item.id
//                         // }
//                       }}
//                       rounded={true}
//                     />
//                     <div>Основное фото</div>
//                   </div>
//                 </div>
//               </>
//             ))}
//             <div className={styles.addBlock}>
//               <img
//                 onClick={() => console.log()}
//                 src={addBig}
//                 height={42}
//                 width={42}
//                 alt="addBig"
//                 className={styles.button}
//               />
//               <input
//                 type="file"
//                 ref={hiddenFileInput}
//                 onChange={() => console.log()}
//                 style={{ display: 'none' }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className={styles.tabs}>
//         <TabsComponent items={categoriesTabs} />
//       </div>
//     </CContainer>
//   )
// }
// export default CategoriesCreate
/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CButton, CContainer, CForm, CFormCheck, CFormInput, CTooltip } from '@coreui/react'
import JoditEditor from 'jodit-react'
import styles from './CategoriesEditComponent.module.scss'
import SliderGallery from '../sliderGallery/SliderGallery'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  A11y,
  FreeMode,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper/modules'
import { ImageItem } from '../../api/mock/typesApi'
import SliderItem from '../slider/SliderItem'
import { Checkbox } from '../common/checkBox/checkBox'
import TabsComponent, { TTabsItem } from '../tabs/TabsComponent'
import addBig from '../../assets/icons/addBig.svg'
import { cilCheckAlt, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CreateCategoryItem,
  EditCategoryItem,
  getCategoryItem,
} from '../../store/slices/CategoriesSlice'
import { useDispatch, useSelector } from 'react-redux'
import EditCategoryImage from './EditCategoryImage'
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'
import { RootState } from '../../store/store'
import { DeleteFile, SaveFile } from '../../store/slices/FileSlice'
import { useNavigate, useParams } from 'react-router-dom'
import Trash from '../../assets/icons/trash.svg'
import Button from '../common/Button/Button'
import { openNotification } from '../common/Notification/Notification'
import { putSeo } from '../../store/slices/SeoSlice'

interface CategoriesEditProps {
  // payload: any
}
type TGetTabs = (
  tabs: {
    id: number
    title: string
    content: {
      title: string
      description: Element[] | []
    }[]
  }[],
) => TTabsItem[] | []
const getTabs: any = (tabs: any) => {
  if (tabs?.length) {
    let newTabs: TTabsItem[] = []
    tabs.map((tab: any, index: any) =>
      newTabs.push({
        id: tab.id,
        title: tab.title,
        content: tab.content,
      }),
    )
    return newTabs
  }
  return []
}
const CategoriesEdit: React.FC<CategoriesEditProps> = () => {
  const history = useNavigate()
  const dispatch = useDispatch<any>()
  const editor = useRef(null)
  const categId = useSelector((state: RootState) => state.CategoriesSlice.categoryId)
  // const payload = useSelector((state: RootState) => state.CategoriesSlice.categoryItem)
  const [title, setTitle] = useState<string | undefined>('')
  const [content, setContent] = useState<string | undefined>('')
  const [url, setUrl] = useState<string | undefined>('')
  const [name, setName] = useState<string | undefined>('')
  const [payloadSecond, setPayloadSecond] = useState<any>()
  const hiddenFileInput: any = React.createRef()
  const hiddenFileInputMain: any = React.createRef()
  // const [photos, setNphotos] = useState(payload?.imageList)
  const [image, setImage] = useState<File>()
  const [imageMain, setImageMain] = useState<File>()
  const [currentFormData, setCurrentFormData] = useState<any | null>(null)
  const [flagMain, setFlagMain] = useState<boolean>()
  const params = useParams()
  const prodId = params.id

  // useEffect(() => {
  //   dispatch(
  //     getCategoryItem({
  //       id: prodId,
  //     }),
  //   )
  // }, [])
  const SaveTextHandler = () => {
    if (!title || !content || !url || !name) {
      openNotification({ type: 'warning', text: 'Заполните всю основную информацию' })
    } else {
      dispatch(
        CreateCategoryItem({
          name: title,
          title: title,
          description: content,
          url: url,
        }),
      )
        .then((e: any) => history(`/categories/${url}/${e.payload.data.value.value}`))
        .then(() =>
          dispatch(
            putSeo({
              name: title,
              mnemo: url,
            }),
          ),
        )
    }
  }
  return (
    <>
      <CContainer fluid className="bg-white border table-responsive">
        <div className={styles.main}>
          <div className={styles.main__editors}>
            <h4>Заголовок</h4>
            <CForm>
              <CFormInput
                value={title || ''}
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
              />
            </CForm>
            <h4 style={{ marginTop: '10px' }}>Наименование</h4>
            <CForm>
              <CFormInput
                value={name || ''}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
            </CForm>
            <h4 style={{ marginTop: '10px' }}>URL</h4>
            <CForm>
              <CFormInput
                value={url || ''}
                onChange={(e) => {
                  setUrl(e.target.value)
                }}
              />
            </CForm>
            <h4 style={{ marginTop: '10px' }}>Описание</h4>
            <JoditEditor
              ref={editor}
              value={content || ''}
              // config={config}
              // tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
            <CTooltip
              content="Сохранение заголовка, url, описания"
              placement="top"
              style={{ zIndex: '9999999' }}
            >
              {/* <Button onClick={() => SaveTextHandler()} style={{ marginTop: '10px' }}>
                Сохранить
              </Button> */}
              <CButton
                // color="secondary"
                onClick={() => SaveTextHandler()}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                Сохранить
              </CButton>
            </CTooltip>
          </div>
          <div className={styles.main__sliders}>
            <div style={{ marginLeft: '50px', marginTop: '15px' }}>
              <h4>Главное фото</h4>
            </div>
            {image !== undefined ? (
              <div className={styles.main__sliders__mainPhoto}>
                <div style={{ position: 'absolute', top: 10, right: 5 }}>
                  <img
                    onClick={() => {
                      console.log('deleted')
                    }}
                    src={Trash}
                    height={19}
                    width={19}
                    alt="delete"
                    className={styles.trashButton}
                  />
                </div>
                {/* <img src={image} width={200} height={140} style={{ borderRadius: '4px' }} /> */}
              </div>
            ) : (
              <div className={styles.main__sliders__addBlock}>
                <CIcon
                  size="xl"
                  className={styles.check}
                  icon={cilPlus}
                  onClick={(e) => {
                    openNotification({
                      type: 'warning',
                      text: 'Сначала заполните основную информацию, затем нажмите "Cохранить"',
                    })
                  }}
                />
                <input
                  type="file"
                  ref={hiddenFileInputMain}
                  onChange={(e: any) => {
                    const file = e?.target?.files[0]
                    if (file && file.type.substring(0, 5) === 'image') {
                      setImageMain(file)
                      setFlagMain(true)
                      console.log(flagMain)
                    } else {
                      setImageMain(undefined)
                    }
                  }}
                  style={{ display: 'none' }}
                />
              </div>
            )}
            <div style={{ marginLeft: '50px', marginTop: '15px' }}>
              <h4>Колекция фото</h4>
            </div>
            <div className={styles.gridArea}>
              {/* {payload?.imageList?.map((item: any, index: any) => (
                <EditCategoryImage item={item} category={payload} />
              ))} */}
              <div className={styles.addBlock}>
                <CIcon
                  size="xl"
                  className={styles.check}
                  icon={cilPlus}
                  onClick={(e) =>
                    openNotification({
                      type: 'warning',
                      text: 'Сначала заполните основную информацию, затем нажмите "Cохранить"',
                    })
                  }
                />
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={(e: any) => {
                    const file = e?.target?.files[0]
                    if (file && file.type.substring(0, 5) === 'image') {
                      setImage(file)
                      setFlagMain(false)
                    } else {
                      setImage(undefined)
                    }
                  }}
                  style={{ display: 'none' }}
                />
              </div>
            </div>
          </div>
        </div>
      </CContainer>
      <div className={styles.tabs}>
        {/* <TabsComponent items={categoriesTabs} category={payload} /> */}
      </div>
    </>
  )
}
export default CategoriesEdit
