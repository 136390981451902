import React, { CSSProperties } from 'react'
import styles from './CardItem.module.scss'
import Phone from '@/assets/icons/phone.svg'
import { TWorkWithUsItem } from '../../api/mock/typesApi'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import Trash from '../../assets/icons/trash.svg'
import { useDispatch } from 'react-redux'
import { DeleteWorkWithUs, getWorkWithUs } from '../../store/slices/AboutSlice'
interface ICardItem {
  item: TWorkWithUsItem
  style?: CSSProperties
  openModal: Function
  deleteHandler: Function
  setItems: Function
}

const CardItem: React.FC<ICardItem> = ({
  item,
  style = {},
  openModal,
  deleteHandler,
  setItems,
}) => {
  const handleOpenModal = () => {
    openModal()
    setItems(item)
  }
  const dispatch = useDispatch<any>()
  const deleteHandlerWorkWithUS = () => {
    dispatch(
      DeleteWorkWithUs({
        id: item.id,
      }),
    ).then(() => dispatch(getWorkWithUs()))
  }
  return (
    <div className={styles.cardItem} style={style}>
      <div
        className={styles.cardItem__trashIcon}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <img
          onClick={() => {
            deleteHandlerWorkWithUS()
          }}
          src={Trash}
          width={20}
          height={20}
          //   className={styles.trashButton__button}
          alt="delete"
        />
      </div>
      <div
        className={styles.cardItem__edit}
        onClick={() => {
          handleOpenModal()
        }}
      >
        <CIcon icon={cilPencil} style={{ color: 'black' }} />
      </div>
      <div className={styles.cardItem__image}>
        <img alt={'phone'} src={item.image} width={68} height={68} />
      </div>
      <div className={styles.cardItem__title}>{item.title}</div>
      <div className={styles.cardItem__description}>{item.description}</div>
    </div>
  )
}

export default CardItem
