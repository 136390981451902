import { cilEnvelopeClosed } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CFooter, CHeader, CModal, CModalBody } from '@coreui/react'
import React, { ReactElement } from 'react'
import Button from '../Button/Button'
import styles from './Modal.module.scss'

interface IModal {
  open: boolean
  handleClose: (val?: any) => void
  header?: ReactElement | string
  children: ReactElement
  onOk?: (val?: any) => any
  okText?: string
  alignment?: 'center' | 'top'
}

const Modal: React.FC<IModal> = ({
  open,
  handleClose,
  header,
  children,
  onOk,
  alignment = 'center',
  okText = 'ОК',
}) => {
  return (
    <CModal
      className={styles.mainModal}
      visible={open}
      onClose={handleClose}
      alignment={alignment}
      size="xl"
    >
      {header ? (
        <CHeader className={styles.headerWithContent}>
          <>
            {header}
            <button
              onClick={handleClose}
              type="button"
              className="btn-close btn-close-white"
              aria-label="Close"
            ></button>
          </>
        </CHeader>
      ) : (
        <CHeader className={styles.headerWithoutContent}>
          <button
            onClick={handleClose}
            type="button"
            className="btn-close "
            aria-label="Close"
          ></button>
        </CHeader>
      )}
      <CModalBody>{children}</CModalBody>
      <CFooter className={styles.footer}>
        <Button
          className={styles.button}
          onClick={(e) => {
            onOk && onOk(e)
          }}
        >
          {okText}
        </Button>
      </CFooter>
    </CModal>
  )
}

export default Modal
