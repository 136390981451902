import { CContainer, CForm, CFormCheck, CFormInput, CFormTextarea } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { TSliderItem } from '../../api/mock/typesApi'
import Modal from '../common/Modal/Modal'
import styles from './MainClients.module.scss'
import InputImage from '../common/InputImage/InputImage'
import JoditEditor from 'jodit-react'
import { useDispatch } from 'react-redux'
import {
  CreateMainClients,
  CreateWorkWithUs,
  editAboutBlock,
  editMainClients,
  editService,
  editWorkWithUs,
  getAboutBlock,
  getMainClients,
  getService,
  getWorkWithUs,
} from '../../store/slices/AboutSlice'
import { SaveFile } from '../../store/slices/FileSlice'

interface IMainClients {
  open: boolean
  handleClose: (val?: any) => void
  payload: any | null
  //   saveHandler: Function
}

const MainClientsModal: React.FC<IMainClients> = ({
  open,
  handleClose,
  payload,
  //   saveHandler,
}) => {
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<any>(payload)
  useEffect(() => {
    setModel(payload)
    console.log(payload)
  }, [payload])
  const [file, setFile] = useState<any>(null)

  const saveWorkWithUs = (model: any) => {
    if (payload === undefined) {
      if (file === null) {
        dispatch(
          CreateMainClients({
            typeId: 2,
            title: model.title,
            description: model.description,
          }),
        )
          .then(() => handleClose())
          .then(() => dispatch(getMainClients()))
      } else {
        dispatch(
          CreateMainClients({
            typeId: 2,
            title: model.title,
            description: model.description,
          }),
        )
          .then((e: any) => {
            if (file) {
              const formData = new FormData()
              formData.append('Files', file)
              formData.append(
                'Json',
                JSON.stringify({
                  Flag: 'I',
                  Id: e?.payload?.data?.value?.value,
                  Type: 'InformationArticle',
                  Files: [
                    {
                      Name: file?.name,
                      Extention: file?.name
                        .split('.')
                        [file.name.split('.').length - 1].toUpperCase(),
                      dataType: '2',
                      Rewrite: true,
                    },
                  ],
                }),
              )
              dispatch(
                SaveFile({
                  formData: {
                    Files: file,
                    Json: JSON.stringify({
                      Flag: 'I',
                      Id: e?.payload?.data?.value?.value,
                      Type: 'InformationArticle',
                      Files: [
                        {
                          Name: file?.name,
                          Extention: file?.name
                            .split('.')
                            [file.name.split('.').length - 1].toUpperCase(),
                          dataType: '2',
                          Rewrite: true,
                        },
                      ],
                    }),
                  },
                }),
              )
                .then(() => dispatch(getMainClients()))
                .then(() => handleClose())
            }
          })
          .then(() => handleClose())
          .then(() => dispatch(getMainClients()))
      }
    } else {
      if (file === null) {
        dispatch(
          editMainClients({
            id: payload?.id,
            title: model.title,
            description: model.description,
          }),
        )
          .then(() => handleClose())
          .then(() => dispatch(getMainClients()))
      } else {
        dispatch(
          editMainClients({
            title: model.title,
            description: model.description,
            id: payload?.id,
          }),
        ).then(() => {
          if (file) {
            const formData = new FormData()
            formData.append('Files', file)
            formData.append(
              'Json',
              JSON.stringify({
                Flag: 'I',
                Id: payload?.id,
                Type: 'InformationArticle',
                Files: [
                  {
                    Name: file?.name,
                    Extention: file?.name.split('.')[file.name.split('.').length - 1].toUpperCase(),
                    dataType: '2',
                    Rewrite: true,
                  },
                ],
              }),
            )
            dispatch(
              SaveFile({
                formData: {
                  Files: file,
                  Json: JSON.stringify({
                    Flag: 'I',
                    Id: payload.id,
                    Type: 'InformationArticle',
                    Files: [
                      {
                        Name: file?.name,
                        Extention: file?.name
                          .split('.')
                          [file.name.split('.').length - 1].toUpperCase(),
                        dataType: '2',
                        Rewrite: true,
                      },
                    ],
                  }),
                },
              }),
            )
              .then(() => dispatch(getMainClients()))
              .then(() => handleClose())
          }
        })
      }
    }
  }
  return (
    <Modal
      header={payload ? 'Редактирование "Главные клиенты"' : 'Создание "Главные клиенты"'}
      open={open}
      handleClose={handleClose}
      onOk={() => saveWorkWithUs(model)}
      okText={'Сохранить'}
    >
      <CContainer fluid>
        <CForm className={styles.form}>
          <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Местоположение</span>
            <CFormInput
              value={model?.description}
              onChange={(e) => setModel({ ...model, description: e.target.value })}
              placeholder="Местоположение"
            />
          </div>
          <div
            className={styles.form__formInput}
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            <span className={styles.jodit}>Наименование</span>
            <CFormTextarea
              value={model?.title}
              rows={6}
              onChange={(e) => setModel({ ...model, title: e.target.value })}
              placeholder="Наименование"
            />
            {/* <JoditEditor
              ref={editor}
              value={model?.description || ''}
              config={config}
              // tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setModel({ ...model, description: newContent })} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            /> */}
          </div>
          {/* <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Список тракторов</span>
          </div> */}
          {/* <div className={styles.form__formInput}>
            <InputImage setFile={setFile} />
          </div> */}
        </CForm>
      </CContainer>
    </Modal>
  )
}

export default MainClientsModal
