// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__w\\+JtY {
  border: none;
  border-radius: 8px;
  transition: 0.3s ease;
}
.Button_button__hoverDisable__Q8xLX:hover {
  cursor: not-allowed;
  transition: 0.3s ease;
}

.Button_buttonPrimary__KZlm\\+ {
  background-color: var(--primary-color);
  color: white;
}

.Button_buttonSecondary__Vi23f {
  background-color: var(--secondary-color);
  color: black;
}

.Button_hoverPrimaryActive__8ZwF\\+:hover {
  background-color: var(--primary-color-hover);
  cursor: pointer;
  transition: 0.3s ease;
}

.Button_hoverSecondaryActive__Dz-sW:hover {
  background-color: var(--secondary-color-hover);
  cursor: pointer;
  transition: 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Button/Button.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EAEA,kBAAA;EACA,qBAAA;AAAJ;AAII;EACI,mBAAA;EACA,qBAAA;AAFR;;AAMA;EACI,sCAAA;EACA,YAAA;AAHJ;;AAMA;EACI,wCAAA;EACA,YAAA;AAHJ;;AAMA;EACI,4CAAA;EACA,eAAA;EACA,qBAAA;AAHJ;;AAMA;EACI,8CAAA;EACA,eAAA;EACA,qBAAA;AAHJ","sourcesContent":[".button {\n    border: none;\n    \n    border-radius: 8px;\n    transition: 0.3s ease;\n\n    \n\n    &__hoverDisable:hover {\n        cursor: not-allowed;\n        transition: 0.3s ease;\n    }\n}\n\n.buttonPrimary{\n    background-color: var(--primary-color);\n    color: white;\n}\n\n.buttonSecondary{\n    background-color: var(--secondary-color);\n    color: black;\n}\n\n.hoverPrimaryActive:hover {\n    background-color: var(--primary-color-hover);\n    cursor: pointer;\n    transition: 0.3s ease;\n}\n\n.hoverSecondaryActive:hover {\n    background-color: var(--secondary-color-hover);\n    cursor: pointer;\n    transition: 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__w+JtY`,
	"button__hoverDisable": `Button_button__hoverDisable__Q8xLX`,
	"buttonPrimary": `Button_buttonPrimary__KZlm+`,
	"buttonSecondary": `Button_buttonSecondary__Vi23f`,
	"hoverPrimaryActive": `Button_hoverPrimaryActive__8ZwF+`,
	"hoverSecondaryActive": `Button_hoverSecondaryActive__Dz-sW`
};
export default ___CSS_LOADER_EXPORT___;
