import { CSSProperties, useRef } from 'react'

export const useHover = (styleEnter: CSSProperties, styleLeave: CSSProperties) => {
  const currentRef = useRef<any>(null)

  const onMouseEnter = () => {
    if (currentRef?.current?.style) {
      for (let property in styleEnter) {
        //@ts-ignore
        currentRef.current.style[property] = styleEnter[property]
      }
    }
  }
  const onMouseLeave = () => {
    if (currentRef?.current?.style) {
      for (let property in styleLeave) {
        //@ts-ignore
        currentRef.current.style[property] = styleLeave[property]
      }
    }
  }

  return { onMouseEnter, onMouseLeave, currentRef }
}

export const editableBlockStyle: CSSProperties = {
  backgroundColor: 'rgb(0, 170, 255, 0.2)',
  outline: '3px solid #0085c7',
  padding: '6px',
  borderRadius: '12px',
}
