import { getSlider } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../components/common/Button/Button'
import ContactsComponent from '../../components/contacts/ContactsComponent'
import React, { useEffect } from 'react'
import { getCategories } from '../../store/slices/CategoriesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CContainer } from '@coreui/react'
import CategoriesComponent from '../../components/categories/CategoriesComponent'
import AboutCompanyComponent from '../../components/aboutCompany/AboutCompanyComponent'
import { getAboutBlock, getAboutPoster } from '../../store/slices/AboutSlice'
import { openNotification } from '../../components/common/Notification/Notification'
import { getCookie } from '../../helpers/cookies'

const About: React.FC = ({}) => {
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(getAboutPoster())
    dispatch(getAboutBlock())
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  const aboutPoster = useSelector((state: RootState) => state.AboutSlice.aboutPoster)
  const aboutBlock = useSelector((state: RootState) => state.AboutSlice.aboutBlock)
  return (
    <CContainer fluid className="bg-white p-0 m-0 rounded">
      <AboutCompanyComponent aboutPoster={aboutPoster} aboutBlock={aboutBlock} />
    </CContainer>
  )
}

export default About
