import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Trash from '../../assets/icons/trash.svg'
import styles from './CategoriesEditComponent.module.scss'
import { CFormCheck } from '@coreui/react'
import { DeleteFile, SaveFile } from '../../store/slices/FileSlice'
import { getCategoryItem } from '../../store/slices/CategoriesSlice'
import { RootState } from '../../store/store'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'
interface ITabComponent {
  item: any
  category: any
  idx: number
}

const EditCategoryImage: React.FC<ITabComponent> = ({ item, category, idx }) => {
  const dispatch = useDispatch<any>()
  const deletePhotoHandler = (flag: string) => {
    console.log(item)
    dispatch(
      DeleteFile({
        Type: 'Category',
        Flag: flag,
        Id: category?.id,
        Name: item?.url?.split('/')[item?.url?.split('/').length - 1],
      }),
    ).then(() => {
      dispatch(
        getCategoryItem({
          id: category?.id,
        }),
      )
    })
  }
  const loadings = useSelector((state: RootState) => state.SaveFileSlice.loadings)
  return (
    <>
      <SkeletonLoading loading={checkLoadings(loadings)}>
        <div className={styles.photoCard}>
          <div style={{ position: 'absolute', top: 10, right: 5 }}>
            <img
              onClick={() => {
                deletePhotoHandler('D')
              }}
              src={Trash}
              height={19}
              width={19}
              alt="delete"
              className={styles.trashButton}
            />
          </div>
          <div className={styles.order} style={{ position: 'absolute', top: 10, left: 5 }}>
            {idx + 1}
          </div>
          <img className={styles.photoCardImg} src={item.url} />
        </div>
      </SkeletonLoading>
    </>
  )
}

export default EditCategoryImage
