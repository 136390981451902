// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GalleryEditImage_photoCard__h2oY4 {
  position: relative;
  width: 200px;
  height: 140px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.GalleryEditImage_photoCard__h2oY4 .GalleryEditImage_photoCardImage__\\+ewZq {
  width: 200px;
  height: 140px;
}
.GalleryEditImage_photoCard__h2oY4 .GalleryEditImage_order__9VsmL {
  background-color: var(--secondary-color);
  cursor: pointer;
  width: 20px;
  height: 20px;
  right: 0px;
  top: 5px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  border-radius: 100%;
  transition: 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/GalleryBig/GalleryEditImage.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,0CAAA;EACA,kBAAA;EAEA,aAAA;EACA,sBAAA;EACA,gBAAA;AAAJ;AACI;EACI,YAAA;EACA,aAAA;AACR;AACI;EACI,wCAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,QAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;AACR","sourcesContent":[".photoCard {\n    position: relative;\n    width: 200px;\n    height: 140px;\n    background: #FFFFFF;\n    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n\n    display: flex;\n    flex-direction: column;\n    overflow: hidden;\n    .photoCardImage{\n        width: 200px;\n        height: 140px;\n    }\n    .order{\n        background-color: var(--secondary-color);\n        cursor: pointer;\n        width: 20px;\n        height: 20px;\n        right: 0px;\n        top: 5px;\n        z-index: 99;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        margin-top: -10px;\n        border-radius: 100%;\n        transition: 0.3s ease;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photoCard": `GalleryEditImage_photoCard__h2oY4`,
	"photoCardImage": `GalleryEditImage_photoCardImage__+ewZq`,
	"order": `GalleryEditImage_order__9VsmL`
};
export default ___CSS_LOADER_EXPORT___;
