import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MockApi } from '../../api/mock/mockApi'
import { TDeleteSlide, TEditSlide, TNomenclatures, TSliderItem } from '../../api/mock/typesApi'
import { instanceAxiosClose } from '../api/instances'
import { paths } from '../api/paths'
import { getInlineParams } from '../../helpers/helper'
import { openNotification } from '../../components/common/Notification/Notification'

export interface IContactsSlice {
  loadings: {
    getSlider: boolean
    editSlider: boolean
    createSlider: boolean
    deleteSlider: boolean
  }
  sliderInfo: TSliderItem[] | []
  nomenclatures: TNomenclatures[] | any
}

const initialState: IContactsSlice = {
  loadings: {
    getSlider: false,
    editSlider: false,
    createSlider: false,
    deleteSlider: false,
  },
  sliderInfo: [],
  nomenclatures: [],
}

const mockApi = new MockApi()

export const getSlider = createAsyncThunk<any>('slider/getSlider', async () => {
  const data = await instanceAxiosClose().get(paths.sliderService.slide)
  return data?.data
})
export const getNomenclatures = createAsyncThunk<any>('slider/nomenclatures', async () => {
  const data = await instanceAxiosClose().get(paths.sliderService.nomenclature)
  return data?.data
})
export const EditSlide = createAsyncThunk('slider/editslide', async (body: TEditSlide) => {
  const data = await instanceAxiosClose().post(paths.sliderService.slide, body)
  return data?.data
})
export const CreateSlide = createAsyncThunk('slider/createslide', async (body: TEditSlide) => {
  const data = await instanceAxiosClose().put(paths.sliderService.slide, body)
  return data?.data
})
export const DeleteSlide = createAsyncThunk('categories/DeleteTab', async (body: TDeleteSlide) => {
  const data = await instanceAxiosClose().delete(paths.sliderService.slide + getInlineParams(body))
  return data?.data
})

export const SliderSlice = createSlice({
  name: 'slider',
  initialState,
  reducers: {
    setSlider: (state, action) => {
      return { ...state, contactsInfo: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSlider.fulfilled, (state, action) => {
      state.sliderInfo = action.payload?.data?.value?.items
      state.loadings.getSlider = false
    })
    builder.addCase(getSlider.pending, (state, action) => {
      state.loadings.getSlider = true
    })
    builder.addCase(getSlider.rejected, (state, action) => {
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
      state.loadings.getSlider = false
    })
    builder.addCase(getNomenclatures.fulfilled, (state, action) => {
      state.nomenclatures = action.payload?.data?.value?.items
      state.loadings.getSlider = false
    })
    builder.addCase(getNomenclatures.pending, (state, action) => {
      state.loadings.getSlider = true
    })
    builder.addCase(getNomenclatures.rejected, (state, action) => {
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
      state.loadings.getSlider = false
    })
    builder.addCase(EditSlide.fulfilled, (state, action) => {
      state.loadings.getSlider = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(EditSlide.pending, (state, action) => {
      state.loadings.getSlider = true
    })
    builder.addCase(EditSlide.rejected, (state, action) => {
      state.loadings.getSlider = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(CreateSlide.fulfilled, (state, action) => {
      state.loadings.getSlider = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(CreateSlide.pending, (state, action) => {
      state.loadings.getSlider = true
    })
    builder.addCase(CreateSlide.rejected, (state, action) => {
      state.loadings.getSlider = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(DeleteSlide.fulfilled, (state, action) => {
      state.loadings.getSlider = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(DeleteSlide.pending, (state, action) => {
      state.loadings.getSlider = true
    })
    builder.addCase(DeleteSlide.rejected, (state, action) => {
      state.loadings.getSlider = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const { setSlider } = SliderSlice.actions

export default SliderSlice.reducer
