import { getSlider } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../components/common/Button/Button'
import ContactsComponent from '../../components/contacts/ContactsComponent'
import React, { useEffect } from 'react'
import { getCategories } from '../../store/slices/CategoriesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CContainer } from '@coreui/react'
import CategoriesComponent from '../../components/categories/CategoriesComponent'
import AboutCompanyComponent from '../../components/aboutCompany/AboutCompanyComponent'
import {
  getAboutBlock,
  getAboutPoster,
  getAchievements,
  getGalleryImages,
} from '../../store/slices/AboutSlice'
import Achievements from '../../components/Achievements/Achievements'
import GalleryBig from '../../components/GalleryBig/GalleryBig'
import { getCookie } from '../../helpers/cookies'
import { openNotification } from '../../components/common/Notification/Notification'

const AchievementsView: React.FC = ({}) => {
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(getAchievements())
    dispatch(getGalleryImages())
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  const achievements = useSelector((state: RootState) => state.AboutSlice.achievements)
  const images = useSelector((state: RootState) => state.AboutSlice.galleryImages)
  return (
    <>
      <CContainer fluid className="bg-white p-0 m-0 rounded">
        <Achievements items={achievements} />
      </CContainer>
      <CContainer fluid className="bg-white p-0 m-0 rounded">
        <GalleryBig images={images} />
      </CContainer>
    </>
  )
}

export default AchievementsView
