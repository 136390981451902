import { getSlider } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../components/common/Button/Button'
import ContactsComponent from '../../components/contacts/ContactsComponent'
import React, { useEffect, useState } from 'react'
import { getCategories } from '../../store/slices/CategoriesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CContainer } from '@coreui/react'
import CategoriesComponent from '../../components/categories/CategoriesComponent'
import { TLoading } from '../../store/types'
import { checkLoaders, getPropertiesAsArray } from '../../helpers/helper'
import { LoadingModal } from '../../components/Loading/LoadingModal'
import { getCookie } from '../../helpers/cookies'
import { openNotification } from '../../components/common/Notification/Notification'

const Categories: React.FC = ({}) => {
  const [loadObject, setLoadObject] = useState<TLoading>({ state: false })
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(getCategories())
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  const categoriesLoading = useSelector(
    (state: RootState) => state.CategoriesSlice.loadings.getCategories,
  )
  const categories = useSelector((state: RootState) => state.CategoriesSlice.categoriesInfo)
  // useEffect(() => {
  //   let loadingsArray = []
  //   loadingsArray = [...getPropertiesAsArray(categoriesLoading)]
  //   setLoadObject(checkLoaders([...loadingsArray]) || { state: false })
  //   console.log(loadObject)
  // }, [categoriesLoading])
  return (
    <>
      {/* <LoadingModal open={loadObject} /> */}
      <CContainer fluid className="bg-white p-0 m-0 rounded">
        <CategoriesComponent payload={categories} />
      </CContainer>
    </>
  )
}

export default Categories
