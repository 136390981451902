import { getSlider } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../components/common/Button/Button'
import ContactsComponent from '../../components/contacts/ContactsComponent'
import React, { useEffect } from 'react'
import { getCategories } from '../../store/slices/CategoriesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CContainer } from '@coreui/react'
import CategoriesComponent from '../../components/categories/CategoriesComponent'
import AboutCompanyComponent from '../../components/aboutCompany/AboutCompanyComponent'
import { getAboutBlock, getAboutPoster, getWorkWithUs } from '../../store/slices/AboutSlice'
import TellAboutUsComponent from '../../components/TellAboutUs/TellAboutUsComponent'
import WorkWithUsComponent from '../../components/workWIthUs/workWithUsComponent'
import { getCookie } from '../../helpers/cookies'
import { openNotification } from '../../components/common/Notification/Notification'

const WorkWithUsView: React.FC = ({}) => {
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(getWorkWithUs())
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  const workWithUs = useSelector((state: RootState) => state.AboutSlice.workWithUsInfo)
  const loading = useSelector((state: RootState) => state.AboutSlice.loadings)
  return (
    <CContainer fluid className="bg-white p-0 m-0 rounded">
      <WorkWithUsComponent workWithUs={workWithUs} loading={loading} />
    </CContainer>
  )
}

export default WorkWithUsView
