/* eslint-disable react/jsx-key */
import React, { useState } from 'react'
import { CCol, CContainer, CForm, CFormInput, CImage, CRow } from '@coreui/react'
import SkeletonLoading from '../Skeleton'
import { useEffect } from 'react'
import { useFooter } from '../../helpers/hooks/useFooter'
import { checkLoadings } from '../../helpers/loadings'
import Paper from '../../assets/icons/paper.svg'
import Phone from '../../assets/icons/phone.svg'
import Mail from '../..//assets/icons/mail.svg'
import Location from '../..//assets/icons/location.svg'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import {
  TContacts,
  TContactsRequisites,
  TInformation,
  TNewContacts,
  TRequisites,
} from '../../api/mock/typesApi'
import Button from '../common/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { EditInfo, editNewContacts, getContactsInfo } from '../../store/slices/ContactsSlice'
import { RootState } from '../../store/store'
import { setOriginalNode } from 'typescript'
import styles from './ContactsCompanent.module.scss'

interface ContactsProps {
  payload: TInformation | null
  newContacts: TNewContacts[] | []
  // payloadR: TRequisites | null
}
const ContactsComponent: React.FC<ContactsProps> = ({ payload, newContacts }) => {
  const contacts = useSelector((state: RootState) => state.ContactsSlice.contactsInfo)
  const loadings = useSelector((state: RootState) => state.ContactsSlice.loadings)
  // const newwContacts = useSelector((state: RootState) => state.ContactsSlice.newContacts)
  const dispatch = useDispatch<any>()
  const [name, setName] = useState<string>('')
  const [inn, setInn] = useState('')
  const [ogrni, setOgrni] = useState('')
  const [number, setNumber] = useState('')
  const [additionalNumber, setAdditionalNumber] = useState('')
  const [mail, setMail] = useState('')
  const [address, setAddress] = useState('')
  const [production, setProduction] = useState('')
  const [contactsEditValue, setContactsEditValue] = useState<any>(newContacts[0])
  const [contactsEditValue1, setContactsEditValue1] = useState<any>(newContacts[1])
  const [contactsEditValue2, setContactsEditValue2] = useState<any>(newContacts[2])
  const [contactsEditValue3, setContactsEditValue3] = useState<any>(newContacts[3])
  useEffect(() => {
    setContactsEditValue(newContacts[0])
    setContactsEditValue1(newContacts[1])
    setContactsEditValue2(newContacts[2])
    setContactsEditValue3(newContacts[3])
    console.log(contactsEditValue)
    console.log(contactsEditValue1)
  }, [newContacts])
  useEffect(() => {
    setName(contacts?.name)
    setInn(contacts?.inn)
    setOgrni(contacts?.ogrni)
    setNumber(contacts?.number)
    setAdditionalNumber(contacts?.additionalNumber)
    setMail(contacts?.mail)
    setAddress(contacts?.address)
    setProduction(contacts?.production)
  }, [contacts])
  const [edit, setEdit] = useState<boolean>(true)

  const editContactsHandler = () => {
    dispatch(
      EditInfo({
        name: name,
        inn: inn,
        ogrni: ogrni,
        number: number,
        additionalNumber: additionalNumber,
        mail: mail,
        address: address,
        production: production,
      }),
    )
      .then(() =>
        dispatch(
          editNewContacts({
            id: contactsEditValue.id,
            phone: contactsEditValue.phone,
            email: contactsEditValue.email,
          }),
        ),
      )
      .then(() =>
        dispatch(
          editNewContacts({
            id: contactsEditValue1.id,
            phone: contactsEditValue1.phone,
            email: contactsEditValue1.email,
          }),
        ),
      )
      .then(() =>
        dispatch(
          editNewContacts({
            id: contactsEditValue2.id,
            phone: contactsEditValue2.phone,
            email: contactsEditValue2.email,
          }),
        ),
      )
      .then(() =>
        dispatch(
          editNewContacts({
            id: contactsEditValue3.id,
            phone: contactsEditValue3.phone,
            email: contactsEditValue3.email,
          }),
        ),
      )
  }

  useEffect(() => {
    console.log(contactsEditValue)
  }, [contactsEditValue])
  return (
    <div>
      <SkeletonLoading style={{ minHeight: '430px' }} loading={checkLoadings(loadings)}>
        <CContainer
          fluid
          style={{
            overflowX: 'auto',
            minWidth: 500,
            padding: '10px 0 10px 0',
            borderRadius: '10px',
          }}
          className="bg-white border table-responsive"
        >
          <div style={{ width: '80%', margin: '0 auto', borderRadius: '10px' }}>
            <CCol className="m-2 p-0">
              <h4>Контакты</h4>
            </CCol>
            <CRow className="align-items-center pt-2">
              <CCol sm="auto" className="align-items-end" style={{ cursor: 'pointer' }}>
                {/* <CIcon
                  icon={cilPencil}
                  onClick={() => {
                    setEdit(!edit)
                  }}
                /> */}
              </CCol>
            </CRow>
            {/* <div>
              {contactsEditValue?.map((items: any, idx:number) => {return (
              <div>
                <span>Телефон{idx + 1}</span>
                <CFormInput
                    value={items?.phone || ''}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                <span>Email{idx + 1}</span>
                  <CFormInput
                    value={items?.email || ''}
                    onChange={(e) => setAddress(e.target.value)}
                  />
              </div>)})}</div> */}
            <CRow className="align-items-start">
              <CCol className="m-2 p-0">
                <CContainer className="p-20 m-0">
                  {/* <CImage alt={'ИНН'} src={Paper} width={25} height={25} /> */}
                  <CContainer className="p-1">
                    <CRow>
                      <h6>Телефон №1</h6>
                    </CRow>
                    <CRow>
                      <CFormInput
                        value={contactsEditValue?.phone || ''}
                        onChange={(e) =>
                          setContactsEditValue({ ...contactsEditValue, phone: e.target.value })
                        }
                      />
                    </CRow>
                  </CContainer>
                  {/* <CImage alt={'ИНН'} src={Paper} width={25} height={25} /> */}
                  <CContainer className="p-1">
                    <CRow>
                      <h6>Email №1</h6>
                    </CRow>
                    <CRow>
                      <CFormInput
                        value={contactsEditValue?.email || ''}
                        onChange={(e) =>
                          setContactsEditValue({ ...contactsEditValue, email: e.target.value })
                        }
                      />
                    </CRow>
                  </CContainer>
                  {/* <CImage alt={'ИНН'} src={Paper} width={25} height={25} /> */}
                  <CContainer className="p-1">
                    <CRow>
                      <h6>Телефон №2</h6>
                    </CRow>
                    <CRow>
                      <CFormInput
                        value={contactsEditValue1?.phone || ''}
                        onChange={(e) =>
                          setContactsEditValue1({ ...contactsEditValue1, phone: e.target.value })
                        }
                      />
                    </CRow>
                  </CContainer>
                  {/* <CImage alt={'ИНН'} src={Paper} width={25} height={25} /> */}
                  <CContainer className="p-1">
                    <CRow>
                      <h6>Email №2</h6>
                    </CRow>
                    <CRow>
                      <CFormInput
                        value={contactsEditValue1?.email || ''}
                        onChange={(e) =>
                          setContactsEditValue1({ ...contactsEditValue1, email: e.target.value })
                        }
                      />
                    </CRow>
                  </CContainer>
                </CContainer>
              </CCol>
              <CCol className="m-2 p-0">
                <CContainer className="p-20 m-0">
                  {/* <CImage alt={'ИНН'} src={Paper} width={25} height={25} /> */}
                  <CContainer className="p-1">
                    <CRow>
                      <h6>Телефон №3</h6>
                    </CRow>
                    <CRow>
                      <CFormInput
                        value={contactsEditValue2?.phone || ''}
                        onChange={(e) =>
                          setContactsEditValue2({ ...contactsEditValue2, phone: e.target.value })
                        }
                      />
                    </CRow>
                  </CContainer>
                  {/* <CImage alt={'ИНН'} src={Paper} width={25} height={25} /> */}
                  <CContainer className="p-1">
                    <CRow>
                      <h6>Email №3</h6>
                    </CRow>
                    <CRow>
                      <CFormInput
                        value={contactsEditValue2?.email || ''}
                        onChange={(e) =>
                          setContactsEditValue2({ ...contactsEditValue2, email: e.target.value })
                        }
                      />
                    </CRow>
                  </CContainer>
                  {/* <CImage alt={'ИНН'} src={Paper} width={25} height={25} /> */}
                  <CContainer className="p-1">
                    <CRow>
                      <h6>Телефон №4</h6>
                    </CRow>
                    <CRow>
                      <CFormInput
                        value={contactsEditValue3?.phone || ''}
                        onChange={(e) =>
                          setContactsEditValue3({ ...contactsEditValue3, phone: e.target.value })
                        }
                      />
                    </CRow>
                  </CContainer>
                  {/* <CImage alt={'ИНН'} src={Paper} width={25} height={25} /> */}
                  <CContainer className="p-1">
                    <CRow>
                      <h6>Email №4</h6>
                    </CRow>
                    <CRow>
                      <CFormInput
                        value={contactsEditValue3?.email || ''}
                        onChange={(e) =>
                          setContactsEditValue3({ ...contactsEditValue3, email: e.target.value })
                        }
                      />
                    </CRow>
                  </CContainer>
                </CContainer>
              </CCol>
            </CRow>
            <CCol className="m-2 p-0">
              <h4>Реквизиты</h4>
            </CCol>
            <CRow className="align-items-start">
              <CCol className="m-2 p-0">
                <CContainer className="p-0 m-0">
                  <CContainer className="p-1">
                    <CRow>
                      <CCol sm="auto" className="align-items-start">
                        <CImage alt={'ОФис'} src={Location} width={25} height={25} />
                      </CCol>
                      <CCol sm="auto" className="align-items-start">
                        <h6>Наш офис</h6>
                      </CCol>
                    </CRow>
                    <CRow>
                      {edit === false ? (
                        <CCol className="align-items-start">
                          {address ? address : 'Не указано'}
                        </CCol>
                      ) : (
                        <CForm>
                          <CFormInput
                            value={address || ''}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </CForm>
                      )}
                    </CRow>
                  </CContainer>
                  <CContainer className="p-1">
                    <CRow>
                      <CCol sm="auto" className="align-items-start">
                        <CImage alt={'Производство'} src={Location} width={25} height={25} />
                      </CCol>
                      <CCol sm="auto" className="align-items-start">
                        <h6>Наше производство</h6>
                      </CCol>
                    </CRow>
                    <CRow>
                      {edit === false ? (
                        <CCol className="align-items-start">
                          {production ? production : 'Не указано'}
                        </CCol>
                      ) : (
                        <CForm>
                          <CFormInput
                            value={production || ''}
                            onChange={(e) => setProduction(e.target.value)}
                          />
                        </CForm>
                      )}
                    </CRow>
                  </CContainer>
                </CContainer>
              </CCol>
              <CCol>
                <CContainer className="p-1">
                  <CRow>
                    <CCol sm="auto" className="align-items-start">
                      <CImage alt={'Наименование'} src={Paper} width={25} height={25} />
                    </CCol>
                    <CCol sm="auto" className="align-items-start">
                      <h6>Наименование</h6>
                    </CCol>
                  </CRow>
                  <CRow>
                    {edit === false ? (
                      <CCol className="align-items-start">{name ? name : 'Не указано'}</CCol>
                    ) : (
                      <CForm>
                        <CFormInput value={name || ''} onChange={(e) => setName(e.target.value)} />
                      </CForm>
                    )}
                  </CRow>
                </CContainer>
                <CContainer className="p-1">
                  <CRow>
                    <CCol sm="auto" className="align-items-start">
                      <CImage alt={'ИНН'} src={Paper} width={25} height={25} />
                    </CCol>
                    <CCol sm="auto" className="align-items-start">
                      <h6>ИНН</h6>
                    </CCol>
                  </CRow>
                  <CRow>
                    {edit === false ? (
                      <CCol className="align-items-start">{inn ? inn : 'Не указано'}</CCol>
                    ) : (
                      <CForm>
                        <CFormInput value={inn || ''} onChange={(e) => setInn(e.target.value)} />
                      </CForm>
                    )}
                  </CRow>
                </CContainer>
                <CContainer className="p-1">
                  <CRow>
                    <CCol sm="auto" className="align-items-start">
                      <CImage alt={'ОГРНИ'} src={Paper} width={25} height={25} />
                    </CCol>
                    <CCol sm="auto" className="align-items-start">
                      <h6>ОГРНИ</h6>
                    </CCol>
                  </CRow>
                  <CRow>
                    {edit === false ? (
                      <CCol className="align-items-start">{ogrni ? ogrni : 'Не указано'}</CCol>
                    ) : (
                      <CForm>
                        <CFormInput
                          value={ogrni || ''}
                          onChange={(e) => setOgrni(e.target.value)}
                        />
                      </CForm>
                    )}
                  </CRow>
                </CContainer>
              </CCol>
            </CRow>
            {edit && (
              <Button
                style={{ float: 'right', marginTop: '10px' }}
                onClick={(e) => {
                  editContactsHandler()
                }}
              >
                Сохранить
              </Button>
            )}
          </div>
        </CContainer>
      </SkeletonLoading>
    </div>
  )
}

export default ContactsComponent
