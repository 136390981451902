import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Tab } from 'react-tabs'
import styles from './TabsComponent.module.scss'
import Trash from '../../assets/icons/trash.svg'
import { CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCheckAlt, cilPencil } from '@coreui/icons'
import { useDispatch } from 'react-redux'
import { EditTab, getCategoryItem } from '../../store/slices/CategoriesSlice'

interface ITabComponent {
  index: number
  selectedIndex: number
  deleteTabHandler: Function
  tab: any
  category: any
}

const TabComponent: React.FC<ITabComponent> = ({
  index,
  selectedIndex,
  deleteTabHandler,
  tab,
  category,
}) => {
  const dispatch = useDispatch<any>()
  const [edit, setEdit] = useState<boolean>(false)
  const [showEditable, setSHowEditable] = useState<boolean>(false)
  const [editableTitle, setEditableTitle] = useState(tab?.title)
  console.log(tab)
  const acceptHandler = () => {
    dispatch(
      EditTab({
        title: editableTitle,
        categoryId: category.id,
        id: tab.id,
      }),
    ).then(() =>
      dispatch(
        getCategoryItem({
          id: category?.id,
        }),
      ),
    )
  }
  return (
    // <Tab
    //   key={index + ' tab'}
    //   className={styles.tabs__tabList__tab}
    //   style={{ position: 'relative' }}
    //   onClick={() => {
    //     setSHowEditable(true)
    //   }}
    // >
    <div
      className={
        styles.tabs__tabList__tab__title + (selectedIndex === index ? ` ${styles.activeTab}` : '')
      }
    >
      <div style={{ marginRight: '5px' }}>
        <img
          onClick={() => {
            deleteTabHandler()
          }}
          src={Trash}
          height={19}
          width={19}
          alt="delete"
          className={styles.trashButton}
        />
      </div>
      {edit ? (
        <CFormInput
          value={editableTitle}
          onChange={(e) => {
            setEditableTitle(e.target.value)
          }}
        ></CFormInput>
      ) : (
        tab?.title
      )}
      {edit ? (
        <div
          className={styles.AddBlockTab}
          onClick={() => {
            setEdit(!edit)
          }}
        >
          <CIcon
            size="xl"
            className={styles.check}
            icon={cilCheckAlt}
            style={{ color: 'black' }}
            height={19}
            width={19}
            onClick={() => {
              acceptHandler()
            }}
          />
        </div>
      ) : (
        <div
          style={{ marginLeft: '5px' }}
          className={styles.trashButton}
          onClick={() => {
            setEdit(!edit)
          }}
        >
          <CIcon
            icon={cilPencil}
            style={{ color: 'black' }}
            // className={styles.trashButton}
            // height={19}
            // width={19}
          />
        </div>
      )}
    </div>
    // </Tab>
  )
}

export default TabComponent
