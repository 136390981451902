import { CContainer } from '@coreui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SliderComponent from '../../components/slider/SliderComponent'
import { getContactsInfo } from '../../store/slices/ContactsSlice'
import { getSlider } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../components/common/Button/Button'
import ContactsComponent from '../../components/contacts/ContactsComponent'
import { getReviewsInfo } from '../../store/slices/RatingSlice'
import ReviewsList from '../../components/ReviewsList/ReviewsList'
import { getCookie } from '../../helpers/cookies'
import { openNotification } from '../../components/common/Notification/Notification'

const Reviews: React.FC = ({}) => {
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(
      getReviewsInfo({
        count: 9,
        offset: 0,
      }),
    )
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  const reviews = useSelector((state: RootState) => state.ReviewsSlice.reviewsInfo)
  const total = useSelector((state: RootState) => state.ReviewsSlice.total)
  const loading = useSelector((state: RootState) => state.ReviewsSlice.loadings)
  return (
    <CContainer fluid className="bg-white p-0 m-0 rounded">
      <ReviewsList reviews={reviews} loading={loading} total={total} />
    </CContainer>
  )
}

export default Reviews
