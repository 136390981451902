// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_mainModal__a4V5- .Modal_footer__JLyQM {
  display: flex;
  justify-content: end;
}
.Modal_mainModal__a4V5- .Modal_footer__JLyQM .Modal_button__So2vG {
  font-size: 0.8vw;
  padding: 0.2vw 0.5vw;
}
.Modal_mainModal__a4V5- .Modal_headerWithContent__jBcDh {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--primary-color) !important;
  color: white;
  font-size: 0.9vw;
}
.Modal_mainModal__a4V5- .Modal_headerWithoutContent__pYT\\+p {
  display: flex !important;
  justify-content: flex-end !important;
  width: 100%;
  background-color: var(--primary-color) !important;
  color: white;
  font-size: 0.9vw;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Modal/Modal.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,oBAAA;AAAR;AACQ;EACI,gBAAA;EACA,oBAAA;AACZ;AAGI;EACI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,iDAAA;EACA,YAAA;EACA,gBAAA;AADR;AAII;EACI,wBAAA;EACA,oCAAA;EACA,WAAA;EACA,iDAAA;EACA,YAAA;EACA,gBAAA;AAFR","sourcesContent":[".mainModal {\n    .footer {\n        display: flex;\n        justify-content: end;\n        .button {\n            font-size: 0.8vw;\n            padding: 0.2vw 0.5vw;\n        }\n    }\n\n    .headerWithContent {\n        display: flex;\n        justify-content: space-between;\n        width: 100%;\n        background-color: var(--primary-color) !important;\n        color: white;\n        font-size: 0.9vw;\n    }\n\n    .headerWithoutContent {\n        display: flex !important;\n        justify-content: flex-end !important;\n        width: 100%;\n        background-color: var(--primary-color) !important;\n        color: white;\n        font-size: 0.9vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainModal": `Modal_mainModal__a4V5-`,
	"footer": `Modal_footer__JLyQM`,
	"button": `Modal_button__So2vG`,
	"headerWithContent": `Modal_headerWithContent__jBcDh`,
	"headerWithoutContent": `Modal_headerWithoutContent__pYT+p`
};
export default ___CSS_LOADER_EXPORT___;
