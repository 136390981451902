import { CContainer } from '@coreui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SliderComponent from '../../components/slider/SliderComponent'
import { getContactsInfo } from '../../store/slices/ContactsSlice'
import { getNomenclatures, getSlider } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../components/common/Button/Button'
import { getCookie } from '../../helpers/cookies'
import { openNotification } from '../../components/common/Notification/Notification'

const Slider: React.FC = ({}) => {
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(getContactsInfo())
    dispatch(getSlider())
    dispatch(getNomenclatures())
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  // const contacts = useSelector((state: RootState) => state.ContactsSlice.newContactInfo)
  const slider = useSelector((state: RootState) => state.SliderSlice.sliderInfo)
  const nomenclatures = useSelector((state: RootState) => state.SliderSlice.nomenclatures)
  return (
    <CContainer fluid className="bg-white p-0 m-0 rounded">
      <SliderComponent items={slider} nomenclatures={nomenclatures} />
    </CContainer>
  )
}

export default Slider
