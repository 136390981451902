import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MockApi } from '../../api/mock/mockApi'
import { instanceAxiosClose } from '../api/instances'
import { paths } from '../api/paths'
import { TAddContacts, TInformation, TNewContacts, TPostNewContacts } from '../../api/mock/typesApi'
import { openNotification } from '../../components/common/Notification/Notification'

const mockApi = new MockApi()

export interface IContactsSlice {
  loadings: {
    getContacts: boolean
    getRequisites: boolean
  }
  contactsInfo: {
    name: string
    inn: string
    ogrni: string
    number: string
    additionalNumber: string
    mail: string
    address: string
    production: string
  }
  requisitesInfo: {
    name?: string
    inn?: string
    ogrni?: string
  }
  newContacts: TNewContacts[] | []
  newContactInfo: {
    address: string
    items:
      | {
          phone: string
          email: string
        }[]
      | []
    production: string
  }
}

const initialState: IContactsSlice = {
  loadings: {
    getContacts: false,
    getRequisites: false,
  },
  contactsInfo: {
    name: '',
    inn: '',
    ogrni: '',
    number: '',
    additionalNumber: '',
    mail: '',
    address: '',
    production: '',
  },
  requisitesInfo: {
    name: '',
    inn: '',
    ogrni: '',
  },
  newContacts: [],
  newContactInfo: {
    address: '',
    items: [],
    production: '',
  },
}

export const getContactsInfo = createAsyncThunk<any>('info/getInfo', async () => {
  const { data } = await instanceAxiosClose().get(paths.informationService.information)
  return data?.data
})
export const EditInfo = createAsyncThunk('info/EditInfo', async (body: TInformation) => {
  const data = await instanceAxiosClose().post(paths.informationService.information, body)
  return data?.data
})
export const getNewContacts = createAsyncThunk<any>('info/getContactsNew', async () => {
  const { data } = await instanceAxiosClose().get(paths.informationService.contacts)
  return data?.data
})
export const editNewContacts = createAsyncThunk(
  'info/postContactsNew',
  async (body: TPostNewContacts) => {
    const { data } = await instanceAxiosClose().post(paths.informationService.contact, body)
    return data?.data
  },
)
export const addNewContatcts = createAsyncThunk(
  'info/putContactsNew',
  async (body: TAddContacts) => {
    const { data } = await instanceAxiosClose().put(paths.informationService.contact, body)
    return data?.data
  },
)
export const getNewContact = createAsyncThunk<any>('info/getContactNew', async () => {
  const { data } = await instanceAxiosClose().get(paths.informationService.contact)
  return data?.data
})
// export const getContactsInfo = createAsyncThunk<any>('contacts/getContacts', async () => {
//   const data = await mockApi.getContacts({ mock: true })
//   return data?.data
// })

// export const getRequisitesInfo = createAsyncThunk<any>('contacts/getRequisites', async () => {
//   const data = await mockApi.getRequisites({ mock: true })
//   return data?.data
// })

export const ContactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContactsInfo.pending, (state, action) => {
      state.loadings.getContacts = true
    })
    builder.addCase(getContactsInfo.fulfilled, (state, action) => {
      state.loadings.getContacts = false
      state.contactsInfo = action?.payload?.value
    })
    builder.addCase(getContactsInfo.rejected, (state, action) => {
      state.loadings.getContacts = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getNewContact.pending, (state, action) => {
      state.loadings.getContacts = true
    })
    builder.addCase(getNewContact.fulfilled, (state, action) => {
      state.loadings.getContacts = false
      state.newContactInfo = action?.payload?.value
    })
    builder.addCase(getNewContact.rejected, (state, action) => {
      state.loadings.getContacts = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getNewContacts.pending, (state, action) => {
      state.loadings.getContacts = true
    })
    builder.addCase(getNewContacts.fulfilled, (state, action) => {
      state.loadings.getContacts = false
      state.newContacts = action?.payload?.value?.items
    })
    builder.addCase(getNewContacts.rejected, (state, action) => {
      state.loadings.getContacts = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(editNewContacts.pending, (state, action) => {
      state.loadings.getContacts = true
    })
    builder.addCase(editNewContacts.fulfilled, (state, action) => {
      state.loadings.getContacts = false
      // openNotification({ type: 'success', text: 'успешно' })
    })
    builder.addCase(editNewContacts.rejected, (state, action) => {
      state.loadings.getContacts = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(addNewContatcts.pending, (state, action) => {
      state.loadings.getContacts = true
    })
    builder.addCase(addNewContatcts.fulfilled, (state, action) => {
      state.loadings.getContacts = false
      // openNotification({ type: 'success', text: 'успешно' })
    })
    builder.addCase(addNewContatcts.rejected, (state, action) => {
      state.loadings.getContacts = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(EditInfo.pending, (state, action) => {
      state.loadings.getContacts = true
    })
    builder.addCase(EditInfo.fulfilled, (state, action) => {
      state.loadings.getContacts = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(EditInfo.rejected, (state, action) => {
      state.loadings.getContacts = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {} = ContactsSlice.actions

export default ContactsSlice.reducer
