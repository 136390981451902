import axios from 'axios'
import { paths } from './paths'
import { authTokenUrl } from '../../helpers/appSettings'
import { getCookie } from '../../helpers/cookies'

export const instanceAxiosClose = (token?: string) => {
  return axios.create({
    baseURL: paths.baseURL,
    headers: {
      ContentType: 'application/json',
      Authorization: `Bearer ${String(getCookie('authTokenUrl'))}`,
    },
  })
}

export const instanceAxiosOpen = (token?: string) => {
  return axios.create({
    baseURL: paths.baseURL,
    headers: {
      ContentType: 'application/json',
    },
  })
}

export const instanceAxiosCloseFormData = (token?: string) => {
  return axios.create({
    baseURL: paths.baseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${String(getCookie(authTokenUrl))}`,
    },
  })
}

export const instanceAxiosClosefileToBase64 = (token?: string) => {
  return axios.create({
    baseURL: paths.baseURL,
    headers: {
      'Content-Type': 'application/octet-stream',
      Authorization: `Bearer ${String(getCookie(authTokenUrl))}`,
    },
    responseType: 'blob',
  })
}
