import React, { useState } from 'react'
import { TCategories } from '../../api/mock/typesApi'
import Button from '../common/Button/Button'
import styles from './CategoriesItem.module.scss'
import {
  CButton,
  CCard,
  CCardBody,
  CCardImage,
  CCardText,
  CCardTitle,
  CTooltip,
} from '@coreui/react'
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import CategoriesEditComponent from './CategoriesEdit'
import SkeletonLoading from '../Skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import {
  DeleteCategory,
  getCategories,
  getCategoryItem,
  setCategoryId,
} from '../../store/slices/CategoriesSlice'
import noPhoto from '../../assets/icons/placeholder.webp'
import Trash from '../../assets/icons/trash.svg'
import { checkLoadings } from '../../helpers/loadings'
import RightArrow from '../../assets/icons/rightArrow.svg'
import RightArrowWhite from '../../assets/icons/rightArrowWhite.svg'
interface CategoriesItemProps {
  items: any
  fluidFlag?: boolean
  loadings: any
}

const CategoriesItemComponent: React.FC<CategoriesItemProps> = ({ items, fluidFlag, loadings }) => {
  console.log(items)
  const dispatch = useDispatch<any>()
  const handleClick = async () => {
    // setCategoryId(items.id)
    // localStorage.setItem('IDCAT', items?.id)
    // dispatch(
    //   getCategoryItem({
    //     id: items.id,
    //   }),
    // )
  }
  const deleteCategoryHandler = () => {
    dispatch(
      DeleteCategory({
        id: items?.id,
      }),
    ).then(() => dispatch(getCategories()))
  }
  return (
    <SkeletonLoading
      style={{ minHeight: '294px', minWidth: '470px' }}
      loading={checkLoadings(loadings)}
    >
      <Link
        to={{
          pathname: `/categories/${items?.url}/${items?.id}`,
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <div
          className={styles.categoryItem + ' ' + (fluidFlag ? styles.rowDense : '')}
          onClick={() => handleClick()}
        >
          <div className={styles.trashIconCategoryCard} style={{ cursor: 'pointer' }}>
            <img
              onClick={(e) => {
                e.preventDefault()
                deleteCategoryHandler()
              }}
              src={Trash}
              width={20}
              height={20}
              //   className={styles.trashButton__button}
              alt="delete"
            />
          </div>
          <div className={styles.categoryItem__image}>
            <img
              src={items.image}
              // objectPosition={'inherit'}
              alt="category"
            />
          </div>
          <div className={styles.categoryItem__title}>
            <div className={styles.categoryItem__title__text} title={items.name}>
              {items.name}
            </div>
            <div className={styles.categoryItem__title__button}>
              {/* <Button className={styles.categoryItem__title__button__btn}> */}
              <>
                <img
                  className={styles.categoryItem__title__button__btn__imgBlue}
                  src={RightArrow}
                  width={32}
                  height={32}
                  alt="arrow"
                />
                {/* <img
                  className={styles.categoryItem__title__button__btn__imgWhite}
                  src={RightArrowWhite}
                  width={32}
                  height={32}
                  alt="arrow"
                /> */}
              </>
              {/* </Button> */}
            </div>
          </div>
        </div>
      </Link>
      {/* <div className={styles.main}>
        <CCard style={{ width: '18rem' }}>
          <div className={styles.trashIconCategoryCard} style={{ cursor: 'pointer' }}>
            <img
              onClick={() => {
                deleteCategoryHandler()
              }}
              src={Trash}
              width={20}
              height={20}
              //   className={styles.trashButton__button}
              alt="delete"
            />
          </div> */}
      {/* {items.image ? (
            <CCardImage orientation="top" src={items.image} height={215} width={160} />
          ) : (
            <CCardImage orientation="top" height={215} width={160} src={noPhoto} />
          )} */}
      {/* <CCardBody>
            <CTooltip content={items?.title}>
              <CCardTitle>
                {items?.title?.length < 20 ? items?.title : items?.title.slice(0, 20) + '...'}
              </CCardTitle>
            </CTooltip> */}
      {/* {item?.length < 10 ? item : item.slice(0, 10) + '...'} */}
      {/* <CCardText>{items.title}</CCardText> */}
      {/* <Link
              to={{
                pathname: `/categories/${items?.url}/${items?.id}`,
              }}
            >
              <CButton color="primary" href="#" onClick={() => handleClick()}>
                Редактировать
              </CButton>
            </Link>
          </CCardBody>
        </CCard>
      </div> */}
    </SkeletonLoading>
  )
}
export default CategoriesItemComponent
