import React, { CSSProperties } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

interface ISkeleton {
  loading: boolean
  children: any
  className?: string
  style?: CSSProperties
}

const SkeletonLoading: React.FC<ISkeleton> = ({ loading, children, className, style }) => {
  return loading ? (
    <SkeletonTheme baseColor="#d9d9d9" highlightColor="#c6c6c6">
      <Skeleton style={style} className={'border ' + className} />
    </SkeletonTheme>
  ) : (
    <>{children}</>
  )
}

export default SkeletonLoading
