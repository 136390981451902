import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoginData } from '../../api/mock/typesApi'
import { MockApi } from '../../api/mock/mockApi'
import { deleteCookie, getCookie, setCookie } from '../../helpers/cookies'
import { errorHandler } from '../../helpers/errors'
import { authRefreshTokenUrl, authTokenUrl } from '../../helpers/appSettings'
import { instanceAxiosClose } from '../api/instances'
import { paths } from '../api/paths'
import { openNotification } from '../../components/common/Notification/Notification'

const mockApi = new MockApi()

export type TUserData = any

export interface IAuthSlice {
  loadings: {
    auth: boolean
    user: boolean
  }
  userData: TUserData | null // TODO тип
}

const initialState: IAuthSlice = {
  loadings: {
    auth: false,
    user: false,
  },
  userData: null,
}

export const login = createAsyncThunk<any, LoginData>(
  'auth/login',
  async (payload, { dispatch }) => {
    const { data } = await instanceAxiosClose().post(paths.accountService.authorization, {
      login: payload.login,
      password: payload.password,
    })
    return data?.data
  },
)

export const getUserData = createAsyncThunk<any>('auth/user', async () => {
  const data = await mockApi.getUserData({ mock: true })
  return data?.data
})

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    deAuth: () => {
      deleteCookie(authTokenUrl)
      deleteCookie(authRefreshTokenUrl)
      window.location.href = '/login'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      setCookie('authTokenUrl', action?.payload?.value?.access_token)
      setCookie(authRefreshTokenUrl, action?.payload?.value?.access_token)
      state.loadings.auth = false
      window.location.href = '/'
      console.log('authTokenUrl', action?.payload?.value?.access_token)
      console.log(getCookie(authTokenUrl))
      openNotification({ type: 'success', text: 'Успешная авторизация!' })
    })
    builder.addCase(login.rejected, (state, action) => {
      state.loadings.auth = false
      errorHandler(action)
    })
    builder.addCase(login.pending, (state, action) => {
      state.loadings.auth = true
    })
  },
})

// Action creators are generated for each case reducer function
export const { deAuth } = AuthSlice.actions

export default AuthSlice.reducer
