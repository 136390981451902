import React, { useEffect, useState } from 'react'
import styles from './About.module.scss'
import CIcon from '@coreui/icons-react'
import { cilPencil, cilPlus } from '@coreui/icons'
import AboutMainEditModal from './AboutMainEditModal'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { getNomenclatures } from '../../store/slices/SliderSlice'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'

interface IAboutMain {
  payload: any
  loadings: any
}

const AboutMainComponent: React.FC<IAboutMain> = ({ payload, loadings }) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(getNomenclatures())
  }, [])
  const nomenclature = useSelector((state: RootState) => state.SliderSlice.nomenclatures)
  const newAboutHandler = () => {}
  return (
    <>
      <SkeletonLoading style={{ minHeight: '1000px' }} loading={checkLoadings(loadings)}>
        <div className={styles.about} style={{ padding: '70px 20px 20px 20px' }}>
          <div
            className={styles.about__editIcon}
            onClick={() => {
              setShowEditModal(true)
            }}
          >
            <CIcon icon={cilPencil} style={{ color: 'black' }} />
          </div>
          <div className={styles.about__left}>
            <div className={styles.about__left__header}>{payload?.titleMain}</div>
            <div
              className={styles.about__left__description}
              dangerouslySetInnerHTML={{
                __html: payload?.description,
              }}
            ></div>
            <div className={styles.about__left__itemList}>
              <ul>
                {payload?.itemList?.length &&
                  payload?.itemList.map((item: any, index: any) => {
                    return (
                      <li key={index + ' traktorAbout'}>
                        <span>{item.name}</span>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
          <div className={styles.about__right}>
            <div
              className={styles.about__right__image}
              style={{ objectPosition: 'inherit', objectFit: 'contain' }}
            >
              <img
                src={payload?.backgroundImage}
                alt="category"
                // style={{ width }}
                width={662}
                height={539}
              />
            </div>
          </div>
        </div>
        <div className={styles.aboutTiles} style={{ padding: '20px 20px 20px 20px' }}>
          <div className={styles.aboutTiles__tile}>
            <div className={styles.aboutTiles__tile__title}>20+</div>
            <div className={styles.aboutTiles__tile__description}>Лет на рынке</div>
          </div>
          <div className={styles.aboutTiles__tile}>
            <div className={styles.aboutTiles__tile__title}>1500+</div>
            <div className={styles.aboutTiles__tile__description}>Клиентская база юр. лиц</div>
          </div>
          <div className={styles.aboutTiles__tile}>
            <div className={styles.aboutTiles__tile__title}>12 мес</div>
            <div className={styles.aboutTiles__tile__description}>Гарантии на крупные узлы</div>
          </div>
          <div className={styles.aboutTiles__tile}>
            <div className={styles.aboutTiles__tile__title}>60+</div>
            <div className={styles.aboutTiles__tile__description}>Сотрудников</div>
          </div>
        </div>
      </SkeletonLoading>
      <AboutMainEditModal
        payload={payload}
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        saveHandler={newAboutHandler}
        nomenclatures={nomenclature}
      />
    </>
  )
}

export default AboutMainComponent
