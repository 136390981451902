import { TAchievementItem } from '../../api/mock/typesApi'
import React, { ReactElement, useState } from 'react'
import SliderCards from '../SliderCards/SliderCards'
import AchievementCart from './AchievementCard'
import styles from './Achievements.module.scss'
import AchievementsModal from './AchievementsModal'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'

interface IAchievements {
  items: TAchievementItem[] | []
}

const Achievements: React.FC<IAchievements> = ({ items }) => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [title, setTitle] = useState('')
  const [item, setItem] = useState<any>()
  const editSliderHandler = () => {
    console.log('')
  }
  const deleteHandler = () => {
    console.log('')
  }
  const openModal = (title: any, image: any) => {
    setTitle(title)
    setOpenEditModal(true)
  }
  const getCards = () => {
    let cardsArray: ReactElement[] = []
    items?.map((card, index) => {
      cardsArray.push(
        <AchievementCart
          key={index + ' achieve'}
          payload={card}
          openModal={openModal}
          deleteHandler={deleteHandler}
          setItem={setItem}
        />,
      )
    })
    return cardsArray
  }
  const getImages = () => {
    let images: any[] = []
    items?.map((card, index) => {
      images.push(card.image)
    })
    return images
  }
  return (
    <>
      <div className={styles.achievements} style={{ paddingTop: '20px' }}>
        <div className={styles.achievements__title}>Достижения и награды</div>
        <div className={styles.achievements__plusIcon}>
          <CIcon
            className={styles.icon}
            size="xl"
            onClick={() => setOpenEditModal(true)}
            icon={cilPlus}
          />
        </div>
        <div className={styles.achievements__content}>
          <SliderCards arrows={true} elements={getCards()} images={getImages()} />
        </div>
      </div>
      <AchievementsModal
        payload={item}
        open={openEditModal}
        handleClose={() => setOpenEditModal(false)}
        saveHandler={editSliderHandler}
      />
    </>
  )
}

export default Achievements
