import React, { CSSProperties, ReactElement } from 'react'
import styles from './Button.module.scss'

interface IButton {
  children?: ReactElement | string | never[] | never
  onClick?: (val: any) => any
  disabled?: boolean
  style?: CSSProperties
  color?: 'primary' | 'secondary'
  className?: string
}

const Button: React.FC<IButton> = ({
  children,
  onClick,
  disabled,
  style,
  color = 'primary',
  className = '',
}) => {
  const getButtonColor = () => {
    switch (color) {
      case 'primary':
        return styles.buttonPrimary
        break

      case 'secondary':
        return styles.buttonSecondary
        break

      default:
        return styles.buttonPrimary
        break
    }
  }

  const getHoverColor = () => {
    switch (color) {
      case 'primary':
        return styles.hoverPrimaryActive
        break

      case 'secondary':
        return styles.hoverSecondaryActive
        break

      default:
        return styles.hoverPrimaryActive
        break
    }
  }
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={style}
      className={
        styles.button +
        ' ' +
        getButtonColor() +
        ' ' +
        (!disabled ? getHoverColor() : styles.button__hoverDisable) +
        ' ' +
        className
      }
    >
      {children}
    </button>
  )
}

export default Button
