/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TPagination } from '../../store/types'
import { IOperationResult } from '../generated/data-contracts'
import { ContentType, HttpClient, RequestParams } from '../generated/http-client'
import {
  LoginData,
  TAboutBlock,
  TAboutPoster,
  TAdventages,
  TCategories,
  TContacts,
  TGetAchievements,
  TGetGalleryImages,
  TGetMainClients,
  TRequisites,
  TReviewItem,
  TReviews,
  TReviewsPageList,
  TService,
  TSlider,
  TWorkwithUs,
} from './typesApi'

export class MockApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  login = (data: LoginData, params: RequestParams = {}) =>
    this.request<any, IOperationResult>({
      path: `/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  getUserData = (params: RequestParams = {}) =>
    this.request<any, IOperationResult>({
      path: `/user`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  // получение контактов
  getContacts = (params: RequestParams = {}) =>
    this.request<TContacts, IOperationResult>({
      path: `/contacts`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  getService = (params: RequestParams = {}) =>
    this.request<TService, IOperationResult>({
      path: `/service`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  getReviews = (params: RequestParams = {}) =>
    this.request<TReviews, IOperationResult>({
      path: `/reviews`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  getReview = (params: RequestParams = {}) =>
    this.request<TReviewItem, IOperationResult>({
      path: `/review`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  // получение реквизитов
  getRequisites = (params: RequestParams = {}) =>
    this.request<TRequisites, IOperationResult>({
      path: `/requisites`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      ...params,
    })
  getReviewsPageList = (body: TPagination, params: RequestParams = {}) =>
    this.request<TReviewsPageList, IOperationResult>({
      path: `/getReviewsPageList`,
      method: 'GET',
      secure: true,
      body: body,
      type: ContentType.Json,
      ...params,
    })

  // получение категорий
  getCategories = (params: RequestParams = {}) =>
    this.request<TCategories, IOperationResult>({
      path: `/categories`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  //Получение категории
  getCategoryItem = (params: RequestParams = {}) =>
    this.request<TCategories, IOperationResult>({
      path: `/remont_and_pokupka`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      ...params,
    })

  getSlider = (params: RequestParams = {}) =>
    this.request<TSlider, IOperationResult>({
      path: `/slider`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    getAdventages = (params: RequestParams = {}) =>
		this.request<TAdventages, IOperationResult>({
			path: `/adventages`,
			method: "GET",
			secure: true,
			type: ContentType.Json,
			...params,
		});
    getAboutPoster = (params: RequestParams = {}) =>
		this.request<TAboutPoster, IOperationResult>({
			path: `/aboutPoster`,
			method: "GET",
			secure: true,
			// body: data,
			type: ContentType.Json,
			...params,
		});
    getAboutBlock = (params: RequestParams = {}) =>
		this.request<TAboutBlock, IOperationResult>({
			path: `/getAboutBlock`,
			method: "GET",
			secure: true,
			// body: data,
			type: ContentType.Json,
			...params,
		});
    getMainClients = (params: RequestParams = {}) =>
		this.request<TGetMainClients, IOperationResult>({
			path: `/getMainClients`,
			method: "GET",
			secure: true,
			// body: data,
			type: ContentType.Json,
			...params,
		});
    getAchievements = (params: RequestParams = {}) =>
		this.request<TGetAchievements, IOperationResult>({
			path: `/getAchievements`,
			method: "GET",
			secure: true,
			// body: data,
			type: ContentType.Json,
			...params,
		});
    getGalleryImages = (params: RequestParams = {}) =>
		this.request<TGetGalleryImages, IOperationResult>({
			path: `/getGalleryImages`,
			method: "GET",
			secure: true,
			// body: data,
			type: ContentType.Json,
			...params,
		});
    getWorkWithUs = (params: RequestParams = {}) =>
    this.request<TWorkwithUs, IOperationResult>({
      path: `/workWithUs`,
			method: "GET",
			secure: true,
			// body: data,
			type: ContentType.Json,
			...params,
    });

}
