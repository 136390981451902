import { useDispatch, useSelector } from 'react-redux'
import { addNewContatcts, getContactsInfo, getNewContact } from '../../store/slices/ContactsSlice'
import { RootState } from '../../store/store'
import { TContactsRequisites } from '../../api/mock/typesApi'

type TUseFooter = () => {
  requisites: {
    name?: string
    inn?: string
    ogrni?: string
  }
  contacts: {
    name: string
    inn: string
    ogrni: string
    number: string
    additionalNumber: string
    mail: string
    address: string
    production: string
  }
  loadings: {
    getContacts: boolean
    getRequisites: boolean
  }
  getContacts: () => void
  editContactsHandler: (payload: TContactsRequisites) => void
}

export const useFooter: TUseFooter = () => {
  const dispatch = useDispatch<any>()
  const requisites = useSelector((state: RootState) => state.ContactsSlice.contactsInfo)
  const contacts = useSelector((state: RootState) => state.ContactsSlice.contactsInfo)
  const loadings = useSelector((state: RootState) => state.ContactsSlice.loadings)
  const getContacts = () => {
    dispatch(getNewContact())
    dispatch(getContactsInfo())
  }

  const editContactsHandler = (payload: TContactsRequisites) => {
    console.log(payload)
  }

  return { getContacts, requisites, contacts, loadings, editContactsHandler }
}
