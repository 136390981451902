import { TMainClientItem } from '../../api/mock/typesApi'
import React, { useState } from 'react'
import ClientBlock from './ClientBlock'
import styles from './MainClients.module.scss'
import MainClientsModal from './MainClientsModal'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'

interface IMainClients {
  blocks: TMainClientItem[]
}

const MainClients: React.FC<IMainClients> = ({ blocks }) => {
  const [items, setItems] = useState<any>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const openModal = () => {
    setShowEditModal(true)
  }
  const deleteHandler = () => {
    console.log('')
  }
  if (blocks?.length) {
    return (
      <>
        <div className={styles.mainClients}>
          <div className={styles.mainClients__title} style={{ paddingTop: '10px' }}>
            Основные клиенты
          </div>
          <div className={styles.mainClients__plusIcon}>
            <CIcon
              className={styles.icon}
              size="xl"
              onClick={() => setShowEditModal(true)}
              icon={cilPlus}
            />
          </div>
          <div className={styles.mainClients__blocks}>
            {blocks.map((block, index) => {
              return (
                <ClientBlock
                  key={index + ' clientBlock'}
                  block={block}
                  openModal={openModal}
                  deleteHandler={deleteHandler}
                  setItems={setItems}
                />
              )
            })}
          </div>
        </div>
        <MainClientsModal
          payload={items}
          open={showEditModal}
          handleClose={() => setShowEditModal(false)}
        />
      </>
    )
  } else return <></>
}

export default MainClients
