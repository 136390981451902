// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Raiting_rating__MXPnu {
  position: relative;
  width: 100%;
  height: 21px;
  display: flex;
  justify-content: flex-start;
}
.Raiting_rating__MXPnu .Raiting_starRate__pGJeV {
  position: relative;
  min-height: 21px;
  min-width: 11px;
}
.Raiting_rating__MXPnu .Raiting_leftStar__YGhDU {
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/Rating/Raiting.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,2BAAA;AACJ;AAAI;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;AAER;AAGI;EACI,iBAAA;AADR","sourcesContent":[".rating{\n    position: relative;\n    width:100%;\n    height: 21px;\n    display: flex;\n    justify-content: flex-start;\n    .starRate{\n        position: relative;\n        min-height: 21px;\n        min-width: 11px;\n    }\n    .rightStar{\n        \n    }\n    .leftStar{\n        margin-right: 4px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rating": `Raiting_rating__MXPnu`,
	"starRate": `Raiting_starRate__pGJeV`,
	"leftStar": `Raiting_leftStar__YGhDU`
};
export default ___CSS_LOADER_EXPORT___;
