import React from 'react'
import styles from './Pagination.module.scss'
import Arrow from '../../assets/icons/arrowPagination.svg'

interface IPagination {
  pagination: { offset: number; count: number }
  onChange: (val: { offset: number; count: number }) => void
  total: number | undefined
}

const Pagination: React.FC<IPagination> = ({ pagination, onChange, total }) => {
  const activePage: number = pagination.offset / pagination.count + 1

  const renderWithTotal = (total: number) => {
    let renderArr = []
    for (let index = 0; index < total / pagination.count; index += 1) {
      if (index + 1 === activePage) {
        renderArr.push(
          <div className={styles.pagination__number + ' ' + styles.numberActive}>{index + 1}</div>,
        )
      } else {
        renderArr.push(
          <div
            className={styles.pagination__number}
            onClick={() => {
              onChange({ ...pagination, offset: index * pagination.count })
            }}
          >
            {index + 1}
          </div>,
        )
      }
    }
    if (total > 6) {
      if (activePage > renderArr.length - 4) {
        let startArr = renderArr.slice(renderArr.length - 6, renderArr.length)
        renderArr = [...startArr]
      } else {
        if (activePage > 4) {
          let startArr = renderArr.slice(activePage - 3, activePage + 1)
          let endArr = renderArr.slice(renderArr.length - 1)
          renderArr = [
            ...startArr,
            <div
              key={activePage}
              className={styles.pagination__dots}
              onClick={() =>
                onChange({
                  ...pagination,
                  offset: pagination.offset + pagination.count * 2,
                })
              }
            >
              ...
            </div>,
            ...endArr,
          ]
        } else {
          let startArr = renderArr.slice(0, 5)
          let endArr = renderArr.slice(renderArr.length - 1)
          renderArr = [
            ...startArr,
            <div
              key={activePage}
              className={styles.pagination__dots}
              onClick={() =>
                onChange({
                  ...pagination,
                  offset: 5 * pagination.count,
                })
              }
            >
              ...
            </div>,
            ...endArr,
          ]
        }
      }
    }
    if (activePage !== 1) {
      renderArr = [
        <div
          key={activePage}
          className={styles.pagination__arrow}
          onClick={() =>
            onChange({
              ...pagination,
              offset: pagination.offset - pagination.count,
            })
          }
        >
          <div className={styles.pagination__arrow__img + ' ' + styles.right}>
            <img className={styles.pagination__arrow__img__image} src={Arrow} alt="arrow" />
          </div>
        </div>,
        ...renderArr,
      ]
    }
    if (activePage !== renderArr.length) {
      renderArr = [
        ...renderArr,
        <div
          key={activePage}
          className={styles.pagination__arrow}
          onClick={() =>
            onChange({
              ...pagination,
              offset: pagination.offset + pagination.count,
            })
          }
        >
          <div className={styles.pagination__arrow__img}>
            <img className={styles.pagination__arrow__img__image} src={Arrow} alt="arrow" />
          </div>
        </div>,
      ]
    }

    return (
      <>
        {renderArr.map((item) => {
          return <>{item}</>
        })}
      </>
    )
  }
  return <div className={styles.pagination}>{total ? renderWithTotal(total) : null}</div>
}

export default Pagination
