import React, { useEffect, useState } from 'react'
import Modal from '../common/Modal/Modal'
import { CContainer, CForm, CFormCheck, CFormInput, CFormTextarea } from '@coreui/react'
import styles from './AboutCompanyEditModal.module.scss'
import InputImage from '../common/InputImage/InputImage'
import SliderEditList from '../slider/SliderEditList'
import { RootState } from '../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { EditAboutPoster, getAboutPoster } from '../../store/slices/AboutSlice'
import { DeleteFile, SaveFile } from '../../store/slices/FileSlice'

interface IAboutEditModal {
  open: boolean
  handleClose: (val?: any) => void
  payload?: any
  saveHandler: (payload: any) => void
}

const AboutEditModal: React.FC<IAboutEditModal> = ({ open, handleClose, payload, saveHandler }) => {
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<any>(
    payload
      ? payload
      : {
          background: '',
          titleFront: '',
          titleMain: '',
          description: '',
          itemList: [],
          supportBtn: false,
          catalogBtn: false,
        },
  )
  const handleSavePoster = (model: any) => {
    if (file === null) {
      dispatch(
        EditAboutPoster({
          id: payload.id,
          titleFront: model?.titleFront,
          titleMain: model?.titleMain,
          description: model?.description,
          supportButton: model?.supportButton,
          catalogButton: model?.catalogButton,
          nomenclatures: model?.itemList,
        }),
      )
        .then(() => dispatch(getAboutPoster()))
        .then(() => handleClose())
    } else {
      dispatch(
        EditAboutPoster({
          id: payload.id,
          titleFront: model?.titleFront,
          titleMain: model?.titleMain,
          description: model?.description,
          supportButton: model?.supportButton,
          catalogButton: model?.catalogButton,
          nomenclatures: model?.itemList,
        }),
      )
        .then(() =>
          dispatch(
            DeleteFile({
              Id: payload?.id,
              Type: 'Slide',
              Flag: 'D',
              Name: payload?.backgroundImage?.split('/')[
                payload?.backgroundImage?.split('/').length - 1
              ],
            }),
          ),
        )
        .then(() => {
          if (file) {
            const formData = new FormData()
            formData.append('Files', file)
            formData.append(
              'Json',
              JSON.stringify({
                Flag: 'I',
                Id: payload.id,
                Type: 'Slide',
                Files: [
                  {
                    Name: file?.name,
                    Extention: file?.name.split('.')[file.name.split('.').length - 1].toUpperCase(),
                    dataType: '2',
                    Rewrite: true,
                  },
                ],
              }),
            )
            dispatch(
              SaveFile({
                formData: {
                  Files: file,
                  Json: JSON.stringify({
                    Flag: 'I',
                    Id: payload.id,
                    Type: 'Slide',
                    Files: [
                      {
                        Name: file?.name,
                        Extention: file?.name
                          .split('.')
                          [file.name.split('.').length - 1].toUpperCase(),
                        dataType: '2',
                        Rewrite: true,
                      },
                    ],
                  }),
                },
              }),
            )
              .then(() => dispatch(getAboutPoster()))
              .then(() => handleClose())
          }
        })
    }
  }
  useEffect(() => {
    console.log(model)
  }, [model])
  useEffect(() => {
    setModel(payload)
    console.log(payload)
  }, [payload])
  const [file, setFile] = useState<any>(null)
  return (
    <Modal
      header={payload ? 'Редактирование постера' : 'Создание постера'}
      open={open}
      handleClose={handleClose}
      onOk={() => handleSavePoster(model)}
      okText={'Сохранить'}
    >
      <CContainer fluid>
        <CForm className={styles.form}>
          <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Заголовок</span>
            <CFormInput
              value={model.titleFront}
              onChange={(e) => setModel({ ...model, titleFront: e.target.value })}
              placeholder="Заголовок"
            />
          </div>
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <span className="text-medium-emphasis">Подзаголовок</span>
            <CFormInput
              value={model.titleMain}
              onChange={(e) => setModel({ ...model, titleMain: e.target.value })}
              placeholder="Подзаголовок"
            />
          </div>
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <span className="text-medium-emphasis">Описание</span>
            <CFormTextarea
              value={model.description}
              rows={6}
              onChange={(e) => setModel({ ...model, description: e.target.value })}
              placeholder="Описание"
            />
          </div>
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <span className="text-medium-emphasis">Список тракторов</span>
            <SliderEditList
              setItems={(val) => {
                console.log(val)
                setModel({
                  ...model,
                  itemList: val.map((item, idx) => ({
                    id: item?.id,
                    order: idx,
                    name: item?.name,
                  })),
                })
              }}
              items={model.itemList}
              nomenclatures={''}
            />
          </div>
          <div className={styles.form__checkers} style={{ marginTop: '10px' }}>
            <div className={styles.form__checkers__checker} style={{ marginTop: '10px' }}>
              <span className="text-medium-emphasis">Кнопка &quot;Связаться с нами&quot;</span>
              <CFormCheck
                checked={model.supportButton}
                onChange={(e) => setModel({ ...model, supportButton: !model.supportButton })}
              />
            </div>
            <div className={styles.form__checkers__checker} style={{ marginTop: '10px' }}>
              <span className="text-medium-emphasis">Кнопка &quot;Каталог&quot;</span>
              <CFormCheck
                checked={model.catalogButton}
                onChange={(e) => setModel({ ...model, catalogButton: !model.catalogButton })}
              />
            </div>
          </div>
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <InputImage setFile={setFile} />
          </div>
        </CForm>
      </CContainer>
    </Modal>
  )
}

export default AboutEditModal
