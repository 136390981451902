import { useEffect, useState } from 'react'
import { TSliderItem } from '../../api/mock/typesApi'
import { useDispatch } from 'react-redux'
import { DeleteSlide, EditSlide, getSlider } from '../../store/slices/SliderSlice'

type IUseSlider = (
  swiper: any,
  items?: TSliderItem[],
) => {
  deleteHandler: (index: number) => void
  manuallyChangeSlide: (activeIndex: number, click?: boolean) => void
  setStopInterval: (val: boolean) => void
  currentSliderKey: number
  progressValue: number
  currentSliderContent: TSliderItem | null
  showNewModal: boolean
  setShowNewModal: (val: boolean) => void
  newSliderHandler: (payload: TSliderItem) => void
  editSliderHandler: (payload: TSliderItem) => void
}

export const useSlider: IUseSlider = (swiper, items) => {
  const dispatch = useDispatch<any>()
  const [currentSliderContent, setCurrentSliderContent] = useState<TSliderItem | null>(
    items?.length ? items[0] : null,
  )
  const [currentSliderKey, setCurrentSliderKey] = useState<number>(0)
  const [progressValue, setProgressValue] = useState<number>(0)
  const [showNewModal, setShowNewModal] = useState<boolean>(false)
  const [stopInterval, setStopInterval] = useState<any>(null)
  const itemLength = items?.length || 0

  const deleteHandler = (index: number) => {
    if (items?.length) {
      console.log(items[index])
    }
    dispatch(
      DeleteSlide({
        id: index,
      }),
    ).then(() => dispatch(getSlider()))
  }

  const newSliderHandler = (payload: TSliderItem) => {
    console.log(payload)
  }

  const editSliderHandler = (payload: TSliderItem) => {
    dispatch(
      EditSlide({
        id: payload.id,
        name: payload.name,
        titleMain: payload.titleMain,
        description: payload.description,
        supportButton: payload.supportButton,
        catalogButton: payload.catalogButton,
        nomenclatures: payload.itemList,
      }),
    ).then(() => dispatch(getSlider()))
  }

  const manuallyChangeSlide = (activeIndex: number, click?: boolean) => {
    if (activeIndex !== currentSliderKey) {
      if (click) {
        swiper.slideTo(activeIndex)
        setCurrentSliderKey(activeIndex)
        setProgressValue(0)
        if (items?.length) {
          setCurrentSliderContent(items[activeIndex])
        }
      } else {
        swiper.slideTo(activeIndex)
        setCurrentSliderKey(activeIndex)
        setProgressValue(0)
        if (items?.length) {
          setCurrentSliderContent(items[activeIndex])
        }
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!stopInterval) {
        if (progressValue <= 1) {
          setProgressValue(progressValue + 0.001)
        } else {
          if (currentSliderKey >= itemLength - 1) {
            setCurrentSliderKey(0)
            swiper.slideTo(0)
          } else {
            if (progressValue >= 1) {
              setCurrentSliderKey(currentSliderKey + 1)
              swiper.slideNext()
            }
          }
          setProgressValue(0)
        }
      }
    }, 10)
    return () => {
      clearInterval(interval)
    }
  }, [progressValue, stopInterval])

  return {
    deleteHandler,
    manuallyChangeSlide,
    setStopInterval,
    currentSliderKey,
    progressValue,
    currentSliderContent,
    showNewModal,
    setShowNewModal,
    newSliderHandler,
    editSliderHandler,
  }
}
