import { TAboutBlock, TAboutPoster } from '../../api/mock/typesApi'
import React, { useEffect, useState } from 'react'
import Button from '../common/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import styles from './AboutCompanyComponent.module.scss'
import { CContainer } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import AboutEditModal from './AboutCompanyEditModal.module'
import { getNomenclatures } from '../../store/slices/SliderSlice'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'
import { RootState } from '../../store/store'

interface AboutProps {
  aboutPoster: TAboutPoster
  aboutBlock: TAboutBlock
}

const AboutCompanyComponent: React.FC<AboutProps> = ({ aboutPoster, aboutBlock }) => {
  const loading = useSelector((state: RootState) => state.AboutSlice.loadings)
  const [windowWidth, setWindowWidth] = useState(-1)
  const dispatch = useDispatch<any>()
  const [mobile, setMobile] = useState(windowWidth < 750 && windowWidth !== -1 ? true : false)
  const [showEditModal, setShowEditModal] = useState(false)
  useEffect(() => {
    dispatch(getNomenclatures())
  })
  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect(() => {
    if (windowWidth <= 750 && windowWidth !== -1) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }, [windowWidth])

  const handleResize = () => {
    if (window) {
      setWindowWidth(window.innerWidth)
      windowWidth <= 750 ? setMobile(true) : setMobile(false)
    }
  }
  return (
    <>
      <SkeletonLoading
        style={{ minHeight: '605px', borderRadius: '16px' }}
        loading={checkLoadings(loading)}
      >
        <CContainer fluid className={styles.main}>
          <div className={styles.aboutPoster}>
            <div
              className={styles.editIcon}
              onClick={() => {
                setShowEditModal(true)
              }}
            >
              <CIcon icon={cilPencil} style={{ color: 'black' }} />
            </div>
            {mobile ? (
              <div
                className={styles.sliderItemBackground}
                style={{
                  backgroundImage: `radial-gradient(circle, rgba(0, 0, 0, 0.2) 45%, #1f3b59, transparent 145%), url(${aboutPoster?.backgroundImage})`,
                }}
              ></div>
            ) : (
              <div
                className={styles.sliderItemBackground}
                style={{
                  backgroundImage: `linear-gradient(to right, #1f3b59 25%, transparent 100%), url(${aboutPoster?.backgroundImage})`,
                }}
              ></div>
            )}
            <div className={styles.aboutPoster__content}>
              <div className={styles.aboutPoster__content__titleFront}>
                {aboutPoster?.titleFront}
              </div>
              <div className={styles.aboutPoster__content__title}>
                <h1>{aboutPoster?.titleMain}</h1>
              </div>
              <div className={styles.aboutPoster__content__description}>
                <div>{aboutPoster?.description}</div>
              </div>
              <div className={styles.aboutPoster__content__itemList}>
                <ul>
                  {aboutPoster?.itemList?.length &&
                    aboutPoster?.itemList.map((item, index) => {
                      return (
                        <li key={index + ' traktor'}>
                          <span>{item.name}</span>
                        </li>
                      )
                    })}
                </ul>
              </div>
              <div className={styles.aboutPoster__content__buttons}>
                {aboutPoster.supportButton ? (
                  <Button
                    className={styles.aboutPoster__content__buttons__button}
                    color={'secondary'}
                    onClick={() => console.log()}
                  >
                    Связаться с нами
                  </Button>
                ) : (
                  <></>
                )}
                {aboutPoster.catalogButton ? (
                  <Button className={styles.aboutPoster__content__buttons__button}>
                    Каталог продукции
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {mobile ? (
            <div className={styles.mobileBlock}>
              <div className={styles.mobileBlock__aboutPoster__content}>
                <div className={styles.mobileBlock__aboutPoster__content__titleFront}>
                  {aboutPoster?.titleFront}
                </div>
                <div className={styles.mobileBlock__aboutPoster__content__title}>
                  <h1>{aboutPoster?.titleMain}</h1>
                </div>
                <div className={styles.mobileBlock__aboutPoster__content__description}>
                  <div>{aboutPoster?.description}</div>
                </div>
                <div className={styles.mobileBlock__aboutPoster__content__itemList}>
                  <ul>
                    {aboutPoster?.itemList?.length &&
                      aboutPoster?.itemList.map((item, index) => {
                        return (
                          <li key={index + ' traktor'}>
                            <span>{item}</span>
                          </li>
                        )
                      })}
                  </ul>
                </div>
                <div className={styles.mobileBlock__aboutPoster__content__buttons}>
                  <Button
                    className={styles.mobileBlock__aboutPoster__content__buttons__button}
                    color={'secondary'}
                    onClick={() => console.log()}
                  >
                    Связаться с нами
                  </Button>
                  <Button className={styles.mobileBlock__aboutPoster__content__buttons__button}>
                    Каталог продукции
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </CContainer>
      </SkeletonLoading>
      <AboutEditModal
        payload={aboutPoster}
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        saveHandler={() => console.log()}
      />
    </>
  )
}

export default AboutCompanyComponent
