import { CContainer } from '@coreui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SliderComponent from '../../components/slider/SliderComponent'
import { getContactsInfo, getNewContacts } from '../../store/slices/ContactsSlice'
import { getSlider } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../components/common/Button/Button'
import ContactsComponent from '../../components/contacts/ContactsComponent'
import SkeletonLoading from '../../components/Skeleton'
import { checkLoadings } from '../../helpers/loadings'
import { getCookie } from '../../helpers/cookies'
import { openNotification } from '../../components/common/Notification/Notification'

const Contacts: React.FC = ({}) => {
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(getContactsInfo())
    dispatch(getNewContacts())
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  const contactss = useSelector((state: RootState) => state.ContactsSlice.contactsInfo)
  const newContacts = useSelector((state: RootState) => state.ContactsSlice.newContacts)

  return (
    // <SkeletonLoading style={{ minHeight: '1000px' }} loading={checkLoadings(loadings)}>
    <CContainer fluid className="bg-white p-0 m-0 rounded" style={{ borderRadius: '10px' }}>
      <ContactsComponent payload={contactss} newContacts={newContacts} />
    </CContainer>
  )
}

export default Contacts
