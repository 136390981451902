/* eslint-disable react/jsx-key */
import React, { ReactElement, useEffect, useState } from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import styles from './SliderCards.module.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import {
  MOBILE_WIDTH,
  SMALL_DESKTOP_WIDTH,
  SMALL_MOBILE_WIDTH,
  TABLET_WIDTH,
} from '../../styles/variables'
import FullsizeGallery from '../FullsizeGallery/FullsizeGallery'
import ArrowBottom from '../../assets/icons/ArrowBottom.svg'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'

interface ISliderCards {
  elements: ReactElement[] | string[] | never[] | never
  arrows?: boolean
  images?: string[] | []
  loading?: any
}

const SliderCards: React.FC<ISliderCards> = ({ elements, images, arrows, loading }) => {
  const [swiper, setSwiper] = useState<any>(null)
  const [openPreview, setOpenPreview] = useState<number | null>(null)
  const manuallyChangeSlide = (activeIndex: number) => {
    if (swiper) swiper.slideTo(activeIndex)
  }
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0)
    }
  }, [swiper])

  const handlePrev = () => {
    if (!swiper) return
    swiper.slidePrev()
  }

  const handleNext = () => {
    if (!swiper) return
    swiper.slideNext()
  }

  const pagination = {
    clickable: true,
    horizontalClass: styles.paginationBlock,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + ' ' + styles.paginationBullet + '">' + '</span>'
    },
  }

  const [windowWidth, setWindowWidth] = useState(3)

  const [slidesPerView, setSlidesPerView] = useState<number>(0)

  const [spaceBetween, setSpaceBeetween] = useState<number>(5)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const handleResize = () => {
    if (window) {
      setWindowWidth(window.innerWidth)
    }
  }

  useEffect(() => {
    if (windowWidth !== -1) {
      if (windowWidth <= SMALL_MOBILE_WIDTH) {
        if (swiper) swiper.slideTo(0)
        setSlidesPerView(1)
        setSpaceBeetween(0)
      } else {
        if (windowWidth <= MOBILE_WIDTH) {
          if (swiper) swiper.slideTo(0)
          setSlidesPerView(1)
          setSpaceBeetween(-45)
        } else {
          if (windowWidth <= TABLET_WIDTH) {
            if (swiper) swiper.slideTo(0)
            setSlidesPerView(2)
            setSpaceBeetween(5)
          } else {
            if (windowWidth > TABLET_WIDTH) {
              if (swiper) swiper.slideTo(0)
              setSlidesPerView(3)
              setSpaceBeetween(5)
            } else {
              if (swiper) swiper.slideTo(0)
              setSlidesPerView(1)
              setSpaceBeetween(-45)
            }
          }
        }
      }
    }
  }, [windowWidth])

  return (
    <div className={styles.sliderCards}>
      <div className={styles.mainSlider}>
        <Swiper
          pagination={pagination}
          modules={[Pagination]}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          initialSlide={0}
          // centeredSlides={true}
          // cssMode={true}
          onInit={(val: any) => setSwiper(val)}
          className={styles.sliderSwiper}
          onSlideChange={(val: any) => manuallyChangeSlide(val.activeIndex)}
        >
          {elements?.length
            ? elements.map((element, index) => {
                return (
                  <SkeletonLoading
                    style={{ minHeight: '430px', borderRadius: '16px' }}
                    loading={checkLoadings(loading)}
                  >
                    <SwiperSlide
                      onClick={() => (images?.length ? setOpenPreview(index) : null)}
                      key={index + 1 + ' card'}
                    >
                      <div>{element}</div>
                    </SwiperSlide>
                  </SkeletonLoading>
                )
              })
            : null}
        </Swiper>
        {arrows && images?.length ? (
          <>
            <div className={styles.prevArrow} onClick={handlePrev}>
              <div className={styles.prevArrow__img}>
                <img className={styles.prevArrow__img__svg} src={ArrowBottom} alt="arrow" />
              </div>
            </div>
            <div className={styles.nextArrow} onClick={handleNext}>
              <div className={styles.nextArrow__img}>
                <img className={styles.nextArrow__img__svg} src={ArrowBottom} alt="arrow" />
              </div>
            </div>
          </>
        ) : null}
      </div>
      {/* {images?.length ? (
        <FullsizeGallery
          onChange={manuallyChangeSlide}
          closeHandler={() => setOpenPreview(null)}
          images={images}
          open={openPreview}
        />
      ) : null} */}
      <div className={styles.paginationEmptyBlock}></div>
    </div>
  )
}

export default SliderCards
