import React, { useEffect, useState } from 'react'
import AppSidebar from '../AppSidebar'
import AppHeader from '../AppHeader'
import AppFooter from '../AppFooter'
import CategoriesEdit from './CategoriesEditComponent'

import { useDispatch, useSelector } from 'react-redux'
import { getCategoryItem } from '../../store/slices/CategoriesSlice'
import { RootState } from '../../store/store'
import { useParams } from 'react-router-dom'
interface CategoriesEditProps {}

const CategoriesEditComponent: React.FC<CategoriesEditProps> = () => {
  const dispatch = useDispatch<any>()
  const params = useParams()
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CategoriesEdit />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default CategoriesEditComponent
