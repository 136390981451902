import React, { Component, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DefaultLayout from './layout/DefaultLayout'
import './scss/style.scss'
import Page404 from './views/pages/page404/Page404'
import Page500 from './views/pages/page500/Page500'
import Register from './views/pages/register/Register'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import CategoriesEditComponent from './components/categories/CategoriesEdit'
import CategoriesCreateComponent from './components/categories/CategoriesCreate'
import 'react-toastify/dist/ReactToastify.css'
import { TLoading } from './store/types'
import FooterView from './views/footer/footer'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ToastContainer />
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/404" element={<Page404 />} />
            <Route path="/500" element={<Page500 />} />
            <Route path="*" element={<DefaultLayout />} />
            <Route path="/categories/:url/:id" element={<CategoriesEditComponent />} />
            <Route path="/categories/create" element={<CategoriesCreateComponent />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
