// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutCompanyEditModal_form__formInput__HtYXW {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/aboutCompany/AboutCompanyEditModal.module.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR","sourcesContent":[".form{\n    &__formInput{\n        margin-top: 10px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form__formInput": `AboutCompanyEditModal_form__formInput__HtYXW`
};
export default ___CSS_LOADER_EXPORT___;
