/* eslint-disable react/jsx-key */
import { cilCheck, cilCheckAlt, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CFormInput, CFormSelect } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import styles from './slider.module.scss'
import Trash from '../../assets/icons/trash.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getNomenclatures } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { TNomenclatures } from '../../api/mock/typesApi'

interface ISliderEditList {
  items: TNomenclatures[] | []
  setItems: (val: TNomenclatures[] | []) => void
  nomenclatures: any
}

const SliderEditList: React.FC<ISliderEditList> = ({ items, setItems, nomenclatures }) => {
  const nomenclature = useSelector((state: RootState) => state.SliderSlice.nomenclatures)

  useEffect(() => {
    console.log(items)
  }, [items])
  const handleDelete = (index: number) => {
    setItems(items.filter((_item, idx) => idx !== index))
  }
  const [newItem, setNewItem] = useState<boolean>(false)
  const [newItemValue, setNewItemValue] = useState<any>()
  const setNewItemHandler = (val: TNomenclatures) => {
    console.log(items)
    if (items === undefined) {
      setItems([val])
      setNewItem(false)
      setNewItemValue('')
    } else {
      setItems([...items, val])
      setNewItem(false)
      setNewItemValue('')
    }
  }
  const changeNewItemHandler = (e: any) => {
    setNewItemValue(nomenclature.find((item: any) => item.id == e.currentTarget.value))
  }
  const [currentItem, setCurrentItem] = useState<any>()

  function dragStartHandler(e: any, item: any, index: number) {
    setCurrentItem({ item: item, index: index })
    console.log('dragsS', item)
  }

  function dragLeaveHandler(e: any) {
    e.currentTarget.classList.remove(styles.item__over)
  }

  function dragEndHandler(e: any) {
    // e.target.style.background = 'white'
  }

  function dragOverHandler(e: any) {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.classList.add(styles.item__over)
  }

  function dropHandler(e: any, index: number) {
    e.currentTarget.classList.remove()
    let newArr = [...items]
    if (index < currentItem.index) {
      const leftHandSplice = items.filter((_item, idx) => idx < index)
      const rightHandSplice = items.filter(
        (_item, idx) => idx !== currentItem.index && idx >= index,
      )
      newArr = [...leftHandSplice, currentItem.item, ...rightHandSplice]
    } else {
      if (index > currentItem.index) {
        const leftHandSplice = items.filter(
          (_item, idx) => idx <= index && idx !== currentItem.index,
        )
        const rightHandSplice = items.filter((_item, idx) => idx > index)
        newArr = [...leftHandSplice, currentItem.item, ...rightHandSplice]
      }
    }
    e.currentTarget.classList.remove(styles.item__over)
    setItems(newArr)
  }

  useEffect(() => {
    console.log(newItemValue)
  }, [newItemValue])

  return (
    <div className={styles.itemList}>
      <ul>
        {items?.map((item, index) => {
          return (
            <div
              key={index + ' li'}
              id={String(index)}
              className={styles.liDiv}
              draggable={true}
              onDragStart={(e) => dragStartHandler(e, item, index)}
              onDragLeave={(e) => dragLeaveHandler(e)}
              onDragEnd={(e) => dragEndHandler(e)}
              onDragOver={(e) => dragOverHandler(e)}
              onDrop={(e) => dropHandler(e, index)}
              title={item?.name}
            >
              <div className={styles.trashButton}>
                <img
                  onClick={() => {
                    handleDelete(index)
                  }}
                  src={Trash}
                  className={styles.trashButton__button}
                  alt="delete"
                />
              </div>
              <li>
                <span>{item?.name}</span>
              </li>
            </div>
          )
        })}
        {!newItem ? (
          <div className={styles.plusIcon}>
            <CIcon
              size="xl"
              icon={cilPlus}
              onClick={() => {
                setNewItem(true)
              }}
            />
          </div>
        ) : (
          <div className={styles.newItem}>
            <CFormSelect
              aria-label="Disabled select example"
              onChange={(e) => changeNewItemHandler(e)}
            >
              <option>Выберете номенклатуру</option>
              {nomenclature.map((item: any) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </CFormSelect>
            <CIcon
              size="xl"
              className={styles.check}
              icon={cilCheckAlt}
              onClick={() => {
                setNewItemHandler(newItemValue)
              }}
            />
          </div>
        )}
      </ul>
    </div>
  )
}

export default SliderEditList
