import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Trash from '../../assets/icons/trash.svg'
import styles from './GalleryEditImage.module.scss'
import { CFormCheck } from '@coreui/react'
import { DeleteFile, SaveFile } from '../../store/slices/FileSlice'
import { getCategoryItem } from '../../store/slices/CategoriesSlice'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'
import { RootState } from '../../store/store'
interface ITabComponent {
  item: any
  idx: number
}

const EditGalleryImage: React.FC<ITabComponent> = ({ item, idx }) => {
  const loading = useSelector((state: RootState) => state.SaveFileSlice.loadings)
  const loadingGall = useSelector((state: RootState) => state.AboutSlice.loadings)
  return (
    <>
      <SkeletonLoading
        style={{ minHeight: '140px', minWidth: '200px', borderRadius: '16px' }}
        loading={checkLoadings(loading)}
      >
        <SkeletonLoading
          style={{ minHeight: '140px', minWidth: '200px', borderRadius: '16px' }}
          loading={checkLoadings(loadingGall)}
        >
          <div className={styles.photoCard}>
            <div style={{ position: 'absolute', top: 10, right: 5 }}></div>
            <div className={styles.order} style={{ position: 'absolute', top: 10, left: 5 }}>
              {idx + 1}
            </div>
            <img width={200} height={140} src={item} />
          </div>
        </SkeletonLoading>
      </SkeletonLoading>
    </>
  )
}

export default EditGalleryImage
