/* eslint-disable react/jsx-key */
import { TSeoItem } from '../../api/mock/typesApi'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { CButton, CFormInput, CFormSelect, CFormTextarea } from '@coreui/react'
import { getSeoPageInfo, postSeoKeys, postSeoPages } from '../../store/slices/SeoSlice'
import SeoEditList from './SeoEditList'
import styles from './SeoComponent.module.scss'

interface ISeo {
  seo: any
  loading: any
  seoPage: TSeoItem | null
}

const SeoComponent: React.FC<ISeo> = ({ seo, loading, seoPage }) => {
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<TSeoItem>(
    seoPage
      ? seoPage
      : {
          id: '',
          title: '',
          description: '',
          keys: [],
          invisibleH1: '',
        },
  )
  const [seoId, setSeoId] = useState<any | undefined>()
  const seoPages = useSelector((state: RootState) => state.SeoSlice.getSeoPageInfo)

  useEffect(() => {
    setModel(seoPages)
  }, [seoPage])

  useEffect(() => {
    if (seoId == 'Выберете настраиваемую страницу' || seoId == undefined) {
    } else {
      setModel({
        id: '',
        title: '',
        description: '',
        keys: [],
        invisibleH1: '',
      })
      dispatch(
        getSeoPageInfo({
          id: seoId,
        }),
      )
    }
  }, [seoId])
  const SaveSeo = () => {
    dispatch(
      postSeoPages({
        id: model?.id,
        title: model?.title,
        description: model?.description,
        pageId: seoId,
        invisibleH1: model?.invisibleH1,
      }),
    ).then(() =>
      dispatch(
        postSeoKeys({
          ceoId: model?.id,
          values: model.keys,
        }),
      ),
    )
  }
  return (
    <div className={styles.main}>
      <div className={styles.selector}>
        <CFormSelect
          aria-label="Disabled select example"
          onChange={(e) => setSeoId(e.currentTarget.value)}
        >
          <option>Выберете настраиваемую страницу</option>
          {seo?.map((item: any) => (
            <option value={item.id}>{item.name}</option>
          ))}
        </CFormSelect>
      </div>
      <div className={styles.title}>
        <span className="text-medium-emphasis">Title (заголовок)</span>
        <CFormInput
          value={model?.title}
          onChange={(e) => setModel({ ...model, title: e.target.value })}
          placeholder="Заголовок"
        />
      </div>
      <div className={styles.title}>
        <span className="text-medium-emphasis">Скрытый h1</span>
        <CFormInput
          value={model?.invisibleH1}
          onChange={(e) => setModel({ ...model, invisibleH1: e.target.value })}
          placeholder="Скрытый h1"
        />
      </div>
      <div className={styles.description}>
        <span className="text-medium-emphasis">Description (описание)</span>
        <CFormTextarea
          value={model?.description}
          rows={6}
          onChange={(e) => setModel({ ...model, description: e.target.value })}
          placeholder="Описание"
        />
      </div>
      {/* {seoId !== 'Выберете настраиваемую страницу' || undefined ? ( */}
      <div className={styles.keywords}>
        <span className="text-medium-emphasis">keywords</span>
        <SeoEditList
          setItems={(val: any) => {
            console.log(val)
            setModel({ ...model, keys: val })
          }}
          items={model?.keys}
        />
      </div>
      {/* ) : (
        <></>
      )} */}
      {/* <div className={styles.keywords}>
        <span className="text-medium-emphasis">keywords</span>
        <SeoEditList
          setItems={(val: any) => {
            console.log(val)
            setModel({ ...model, keys: val })
          }}
          items={model?.keys}
        />
      </div> */}
      <div className={styles.button}>
        <CButton
          // color="secondary"
          onClick={() => SaveSeo()}
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          Сохранить
        </CButton>
      </div>
    </div>
  )
}

export default SeoComponent
