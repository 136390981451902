import { TMainClientItem } from '../../api/mock/typesApi'
import React from 'react'
import styles from './MainClients.module.scss'
import checkIcon from '../../assets/icons/checkIcon.svg'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import Trash from '../../assets/icons/trash.svg'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteMainClient, getMainClients } from '../../store/slices/AboutSlice'
import { RootState } from '../../store/store'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'

interface IClientBlock {
  block: TMainClientItem
  openModal: Function
  deleteHandler: Function
  setItems: Function
}

const ClientBlock: React.FC<IClientBlock> = ({ block, openModal, deleteHandler, setItems }) => {
  const loading = useSelector((state: RootState) => state.AboutSlice.loadings)
  const dispatch = useDispatch<any>()
  const handleOpenModal = () => {
    openModal(true)
    setItems(block)
  }
  const deleteMainClientHandler = () => {
    dispatch(
      DeleteMainClient({
        id: block.id,
      }),
    ).then(() => dispatch(getMainClients()))
  }
  return (
    <>
      <SkeletonLoading
        style={{ minHeight: '88px', minWidth: '713px', borderRadius: '16px' }}
        loading={checkLoadings(loading)}
      >
        <div className={styles.clientBlock}>
          <div
            className={styles.clientBlock__edit}
            onClick={(e) => {
              e.stopPropagation()
              handleOpenModal()
            }}
          >
            <CIcon icon={cilPencil} style={{ color: 'black' }} />
          </div>
          <div
            className={styles.clientBlock__trashIcon}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <img
              onClick={() => {
                deleteMainClientHandler()
              }}
              src={Trash}
              width={20}
              height={20}
              //   className={styles.trashButton__button}
              alt="delete"
            />
          </div>
          <div className={styles.clientBlock__image}>
            <img
              className={styles.categoryItem__title__button__btn__imgBlue}
              src={checkIcon}
              alt="arrow"
            />
          </div>
          <div className={styles.clientBlock__content}>
            <div className={styles.clientBlock__content__title}>
              {block?.title.length < 60 ? block?.title : block?.title.slice(0, 60) + '...'}
            </div>
            <div className={styles.clientBlock__content__description}>{block.description}</div>
          </div>
        </div>
      </SkeletonLoading>
    </>
  )
}

export default ClientBlock
