import React, { ReactElement, useState } from 'react'
import CardItem from '../Card/CardItem'
import SliderCards from '../SliderCards/SliderCards'
import styles from './Advantages.module.scss'
import { TWorkWithUsItem } from '../../api/mock/typesApi'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'
import WorkWithUsModal from './workWithUsModal'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'

interface IWorkWithUs {
  workWithUs: TWorkWithUsItem[] | []
  loading: any
}

const WorkWithUsComponent: React.FC<IWorkWithUs> = ({ workWithUs, loading }) => {
  const deleteHandler = () => {
    console.log('')
  }
  const openModal = () => {
    setShowEditModal(true)
  }
  const [items, setItems] = useState<any>()
  const advantagesElements: ReactElement[] = workWithUs?.map(
    (item: TWorkWithUsItem, index: number) => {
      return (
        <CardItem
          key={index + ' advantage'}
          item={item}
          openModal={openModal}
          deleteHandler={deleteHandler}
          setItems={setItems}
        />
      )
    },
  )
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  return (
    <>
      <div className={styles.advantages} id="adventages" style={{ width: '90%', margin: '0 auto' }}>
        <div className={styles.advantages__title} style={{ paddingTop: '10px' }}>
          Преимущества работы с нами
        </div>
        <div className={styles.advantages__slider}>
          <div className={styles.advantages__slider__plusIcon}>
            <CIcon
              className={styles.icon}
              size="xl"
              onClick={() => setShowEditModal(true)}
              icon={cilPlus}
            />
          </div>
          <SliderCards arrows={true} elements={advantagesElements} loading={loading} />
        </div>
      </div>
      <WorkWithUsModal
        payload={items}
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
      />
    </>
  )
}

export default WorkWithUsComponent
