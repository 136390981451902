import { TServiceItem } from '../../api/mock/typesApi'

import React, { useState } from 'react'
import styles from './Service.module.scss'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import { CForm, CFormInput, CFormTextarea } from '@coreui/react'
import noPhoto from '../../assets/icons/placeholder.webp'

interface TServiceItemProps {
  payload: any
  setShowEditModal: Function
  setItems: Function
}
const ServiceItem: React.FC<TServiceItemProps> = ({ payload, setShowEditModal, setItems }) => {
  const [edit, setEdit] = useState<boolean>(false)

  const editModalHandler = () => {
    setShowEditModal(true)
    setItems(payload)
  }
  return (
    <div className={styles.serviceItem}>
      <div
        className={styles.serviceItem__editPen}
        onClick={() => {
          editModalHandler()
        }}
      >
        <CIcon icon={cilPencil} style={{ color: 'black' }} />
      </div>
      <div className={styles.serviceItem__icon}>
        {payload?.image ? (
          <div className={styles.serviceItem__icon__svg}>
            <img src={payload?.image} alt="category" width={48} height={48} />
          </div>
        ) : (
          <div className={styles.serviceItem__icon__svg}>
            <img src={noPhoto} alt="category" width={48} height={48} />
          </div>
        )}
      </div>
      <div className={styles.serviceItem__content}>
        {edit ? (
          <>
            <CForm>
              <CFormInput
                value={payload?.title}
                size="lg"
                className={styles.serviceItem__content__titleEdit}
              ></CFormInput>
              <CFormTextarea
                value={payload?.description}
                style={{ width: '100%' }}
                className={styles.serviceItem__content__descriptionEdit}
              ></CFormTextarea>
            </CForm>
          </>
        ) : (
          <>
            <div className={styles.serviceItem__content__title}>{payload?.title}</div>
            <div className={styles.serviceItem__content__description}>{payload?.description}</div>
          </>
        )}
      </div>
    </div>
  )
}

export default ServiceItem
