import { CFormInput } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import styles from './InputImage.module.scss'

interface IInputImage {
  setFile: (val: File | null) => void
}

const InputImage: React.FC<IInputImage> = ({ setFile }) => {
  const [preview, setPreview] = useState<string>('')
  const changeFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /[image*]\g/
    if (
      e.target?.files?.length !== 0 &&
      e.target?.files?.length &&
      reg.test(e.target.files[0].type)
    ) {
      setFile(e.target.files[0])
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onloadend = () => {
        setPreview(reader.result as string)
      }
    } else {
      setFile(null)
      setPreview('')
    }
  }
  return (
    <>
      <CFormInput onChange={(e) => changeFileHandler(e)} type="file" id="formFile" />
      {preview ? (
        <div className={styles.preview}>
          <img src={preview} alt="preview" />
        </div>
      ) : null}
    </>
  )
}

export default InputImage
