import { cilPencil } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CCol, CContainer, CImage, CRow } from '@coreui/react'
import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import FooterLogo from '../../assets/brand/footerLogo.png'
import SkeletonLoading from '../../components/Skeleton'
import { useFooter } from '../../helpers/hooks/useFooter'
import { useHover } from '../../helpers/hoverHelper'
import { checkLoadings } from '../../helpers/loadings'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { addNewContatcts } from '../../store/slices/ContactsSlice'
import { getCookie } from '../../helpers/cookies'
import { openNotification } from '../../components/common/Notification/Notification'

const FooterView = () => {
  const { getContacts, requisites, contacts, loadings } = useFooter()
  const contactsNew = useSelector((state: RootState) => state.ContactsSlice.newContactInfo)
  const dispatch = useDispatch<any>()
  useEffect(() => {
    getContacts()
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  const contactsHover = useHover({ textDecoration: 'underline' }, { textDecoration: 'none' })
  const requisitesHover = useHover({ textDecoration: 'underline' }, { textDecoration: 'none' })
  useEffect(() => {
    console.log(contactsNew)
  }, [contactsNew])

  return (
    <>
      <SkeletonLoading style={{ minHeight: '430px' }} loading={checkLoadings(loadings)}>
        <CContainer
          fluid
          style={{
            overflowX: 'auto',
            minWidth: 500,
            padding: '10px 0 10px 0',
            borderRadius: '10px',
          }}
          className="bg-white border table-responsive"
        >
          <div style={{ width: '80%', margin: '0 auto' }}>
            <CRow className="align-items-center pt-2">
              <CCol className="m-2 p-0">
                <h4>
                  Контакты{' '}
                  <Link className="text-dark" to="/contacts">
                    <CIcon
                      icon={cilPencil}
                      onMouseEnter={() => {
                        contactsHover.onMouseEnter()
                      }}
                      onMouseLeave={() => {
                        contactsHover.onMouseLeave()
                      }}
                    />
                  </Link>
                </h4>
              </CCol>
              <CCol className="m-2 p-0">
                <h4>Навигация</h4>
              </CCol>
              <CCol className="m-2 p-0">
                <h4>Каталог</h4>
              </CCol>
            </CRow>
            <CRow className="align-items-start">
              <CCol className="m-2 p-0">
                <CContainer className="p-0 m-0" ref={contactsHover.currentRef}>
                  <CContainer className="p-1">{contactsNew?.items[0]?.phone || ''}</CContainer>
                  <CContainer className="p-1">{contactsNew?.items[0]?.email || ''}</CContainer>
                  <CContainer className="p-1">{contactsNew?.address || ''}</CContainer>
                  <CContainer className="p-1">{contactsNew?.production || ''}</CContainer>
                </CContainer>
              </CCol>
              <CCol className="m-2 p-0">
                <CContainer className="p-0 m-0">
                  <CContainer className="p-1">Главная</CContainer>
                  <CContainer className="p-1">Каталог</CContainer>
                  <CContainer className="p-1">О компании</CContainer>
                  <CContainer className="p-1">Отзывы</CContainer>
                  <CContainer className="p-1">Преимущества</CContainer>
                  <CContainer className="p-1">Контакты</CContainer>
                </CContainer>
              </CCol>
              <CCol className="m-2 p-0">
                <CContainer className="p-0 m-0">
                  <CContainer className="p-1">Ремонт и покупка трактора</CContainer>
                  <CContainer className="p-1">Ремонт и покупка узлов, агрегатов</CContainer>
                  <CContainer className="p-1">Рукава высокого давления (РВД)</CContainer>
                  <CContainer className="p-1">Метизы, инструмент, подшипники и др.</CContainer>
                  <CContainer className="p-1">
                    Запчасти к технике Кировец, МТЗ, ЯМЗ, Т-150
                  </CContainer>
                </CContainer>
              </CCol>
            </CRow>
            <CRow className="d-flex align-items-center justify-content-center p-2">
              <CContainer className="bg-white border m-2 p-2">
                <CRow>
                  <CCol className="align-items-center">
                    <CImage src={FooterLogo} />
                  </CCol>
                  <CCol className="d-flex align-items-center">Политика конфеденциальности</CCol>
                  <CCol className="d-flex align-items-center">Дизайн сайта</CCol>
                </CRow>
              </CContainer>
            </CRow>
            <CRow className="align-items-center pb-2">
              <CContainer className="d-flex justify-content-between p-0">
                <CContainer className="d-flex p-0 m-0 align-items-center justify-content-start">
                  <CContainer
                    ref={requisitesHover.currentRef}
                    className="d-flex justify-content-start flex-column p-0"
                  >
                    <CContainer>
                      <CContainer className="p-0">
                        {contacts?.name || ''} {contacts?.inn || ''}
                      </CContainer>
                    </CContainer>
                    <CContainer>{contacts?.ogrni || ''}</CContainer>
                  </CContainer>
                  <CContainer>
                    <Link className="text-dark" to="/contacts">
                      <CIcon
                        icon={cilPencil}
                        onMouseEnter={() => {
                          requisitesHover.onMouseEnter()
                        }}
                        onMouseLeave={() => {
                          requisitesHover.onMouseLeave()
                        }}
                      />
                    </Link>
                  </CContainer>
                </CContainer>

                <CContainer className="d-flex justify-content-end align-items-center">
                  @ 2023 | Все права защищены
                </CContainer>
              </CContainer>
            </CRow>
            <CRow className="align-items-center"></CRow>
          </div>
        </CContainer>
      </SkeletonLoading>
    </>
  )
}

export default FooterView
