import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MockApi } from '../../api/mock/mockApi'
import { TCount, TDeleteReview, TReviewItem } from '../../api/mock/typesApi'
import { TPagination } from '../types'
import { instanceAxiosClose } from '../api/instances'
import { paths } from '../api/paths'
import { getInlineParams } from '../../helpers/helper'
import { openNotification } from '../../components/common/Notification/Notification'

const mockApi = new MockApi()

export interface IReviewsSlice {
  loadings: {
    getReviewsLoading: boolean
    getReviewLoading: boolean
  }
  reviewsInfo: TReviewItem[]
  reviewsPageList: TReviewItem[]
  total: number | undefined
  //   {
  // id?: string
  // rating?: string
  // reviewText?: string
  // author?: string
  // fromText?: string
  //   }
  reviewInfo: {
    id?: string
    rating?: string
    reviewText?: string
    author?: string
    fromText?: string
  }
}
const initialState: IReviewsSlice = {
  loadings: {
    getReviewsLoading: false,
    getReviewLoading: false,
  },
  total: undefined,
  reviewsInfo: [],
  reviewsPageList: [],
  reviewInfo: {
    id: '',
    rating: '',
    reviewText: '',
    author: '',
    fromText: '',
  },
}

export const getReviewsInfo = createAsyncThunk('reviews/getReviews', async (body: TCount) => {
  const { data } = await instanceAxiosClose().get(
    paths.reviewsService.review + getInlineParams(body),
  )
  return data?.data
})

export const EditReview = createAsyncThunk('reviews/Editreviews', async (body: TReviewItem) => {
  const data = await instanceAxiosClose().post(paths.reviewsService.review, body)
  return data?.data
})

export const CreateReview = createAsyncThunk('reviews/Createreviews', async (body: TReviewItem) => {
  const data = await instanceAxiosClose().put(paths.reviewsService.review, body)
  return data?.data
})
export const DeleteReview = createAsyncThunk(
  'reviews/DeleteReview',
  async (body: TDeleteReview) => {
    const data = await instanceAxiosClose().delete(
      paths.reviewsService.review + getInlineParams(body),
    )
    return data?.data
  },
)
export const getReviewInfo = createAsyncThunk<any>('review/getReview', async () => {
  const data = await mockApi.getReview({ mock: true })
  return data?.data
})

export const getReviewsPageList = createAsyncThunk<any, TPagination>(
  'about/getReviewsPageList',
  async (body: TPagination) => {
    const data = await mockApi.getReviewsPageList(body, { mock: true })
    return data?.data
  },
)

export const ReviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviewsInfo.pending, (state, action) => {
      state.loadings.getReviewsLoading = true
    })
    builder.addCase(getReviewsInfo.fulfilled, (state, action) => {
      state.loadings.getReviewsLoading = false
      state.reviewsInfo = action?.payload?.value?.items
      state.total = action?.payload?.value?.totalCount
    })
    builder.addCase(getReviewsInfo.rejected, (state, action) => {
      state.loadings.getReviewsLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(EditReview.pending, (state, action) => {
      state.loadings.getReviewsLoading = true
    })
    builder.addCase(EditReview.fulfilled, (state, action) => {
      state.loadings.getReviewsLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(EditReview.rejected, (state, action) => {
      state.loadings.getReviewsLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(CreateReview.pending, (state, action) => {
      state.loadings.getReviewsLoading = true
    })
    builder.addCase(CreateReview.fulfilled, (state, action) => {
      state.loadings.getReviewsLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(CreateReview.rejected, (state, action) => {
      state.loadings.getReviewsLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(DeleteReview.pending, (state, action) => {
      state.loadings.getReviewsLoading = true
    })
    builder.addCase(DeleteReview.fulfilled, (state, action) => {
      state.loadings.getReviewsLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(DeleteReview.rejected, (state, action) => {
      state.loadings.getReviewsLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getReviewInfo.pending, (state, action) => {
      state.loadings.getReviewLoading = true
    })
    builder.addCase(getReviewInfo.fulfilled, (state, action) => {
      state.loadings.getReviewLoading = false
      state.reviewInfo = action?.payload?.data?.value
    })
    builder.addCase(getReviewInfo.rejected, (state, action) => {
      state.loadings.getReviewLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(getReviewsPageList.fulfilled, (state, action) => {
      state.reviewsPageList = action.payload?.items
      state.loadings.getReviewLoading = false
    })
  },
})
export default ReviewsSlice.reducer
