import React, { useEffect } from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import { useAuth } from '../helpers/hooks/useAuth'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'

const DefaultLayout = () => {
  const { getUser } = useAuth()

  useEffect(() => {
    getUser()
  }, [])

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent />
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default DefaultLayout
