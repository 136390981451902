import React, { CSSProperties } from 'react'

interface IProgressBar {
  completed: number
  className?: string
  onClick?: () => void
  containerStyle?: CSSProperties
  fillerStyle?: CSSProperties
}

const ProgressBar: React.FC<IProgressBar> = ({
  completed,
  className,
  onClick,
  containerStyle,
  fillerStyle,
}) => {
  const containerStyles: CSSProperties = {
    height: 10,
    width: '100%',
    backgroundColor: '#5a778f',
    borderRadius: 50,
  }

  const fillerStyles: CSSProperties = {
    height: '100%',
    width: `${completed * 100}%`,
    backgroundColor: '#ffffff',
    borderRadius: 'inherit',
    textAlign: 'right',
    zIndex: 100,
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  }

  return (
    <div
      style={{ ...containerStyles, ...containerStyle }}
      className={className || ''}
      onClick={onClick}
    >
      <div style={{ ...fillerStyles, ...fillerStyle }}>
        <span style={labelStyles}></span>
      </div>
    </div>
  )
}

export default ProgressBar
