import { MockApi } from '../../api/mock/mockApi'
import {
  TAboutBlock,
  TAboutMain,
  TAboutMainEdit,
  TAboutPoster,
  TAchievementItem,
  TAdventageItem,
  TCreateWorkWithUs,
  TDeleteAchievement,
  TDeleteGalleryImage,
  TDeleteWorkWithUs,
  TEditAboutPoster,
  TEditInformationArticle,
  TEditService,
  TEditWorkWithUs,
  TGetMainClients,
  TMainClientItem,
  TReviewItem,
  TSliderItem,
  TWorkWithUsItem,
} from '../../api/mock/typesApi'
import { TPagination } from '../types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TService } from '../../api/mock/typesApi'
import { paths } from '../api/paths'
import { instanceAxiosClose } from '../api/instances'
import { getInlineParams } from '../../helpers/helper'
import { openNotification } from '../../components/common/Notification/Notification'

export interface IAboutSlice {
  loadings: {
    getServiceLoading: boolean
    getAdventagesLoading: boolean
    getReviewsLoading: boolean
    getAboutPosterLoading: boolean
    getAboutBlockLoading: boolean
    getMainClientsLoading: boolean
    getAchievementsLoading: boolean
    getGalleryImagesLoading: boolean
    getWorkWithUsLoading: boolean
    getReviewsPageListLoading: boolean
    getAboutLoading: boolean
  }
  adventagesList: TAdventageItem[] | []
  reviewsList: TReviewItem[] | []
  aboutPoster: TAboutPoster
  aboutBlock: TAboutBlock
  aboutMain: TAboutMain | undefined
  mainClients: TMainClientItem[] | []
  achievements: TAchievementItem[] | []
  galleryImages: string[]
  reviewsPageList: TReviewItem[] | []
  serviceInfo: TService['items']
  workWithUsInfo: TWorkWithUsItem[] | []
}

const initialState: IAboutSlice = {
  loadings: {
    getAboutLoading: false,
    getServiceLoading: false,
    getAdventagesLoading: false,
    getReviewsLoading: false,
    getAboutPosterLoading: false,
    getAboutBlockLoading: false,
    getMainClientsLoading: false,
    getAchievementsLoading: false,
    getGalleryImagesLoading: false,
    getWorkWithUsLoading: false,
    getReviewsPageListLoading: false,
  },
  adventagesList: [],
  reviewsList: [],
  aboutPoster: {
    backgroundImage: '',
    titleFront: '',
    titleMain: '',
    description: '',
    itemList: [],
    supportButton: false,
    catalogButton: false,
    name: '',
  },
  aboutBlock: {
    title: '',
    content: '',
    image: '',
  },
  aboutMain: undefined,
  mainClients: [],
  achievements: [],
  galleryImages: [],
  reviewsPageList: [],
  serviceInfo: [],
  workWithUsInfo: [],
}

const mockApi = new MockApi()

export const getAdventages = createAsyncThunk<any>('about/getAdventages', async () => {
  const data = await mockApi.getAdventages({ mock: true })
  return data?.data
})

export const getReviews = createAsyncThunk<any>('about/getReviews', async () => {
  const data = await mockApi.getReviews({ mock: true })
  return data?.data
})

export const getAboutPoster = createAsyncThunk<any>('about/getAboutPoster', async () => {
  const data = await instanceAxiosClose().get(paths.aboutService.about)
  return data?.data
})
export const EditAboutPoster = createAsyncThunk(
  'about/editAboutPoster',
  async (body: TEditAboutPoster) => {
    const data = await instanceAxiosClose().post(paths.sliderService.slide, body)
    return data?.data
  },
)
export const getAboutMain = createAsyncThunk<any>('about/getAboutMain', async () => {
  const data = await instanceAxiosClose().get(paths.aboutNew.aboutNew)
  return data?.data
})
export const editAboutMain = createAsyncThunk(
  'about/editAboutMain',
  async (body: TAboutMainEdit) => {
    const data = await instanceAxiosClose().post(paths.sliderService.slide, body)
    return data?.data
  },
)

export const getAboutBlock = createAsyncThunk<any>('about/getAboutBlock', async () => {
  const data = await instanceAxiosClose().get(
    paths.informationArticleService.informationArticle + '/1',
  )
  return data?.data
})
export const editAboutBlock = createAsyncThunk(
  'about/editAboutBlock',
  async (body: TEditInformationArticle) => {
    const data = await instanceAxiosClose().post(
      paths.informationArticleService.informationArticle,
      body,
    )
    return data?.data
  },
)

export const getMainClients = createAsyncThunk<any>('about/getMainClients', async () => {
  const data = await instanceAxiosClose().get(
    paths.informationArticleService.informationArticle + '/2',
  )
  return data?.data
})

export const editMainClients = createAsyncThunk(
  'about/editMainClients',
  async (body: TEditService) => {
    const data = await instanceAxiosClose().post(
      paths.informationArticleService.informationArticle,
      body,
    )
    return data?.data
  },
)
export const CreateMainClients = createAsyncThunk(
  'about/createMainClients',
  async (body: TCreateWorkWithUs) => {
    const data = await instanceAxiosClose().put(
      paths.informationArticleService.informationArticle,
      body,
    )
    return data?.data
  },
)
export const DeleteMainClient = createAsyncThunk(
  'about/deleteMainClient',
  async (body: TDeleteAchievement) => {
    const data = await instanceAxiosClose().delete(
      paths.informationArticleService.informationArticle + getInlineParams(body),
    )
    return data?.data
  },
)

export const getAchievements = createAsyncThunk<any>('about/getAchievements', async () => {
  const data = await instanceAxiosClose().get(
    paths.informationArticleService.informationArticle + '/3',
  )
  return data?.data
})
export const editAchievement = createAsyncThunk(
  'about/editAchievements',
  async (body: TEditService) => {
    const data = await instanceAxiosClose().post(
      paths.informationArticleService.informationArticle,
      body,
    )
    return data?.data
  },
)
export const CreateAchievement = createAsyncThunk(
  'about/createAchievements',
  async (body: TCreateWorkWithUs) => {
    const data = await instanceAxiosClose().put(
      paths.informationArticleService.informationArticle,
      body,
    )
    return data?.data
  },
)
export const DeleteAchievement = createAsyncThunk(
  'about/deleteAchievements',
  async (body: TDeleteAchievement) => {
    const data = await instanceAxiosClose().delete(
      paths.informationArticleService.informationArticle + getInlineParams(body),
    )
    return data?.data
  },
)

export const getService = createAsyncThunk<any>('about/getService', async () => {
  const data = await instanceAxiosClose().get(
    paths.informationArticleService.informationArticle + '/4',
  )
  return data?.data
})

export const editService = createAsyncThunk('service/editService', async (body: TEditService) => {
  const data = await instanceAxiosClose().post(
    paths.informationArticleService.informationArticle,
    body,
  )
  return data?.data
})

export const getGalleryImages = createAsyncThunk<any>('about/getGalleryImages', async () => {
  const data = await instanceAxiosClose().get(
    paths.informationArticleService.informationArticle + '/gallery',
  )
  return data?.data
})
export const deleteGalleryImages = createAsyncThunk(
  'about/deleteGalleryImages',
  async (body: TDeleteGalleryImage) => {
    const data = await instanceAxiosClose().post(paths.fileService.deleteFile, body)
    return data?.data
  },
)

export const getWorkWithUs = createAsyncThunk<any>('workWithUs', async () => {
  const data = await instanceAxiosClose().get(
    paths.informationArticleService.informationArticle + '/5',
  )
  return data?.data
})

export const editWorkWithUs = createAsyncThunk(
  'workWithUs/editworkWithUs',
  async (body: TEditWorkWithUs) => {
    const data = await instanceAxiosClose().post(
      paths.informationArticleService.informationArticle,
      body,
    )
    return data?.data
  },
)
export const DeleteWorkWithUs = createAsyncThunk(
  'workWithUs/deleteWorkWithUs',
  async (body: TDeleteWorkWithUs) => {
    const data = await instanceAxiosClose().delete(
      paths.informationArticleService.informationArticle + getInlineParams(body),
    )
    return data?.data
  },
)
export const CreateWorkWithUs = createAsyncThunk(
  'workWithUs/CreateWorkWithUs',
  async (body: TCreateWorkWithUs) => {
    const data = await instanceAxiosClose().put(
      paths.informationArticleService.informationArticle,
      body,
    )
    return data?.data
  },
)

export const getReviewsPageList = createAsyncThunk<any, TPagination>(
  'about/getReviewsPageList',
  async (body: TPagination) => {
    const data = await mockApi.getReviewsPageList(body, { mock: true })
    return data?.data
  },
)

export const AboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    setSlider: (state, action) => {
      return { ...state, contactsInfo: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdventages.fulfilled, (state, action) => {
      state.adventagesList = action.payload?.data?.value?.items
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getAdventages.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getAdventages.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getReviews.fulfilled, (state, action) => {
      state.reviewsList = action.payload?.data?.value?.items
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getReviews.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getReviews.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getAboutPoster.fulfilled, (state, action) => {
      state.aboutPoster = action?.payload?.data?.value?.items[0]
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getAboutPoster.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getAboutPoster.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(getAboutMain.fulfilled, (state, action) => {
      state.aboutMain = action?.payload?.data?.value?.items[0]
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getAboutMain.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getAboutMain.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getAboutBlock.fulfilled, (state, action) => {
      state.aboutBlock = action?.payload?.data?.value?.items[0]
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getAboutBlock.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getAboutBlock.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getMainClients.fulfilled, (state, action) => {
      state.mainClients = action.payload?.data?.value?.items
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getMainClients.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getMainClients.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getAchievements.fulfilled, (state, action) => {
      state.achievements = action.payload?.data?.value?.items
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getAchievements.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getAchievements.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getGalleryImages.fulfilled, (state, action) => {
      state.galleryImages = action.payload?.data?.value?.items
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getGalleryImages.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getGalleryImages.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getReviewsPageList.fulfilled, (state, action) => {
      state.reviewsPageList = action.payload?.items
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getReviewsPageList.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getReviewsPageList.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getService.fulfilled, (state, action) => {
      state.serviceInfo = action?.payload?.data?.value?.items
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getService.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getService.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(getWorkWithUs.fulfilled, (state, action) => {
      state.workWithUsInfo = action.payload?.data?.value?.items
      state.loadings.getAboutLoading = false
    })
    builder.addCase(getWorkWithUs.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(getWorkWithUs.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(EditAboutPoster.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(EditAboutPoster.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(EditAboutPoster.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(editAboutMain.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(editAboutMain.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(editAboutMain.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(editAboutBlock.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(editAboutBlock.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(editAboutBlock.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(editMainClients.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(editMainClients.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(editMainClients.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(CreateMainClients.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(CreateMainClients.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(CreateMainClients.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(DeleteMainClient.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(DeleteMainClient.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(DeleteMainClient.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(editAchievement.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(editAchievement.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(editAchievement.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(CreateAchievement.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(CreateAchievement.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(CreateAchievement.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(DeleteAchievement.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(DeleteAchievement.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(DeleteAchievement.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(editService.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(editService.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(editService.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(deleteGalleryImages.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(deleteGalleryImages.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(deleteGalleryImages.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(editWorkWithUs.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(editWorkWithUs.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(editWorkWithUs.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(DeleteWorkWithUs.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(DeleteWorkWithUs.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(DeleteWorkWithUs.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(CreateWorkWithUs.fulfilled, (state, action) => {
      state.loadings.getAboutLoading = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(CreateWorkWithUs.pending, (state, action) => {
      state.loadings.getAboutLoading = true
    })
    builder.addCase(CreateWorkWithUs.rejected, (state, action) => {
      state.loadings.getAboutLoading = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {} = AboutSlice.actions

export default AboutSlice.reducer
