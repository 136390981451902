// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputImage_preview__9h-Ki {
  width: 100%;
}
.InputImage_preview__9h-Ki img {
  width: 100%;
  margin-top: 3px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/InputImage/InputImage.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAAI;EACI,WAAA;EACA,eAAA;AAER","sourcesContent":[".preview{\n    width: 100%;\n    img{\n        width: 100%;\n        margin-top: 3px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": `InputImage_preview__9h-Ki`
};
export default ___CSS_LOADER_EXPORT___;
