import React from 'react'
import styles from './Raiting.module.scss'
import RightStar from '../../assets/icons/RightStar.svg'
import LeftStar from '../../assets/icons/LeftStar.svg'
import RightStarTransparent from './rightSide'
import LeftStarTransparent from './leftSide'

interface IRating {
  rate: number
  flag: 'editable' | 'none'
}

const Rating: React.FC<IRating> = ({ rate }) => {
  const renderStars = () => {
    const renderArr = []
    for (let index = 0; index < 10; index += 1) {
      if (index < rate * 2) {
        if (index % 2 != 0) {
          renderArr.push(
            <div className={styles.starRate + ' ' + styles.leftStar} key={index + ' rate'}>
              <img alt={'star'} src={LeftStar} />
            </div>,
          )
        } else {
          renderArr.push(
            <div className={styles.starRate + ' ' + styles.rightStar} key={index + ' rate'}>
              <img alt={'star'} src={RightStar} />
            </div>,
          )
        }
      } else {
        if (index % 2 != 0) {
          renderArr.push(
            <div className={styles.starRate + ' ' + styles.leftStar} key={index + ' rate'}>
              {/* <img
                alt={'star'}
                src={ */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="21"
                fill="none"
                version="1.1"
                viewBox="0 0 11 21"
              >
                <path
                  fill="#fdcd06"
                  fillOpacity="0.235"
                  d="M0 17.529V0l3.467 6.819L11 8.02l-5.39 5.417L6.798 21 0 17.529z"
                ></path>
              </svg>
              {/* } */}
              {/* /> */}
            </div>,
          )
        } else {
          renderArr.push(
            <div className={styles.starRate + ' ' + styles.rightStar} key={index + ' rate'}>
              {/* <img alt={'star'} src={RightStarTransparent} /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="21"
                fill="none"
                version="1.1"
                viewBox="0 0 11 21"
              >
                <path
                  fill="#fdcd06"
                  fillOpacity="0.235"
                  d="M11 17.529V0L7.533 6.819 0 8.02l5.39 5.417L4.202 21 11 17.529z"
                ></path>
              </svg>
            </div>,
          )
        }
      }
    }
    return (
      <>
        {renderArr.map((item) => {
          return item
        })}
      </>
    )
  }

  return <div className={styles.rating}>{renderStars()}</div>
}

export default Rating
