import { CButton, CCol, CContainer, CRow, CTooltip } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import CategoriesItemComponent from './CategoriesItem'
import { TCategories, TCategoriesItems } from '../../api/mock/typesApi'
import Button from '../common/Button/Button'
import styles from './Categories.module.scss'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { Link } from 'react-router-dom'
import { EditCategoryOrder, getCategories } from '../../store/slices/CategoriesSlice'
interface CategoriesProps {
  payload: TCategoriesItems[] | []
}
// <SkeletonLoading style={{ minHeight: '430px' }} loading={checkLoadings(loadings)}> </SkeletonLoading>
const CategoriesComponent: React.FC<CategoriesProps> = ({ payload }) => {
  const loadings = useSelector((state: RootState) => state.CategoriesSlice.loadings)
  const dispatch = useDispatch<any>()
  const [currentPage, setCurrentPage] = useState(1)
  const [newItem, setNewItem] = useState<boolean>(false)
  const [newItemValue, setNewItemValue] = useState<string>('')
  const [currentItem, setCurrentItem] = useState<any>()
  const [orderList, setOrderList] = useState<any[] | []>([])
  const [currentOrder, setCurrentOrder] = useState<any>([])
  console.log(payload)
  function dragStartHandler(e: any, item: any, index: number) {
    setCurrentOrder({ item: item, index: index })
    console.log('dragsS', item)
  }

  function dragLeaveHandler(e: any) {
    e.currentTarget.classList.remove(styles.item__over)
  }

  function dragEndHandler(e: any) {
    // e.target.style.background = 'white'
  }

  function dragOverHandler(e: any) {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.classList.add(styles.item__over)
  }
  useEffect(() => {
    setOrderList(
      payload?.length
        ? payload?.map((images: any, idx) => {
            return {
              ...images,
              idx: idx,
            }
          })
        : [],
    )
  }, [payload])

  function dropHandler(e: any, item: any, index: number) {
    const buffer = (item: any) => {
      return { ...item, index: index }
    }
    console.log(item)
    console.log(currentItem?.item?.id)

    e.currentTarget.classList.remove()
    e.preventDefault()
    let arr: any[] = []
    if (item?.id !== currentItem?.item?.id) {
      console.log(currentItem)

      arr = orderList.map((i) => {
        //@ts-ignore
        if (buffer(item).index < currentOrder.index) {
          if (i.idx < buffer(item).index) {
            return { ...i }
          }

          //@ts-ignore
          if (i.idx === currentOrder.index) {
            return { ...i, idx: buffer(item).index }
          }

          //@ts-ignore
          if (i.idx >= buffer(item).index && i.idx < currentOrder.index) {
            return { ...i, idx: i.idx + 1 }
          }

          return { ...i }
        } else {
          //@ts-ignore
          if (buffer(item).index > currentOrder.index) {
            if (i.idx > buffer(item).index) {
              return { ...i }
            }

            //@ts-ignore
            if (i.idx === currentOrder.index) {
              return { ...i, idx: buffer(item).index }
            }

            //@ts-ignore
            if (i.idx <= buffer(item).index && i.idx > currentOrder.index) {
              return { ...i, idx: i.idx - 1 }
            }

            return { ...i }
          }
        }
      })
    } else {
      arr = orderList
    }
    const SortOrder = (a: any, b: any) => {
      if (a.idx > b.idx) {
        return 1
      } else {
        return -1
      }
    }
    dispatch(
      EditCategoryOrder({
        items: arr.sort(SortOrder).map((item) => item?.id),
      }),
    ).then(() => dispatch(getCategories()), setOrderList(arr))
  }

  return (
    <div className={styles.fullMain}>
      <CContainer style={{ marginBottom: '10px', marginLeft: '0' }}>
        <Link
          to={{
            pathname: `/categories/create`,
          }}
        >
          <CButton color="primary" href="#" style={{ marginTop: '10px', marginLeft: '0' }}>
            Создать категорию
          </CButton>
        </Link>
      </CContainer>
      <div className={styles.main}>
        <CContainer
          fluid
          className="border table-responsive"
          style={{
            width: '100%',
            display: 'flex',
            // flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#EBEDEF',
          }}
        >
          {/* <SkeletonLoading
            style={{ minHeight: '430px', minWidth: '430px' }}
            loading={checkLoadings(loadings)}
          > */}
          <div className={styles.cardsItems}>
            {payload?.length
              ? payload?.map((category: any) => {
                  return (
                    <CategoriesItemComponent
                      items={category}
                      key={'category ' + category.order}
                      loadings={loadings}
                    />
                  )
                })
              : null}
          </div>
          {/* </SkeletonLoading> */}
        </CContainer>
        <CContainer fluid className="bg-white border table-responsive" style={{ width: '20%' }}>
          <label className={styles.orderText}>управление порядком</label>
          <div className={styles.list}>
            {payload?.map((item: any, index: any) => {
              return (
                <>
                  <div
                    key={index + ' li'}
                    id={String(index)}
                    className={styles.liDiv}
                    draggable={true}
                    onDragStart={(e: any) => dragStartHandler(e, item, index)}
                    onDragLeave={(e: any) => dragLeaveHandler(e)}
                    onDragEnd={(e: any) => dragEndHandler(e)}
                    onDragOver={(e: any) => dragOverHandler(e)}
                    onDrop={(e: any) => dropHandler(e, item, index)}
                    title={item?.title?.length >= 12 ? item.title : ''}
                  >
                    <li className={styles.orderList}>
                      <CTooltip content={item?.title}>
                        <span>
                          {item?.title.length < 15 ? item?.title : item?.title.slice(0, 15) + '...'}
                        </span>
                      </CTooltip>
                    </li>
                  </div>
                </>
              )
            })}
          </div>
        </CContainer>
      </div>
    </div>
  )
}
export default CategoriesComponent
