import { CContainer } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import styles from './TellAboutUsComponent.module.scss'
import TellAboutUsModal from './TellAboutUsModal'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
interface TellAboutUsProps {
  aboutBlock: any
}
const TellAboutUsComponent: React.FC<TellAboutUsProps> = ({ aboutBlock }) => {
  const loading = useSelector((state: RootState) => state.AboutSlice.loadings)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const editSliderHandler = () => {
    console.log('')
  }
  useEffect(() => {
    console.log(aboutBlock)
  }, [aboutBlock])
  return (
    <>
      <SkeletonLoading
        style={{ minHeight: '605px', borderRadius: '16px' }}
        loading={checkLoadings(loading)}
      >
        <CContainer fluid style={{ position: 'relative' }}>
          <div style={{ margin: '0 auto', width: '80%', paddingBottom: '40px' }}>
            <div
              className={styles.editIcon}
              onClick={() => {
                setShowEditModal(true)
              }}
            >
              <CIcon icon={cilPencil} style={{ color: 'black' }} />
            </div>
            <div className={styles.about}>
              <div className={styles.about__left}>
                <div className={styles.about__left__header}>{aboutBlock.title}</div>
                <div
                  dangerouslySetInnerHTML={{ __html: aboutBlock.description }}
                  className={styles.about__left__description}
                ></div>
              </div>
              {/* <div className={styles.about__right}>
              <div className={styles.about__right__image}>
                <img src={aboutBlock.image} alt="aboutBlock" />
              </div>
            </div> */}
            </div>
            <div className={styles.aboutTiles}>
              <div className={styles.aboutTiles__tile}>
                <div className={styles.aboutTiles__tile__title}>20+</div>
                <div className={styles.aboutTiles__tile__description}>Лет на рынке</div>
              </div>
              <div className={styles.aboutTiles__tile}>
                <div className={styles.aboutTiles__tile__title}>1500+</div>
                <div className={styles.aboutTiles__tile__description}>Клиентская база юр. лиц</div>
              </div>
              <div className={styles.aboutTiles__tile}>
                <div className={styles.aboutTiles__tile__title}>12 мес</div>
                <div className={styles.aboutTiles__tile__description}>Гарантии на крупные узлы</div>
              </div>
              <div className={styles.aboutTiles__tile}>
                <div className={styles.aboutTiles__tile__title}>60+</div>
                <div className={styles.aboutTiles__tile__description}>Сотрудников</div>
              </div>
            </div>
          </div>
        </CContainer>
      </SkeletonLoading>
      <TellAboutUsModal
        payload={aboutBlock}
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        saveHandler={editSliderHandler}
      />
    </>
  )
}

export default TellAboutUsComponent
