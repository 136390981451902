import React, { useEffect, useState } from 'react'
import Modal from '../common/Modal/Modal'
import { CContainer, CForm, CFormInput, CFormTextarea } from '@coreui/react'
import styles from './ReviewEditModal.module.scss'
import Rating from '../Rating/Raiting'
import RatingNew from '../Rating/NewRating'
import { useDispatch } from 'react-redux'
import { CreateReview, EditReview, getReviewsInfo } from '../../store/slices/RatingSlice'
import { getReviews } from '../../store/slices/AboutSlice'
import { openNotification } from '../common/Notification/Notification'

interface ISliderEditModal {
  open: boolean
  handleClose: (val?: any) => void
  payload?: any
  saveHandler: (payload: any) => void
  pagination: any
}

const ReviewEditModal: React.FC<ISliderEditModal> = ({
  open,
  handleClose,
  payload,
  saveHandler,
  pagination,
}) => {
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<any>(
    payload
      ? payload
      : {
          id: '',
          rating: '',
          reviewText: '',
          author: '',
          fromText: '',
        },
  )
  const [rating, setRating] = React.useState(0)
  const [author, setAuthor] = useState('')
  const [fromText, setFromText] = useState('')
  const [reviewText, setReviewText] = useState('')
  const saveReview = () => {
    if (
      rating == null ||
      undefined ||
      reviewText == null ||
      undefined ||
      fromText == null ||
      undefined ||
      author == null ||
      undefined
    ) {
      openNotification({ type: 'warning', text: 'Заполните все поля и проставьте рейтинг!' })
    } else {
      payload
        ? dispatch(
            EditReview({
              id: payload?.reviewPayload?.id,
              rating: rating,
              author: author,
              fromText: fromText,
              reviewText: reviewText,
            }),
          )
            .then(() => dispatch(getReviewsInfo(pagination)))
            .then(
              () => handleClose(),
              setReviewText(''),
              setFromText(''),
              setAuthor(''),
              setRating(0),
            )
        : dispatch(
            CreateReview({
              rating: rating,
              author: author,
              fromText: fromText,
              reviewText: reviewText,
            }),
          )
            .then(() => dispatch(getReviewsInfo(pagination)))
            .then(
              () => handleClose(),
              setReviewText(''),
              setFromText(''),
              setAuthor(''),
              setRating(0),
            )
    }
  }
  useEffect(() => {
    console.log(payload?.reviewPayload)
    setRating(payload?.reviewPayload?.rating)
    setAuthor(payload?.reviewPayload?.author)
    setFromText(payload?.reviewPayload?.fromText)
    setReviewText(payload?.reviewPayload?.reviewText)
  }, [open])
  return (
    <Modal
      header={payload ? 'Редактирование отзыва' : 'Создание отзыва'}
      open={open}
      handleClose={handleClose}
      onOk={() => saveReview()}
      okText={'Сохранить'}
    >
      <CContainer fluid>
        {/* <Rating rate={payload?.reviewPayload?.rating} flag={'editable'} /> */}
        <RatingNew
          count={5}
          value={rating}
          isHalf={true}
          edit={true}
          onChange={(value) => setRating(value)}
        />
        <CForm className={styles.form}>
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <span className="text-medium-emphasis">Отзыв</span>
            <CFormTextarea
              value={reviewText}
              rows={6}
              onChange={(e) => setReviewText(e.target.value)}
              placeholder="Отзыв"
            />
          </div>
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <span className="text-medium-emphasis">Автор</span>
            <CFormInput
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              placeholder="Автор"
            />
          </div>
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <span className="text-medium-emphasis">Местоположение</span>
            <CFormInput
              value={fromText}
              onChange={(e) => setFromText(e.target.value)}
              placeholder="Местоположение"
            />
          </div>
        </CForm>
      </CContainer>
    </Modal>
  )
}

export default ReviewEditModal
