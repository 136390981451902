import { CContainer, CForm, CFormCheck, CFormInput, CFormTextarea } from '@coreui/react'
import React, { useEffect, useRef, useState } from 'react'
import { TSliderItem } from '../../api/mock/typesApi'
import Modal from '../common/Modal/Modal'
import styles from './Advantages.module.scss'
import InputImage from '../common/InputImage/InputImage'
import JoditEditor from 'jodit-react'
import { useDispatch } from 'react-redux'
import {
  CreateWorkWithUs,
  editAboutBlock,
  editWorkWithUs,
  getAboutBlock,
  getWorkWithUs,
} from '../../store/slices/AboutSlice'
import { DeleteFile, SaveFile } from '../../store/slices/FileSlice'

interface IWorkWithUsModal {
  open: boolean
  handleClose: (val?: any) => void
  payload: any | null
  //   saveHandler: Function
}

const WorkWithUsModal: React.FC<IWorkWithUsModal> = ({
  open,
  handleClose,
  payload,
  //   saveHandler,
}) => {
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<any>(
    payload
      ? payload
      : {
          title: '',
          content: '',
          image: '',
        },
  )
  useEffect(() => {
    setModel(payload)
    console.log(payload)
    console.log(model)
  }, [payload])
  const [file, setFile] = useState<any>(null)
  const config = {
    readonly: false,
    language: 'ru',
    style: {
      font: '12px Arial',
      color: '#0c0c0c',
      height: '190px',
    },
    toolbarSticky: false,
    ssr: false,
  }
  const editor = useRef(null)
  useEffect(() => {
    console.log(payload)
  }, [payload])

  const saveWorkWithUs = (model: any) => {
    if (payload === undefined) {
      if (file === null) {
        dispatch(
          CreateWorkWithUs({
            typeId: 5,
            title: model.title,
            description: model.description,
          }),
        )
          .then(() => handleClose(), setModel(''))
          .then(() => dispatch(getWorkWithUs()))
      } else {
        dispatch(
          CreateWorkWithUs({
            typeId: 5,
            title: model.title,
            description: model.description,
          }),
        )
          .then((e: any) => {
            if (file) {
              const formData = new FormData()
              formData.append('Files', file)
              formData.append(
                'Json',
                JSON.stringify({
                  Flag: 'I',
                  Id: e?.payload?.data?.value?.value,
                  Type: 'InformationArticle',
                  Files: [
                    {
                      Name: file?.name,
                      Extention: file?.name
                        .split('.')
                        [file.name.split('.').length - 1].toUpperCase(),
                      dataType: '2',
                      Rewrite: true,
                    },
                  ],
                }),
              )
              dispatch(
                SaveFile({
                  formData: {
                    Files: file,
                    Json: JSON.stringify({
                      Flag: 'I',
                      Id: e?.payload?.data?.value?.value,
                      Type: 'InformationArticle',
                      Files: [
                        {
                          Name: file?.name,
                          Extention: file?.name
                            .split('.')
                            [file.name.split('.').length - 1].toUpperCase(),
                          dataType: '2',
                          Rewrite: true,
                        },
                      ],
                    }),
                  },
                }),
              )
                .then(() => dispatch(getWorkWithUs()))
                .then(() => handleClose(), setModel(''))
            }
          })
          .then(() => handleClose(), setModel(''))
          .then(() => dispatch(getWorkWithUs()))
      }
    } else {
      if (file === null) {
        console.log(payload)
        console.log(model)

        dispatch(
          editWorkWithUs({
            id: payload?.id,
            title: model.title,
            description: model.description,
          }),
        )
          .then(() => handleClose(), setModel(''))
          .then(() => dispatch(getWorkWithUs()))
      } else {
        console.log(payload)
        console.log(model)

        dispatch(
          editWorkWithUs({
            title: model.title,
            description: model.description,
            id: payload?.id,
          }),
        )
          .then(
            () => console.log(payload),
            console.log(model),
            dispatch(
              DeleteFile({
                Id: payload?.id,
                Type: 'InformationArticle',
                Flag: 'D',
                Name: payload?.image?.split('/')[payload?.image?.split('/').length - 1],
              }),
            ),
          )
          .then(() => {
            if (file) {
              const formData = new FormData()
              formData.append('Files', file)
              formData.append(
                'Json',
                JSON.stringify({
                  Flag: 'I',
                  Id: payload?.id,
                  Type: 'InformationArticle',
                  Files: [
                    {
                      Name: file?.name,
                      Extention: file?.name
                        .split('.')
                        [file.name.split('.').length - 1].toUpperCase(),
                      dataType: '2',
                      Rewrite: true,
                    },
                  ],
                }),
              )
              dispatch(
                SaveFile({
                  formData: {
                    Files: file,
                    Json: JSON.stringify({
                      Flag: 'I',
                      Id: payload.id,
                      Type: 'InformationArticle',
                      Files: [
                        {
                          Name: file?.name,
                          Extention: file?.name
                            .split('.')
                            [file.name.split('.').length - 1].toUpperCase(),
                          dataType: '2',
                          Rewrite: true,
                        },
                      ],
                    }),
                  },
                }),
              )
                .then(() => dispatch(getWorkWithUs()))
                .then(() => handleClose(), setModel(''))
            }
          })
      }
    }
  }
  return (
    <Modal
      header={payload ? 'Редактирование "Работа с нами"' : 'Создание "Работа с нами"'}
      open={open}
      handleClose={handleClose}
      onOk={() => saveWorkWithUs(model)}
      okText={'Сохранить'}
    >
      <CContainer fluid>
        <CForm className={styles.form}>
          <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Заголовок</span>
            <CFormInput
              value={model?.title}
              onChange={(e) => setModel({ ...model, title: e.target.value })}
              placeholder="Заголовок"
            />
          </div>
          <div
            className={styles.form__formInput}
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            <span className={styles.jodit}>Описание</span>
            <CFormTextarea
              value={model?.description}
              rows={6}
              onChange={(e) => setModel({ ...model, description: e.target.value })}
              placeholder="Описание"
            />
            {/* <JoditEditor
              ref={editor}
              value={model?.description || ''}
              config={config}
              // tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setModel({ ...model, description: newContent })} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            /> */}
          </div>
          {/* <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Список тракторов</span>
          </div> */}
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <InputImage setFile={setFile} />
          </div>
        </CForm>
      </CContainer>
    </Modal>
  )
}

export default WorkWithUsModal
