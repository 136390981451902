export const checkLoadings = (loadings: any) => {
  let result = false
  for (let key in loadings) {
    if (loadings[key]) {
      result = true
      break
    }
  }
  return result
}
