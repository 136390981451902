import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MockApi } from '../../api/mock/mockApi'
import {
  TCategoriesItems,
  TCategoryItem,
  TCreateCategoryItem,
  TCreateTab,
  TCreateTabContent,
  TDeleteCategory,
  TDeleteTab,
  TDeleteTabContent,
  TEditCategoryItem,
  TEditOrderCategory,
  TEditOrderPhoto,
  TEditTab,
  TEditTabContent,
  TGetCategory,
} from '../../api/mock/typesApi'
import { TPagination } from '../types'
import { instanceAxiosClose } from '../api/instances'
import { paths } from '../api/paths'
import { redirect } from 'react-router-dom'
import { getInlineParams } from '../../helpers/helper'
import { openNotification } from '../../components/common/Notification/Notification'

export interface ICategoriesSlice {
  loadings: {
    getCategories: boolean
    getCategory: boolean
  }
  categoriesInfo: TCategoriesItems[] | []
  categoryItem: TCategoryItem | undefined
  categoryId: number | undefined
}

const initialState: ICategoriesSlice = {
  loadings: {
    getCategories: false,
    getCategory: false,
  },
  categoriesInfo: [],
  categoryItem: undefined,
  categoryId: undefined,
}

const mockApi = new MockApi()

export const getCategories = createAsyncThunk<any>('categories/getCategories', async () => {
  const { data } = await instanceAxiosClose().get(paths.categoriesService.category)
  return data?.data
  // const data = await mockApi.getCategories({ mock: true })
  // return data?.data
})
export const getCategoryItem = createAsyncThunk(
  'categories/getCategoryItem',
  async (body: TGetCategory) => {
    const data = await instanceAxiosClose().get(
      paths.categoriesService.category + `/${body.id}` + getInlineParams(body),
    )
    return data?.data
  },
)
export const EditCategoryItem = createAsyncThunk(
  'categories/EditCategory',
  async (body: TEditCategoryItem) => {
    const data = await instanceAxiosClose().post(paths.categoriesService.category, body)
    return data?.data
  },
)

export const CreateCategoryItem = createAsyncThunk(
  'categories/CreateCategory',
  async (body: TCreateCategoryItem) => {
    const data = await instanceAxiosClose().put(paths.categoriesService.category, body)
    return data?.data
  },
)
export const DeleteCategory = createAsyncThunk(
  'categories/DeleteCategory',
  async (body: TDeleteCategory) => {
    const data = await instanceAxiosClose().delete(
      paths.categoriesService.category + getInlineParams(body),
    )
    return data?.data
  },
)
export const EditOrderPhoto = createAsyncThunk(
  'categories/EditCategoryPhoto',
  async (body: TEditOrderPhoto) => {
    const data = await instanceAxiosClose().post(paths.fileService.orderFile, body)
    return data?.data
  },
)
export const EditCategoryOrder = createAsyncThunk(
  'categories/EditCategoryOrder',
  async (body: TEditOrderCategory) => {
    const data = await instanceAxiosClose().post(paths.categoriesService.category + '/order', body)
    return data?.data
  },
)
//Tabs

export const CreateTab = createAsyncThunk('categories/ctreateTab', async (body: TCreateTab) => {
  const data = await instanceAxiosClose().put(paths.categoriesService.tab, body)
  return data?.data
})
export const EditTab = createAsyncThunk('categories/EditTab', async (body: TEditTab) => {
  const data = await instanceAxiosClose().post(paths.categoriesService.tab, body)
  return data?.data
})
export const DeleteTab = createAsyncThunk('categories/DeleteTab', async (body: TDeleteTab) => {
  const data = await instanceAxiosClose().delete(
    paths.categoriesService.tab + getInlineParams(body),
  )
  return data?.data
})
//Tabs content

export const CreateTabContent = createAsyncThunk(
  'categories/ctreateTabContent',
  async (body: TCreateTabContent) => {
    const data = await instanceAxiosClose().put(paths.categoriesService.tabInfo, body)
    return data?.data
  },
)
export const EditTabContent = createAsyncThunk(
  'categories/EditTabContent',
  async (body: TEditTabContent) => {
    const data = await instanceAxiosClose().post(paths.categoriesService.tabInfo, body)
    return data?.data
  },
)
export const DeleteTabContent = createAsyncThunk(
  'categories/DeleteTabContent',
  async (body: TDeleteTabContent) => {
    const data = await instanceAxiosClose().delete(
      paths.categoriesService.tabInfo + getInlineParams(body),
    )
    return data?.data
  },
)

export const CategoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action) => {
      return { ...state, categoriesInfo: action.payload }
    },
    setCategoryId: (state, { payload }) => {
      state.categoryId = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categoriesInfo = action.payload?.value?.items
      state.loadings.getCategories = false
    })
    builder.addCase(getCategories.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(getCategories.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(getCategoryItem.fulfilled, (state, action) => {
      state.categoryItem = action?.payload?.data?.value

      state.loadings.getCategories = false
      localStorage.setItem('CATEGORY', action?.payload?.data?.value)
    })
    builder.addCase(getCategoryItem.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(getCategoryItem.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(EditCategoryItem.fulfilled, (state, action) => {
      state.loadings.getCategories = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(EditCategoryItem.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(EditCategoryItem.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(CreateCategoryItem.fulfilled, (state, action) => {
      state.loadings.getCategories = false
      state.categoryId = action?.payload?.data?.value?.value
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(CreateCategoryItem.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(CreateCategoryItem.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(CreateTab.fulfilled, (state, action) => {
      state.loadings.getCategories = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(CreateTab.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(CreateTab.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(EditTab.fulfilled, (state, action) => {
      state.loadings.getCategories = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(EditTab.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(EditTab.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(DeleteTab.fulfilled, (state, action) => {
      state.loadings.getCategories = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(DeleteTab.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(DeleteTab.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(DeleteCategory.fulfilled, (state, action) => {
      state.loadings.getCategories = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(DeleteCategory.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(DeleteCategory.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })

    builder.addCase(CreateTabContent.fulfilled, (state, action) => {
      state.loadings.getCategories = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(CreateTabContent.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(CreateTabContent.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(EditTabContent.fulfilled, (state, action) => {
      state.loadings.getCategories = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(EditTabContent.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(EditTabContent.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(DeleteTabContent.fulfilled, (state, action) => {
      state.loadings.getCategories = false
      openNotification({ type: 'success', text: 'Успешно!' })
    })
    builder.addCase(DeleteTabContent.pending, (state, action) => {
      state.loadings.getCategories = true
    })
    builder.addCase(DeleteTabContent.rejected, (state, action) => {
      state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(EditOrderPhoto.fulfilled, (state, action) => {
      // state.loadings.getCategories = false
      openNotification({ type: 'success', text: 'Порядок изменен!' })
    })
    builder.addCase(EditOrderPhoto.pending, (state, action) => {
      // state.loadings.getCategories = true
    })
    builder.addCase(EditOrderPhoto.rejected, (state, action) => {
      // state.loadings.getCategories = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
  },
})

export const { setCategories, setCategoryId } = CategoriesSlice.actions

export default CategoriesSlice.reducer
