/* eslint-disable react/jsx-key */
import { TReviewItem } from '../../api/mock/typesApi'
// import Pagination from "@/components/Pagination/Pagination";
// import { TPagination } from '@/lib/types'
import React, { useEffect, useState } from 'react'
import ReviewCard from '../ReviewCard/ReviewCard'
import styles from './ReviewsList.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getReviewsInfo, getReviewsPageList } from '../..//store/slices/RatingSlice'
import { RootState } from '../../store/store'
import { CContainer } from '@coreui/react'
import Pagination from '../Pagination/Pagination'
import { TPagination } from '../../store/types'
import { cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import ReviewEditModal from '../ReviewCard/ReviewEditModal'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'

interface IReviewsList {
  reviews: TReviewItem[]
  loading: any
  total: number | undefined
}

const ReviewsList: React.FC<IReviewsList> = ({ reviews, loading, total }) => {
  const dispatch = useDispatch<any>()
  const [pagination, setPagination] = useState({ offset: 0, count: 9 })
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const handleRequest = (val: TPagination) => {
    const promisePag = setPagination(val)
    Promise.all([promisePag]).then(() => dispatch(getReviewsInfo(val)))
  }
  const deleteHandler = () => {
    console.log('')
  }
  useEffect(() => {
    console.log(pagination)
  }, [pagination])
  useEffect(() => {
    console.log(total)
  }, [total])
  return (
    <>
      <div className={styles.plusIcon}>
        <CIcon
          className={styles.icon}
          size="xl"
          onClick={() => setShowEditModal(true)}
          icon={cilPlus}
        />
      </div>
      <CContainer fluid className={styles.main}>
        <div className={styles.reviewsList}>
          <div className={styles.reviewsList__items}>
            {reviews?.length
              ? reviews.map((review: any, index: any) => {
                  return (
                    <SkeletonLoading
                      style={{ minHeight: '430px', borderRadius: '16px' }}
                      loading={checkLoadings(loading)}
                    >
                      <ReviewCard
                        width={'100%'}
                        key={index + ' review'}
                        review={review}
                        deleteHandler={deleteHandler}
                        pagination={pagination}
                      />
                    </SkeletonLoading>
                  )
                })
              : null}
          </div>
          <div className={styles.reviewsList__pagination}>
            <Pagination pagination={pagination} onChange={handleRequest} total={total} />
          </div>
        </div>
      </CContainer>
      <ReviewEditModal
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        saveHandler={() => console.log()}
        pagination={pagination}
      />
    </>
  )
}

export default ReviewsList
