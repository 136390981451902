import { createSlice } from '@reduxjs/toolkit'

export interface ISidebarSlice {
  sidebarShow: boolean
}

const initialState: ISidebarSlice = {
  sidebarShow: true,
}

export const SidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebar: (state, action) => {
      return { ...state, sidebarShow: action.payload }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSidebar } = SidebarSlice.actions

export default SidebarSlice.reducer
