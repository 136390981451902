import { TServiceItem } from '../../api/mock/typesApi'
import React, { useState } from 'react'
import styles from './Service.module.scss'
import ServiceItem from './ServiceItem'
import { CContainer } from '@coreui/react'
import ServiceModal from './ServiceModal'
import { RootState } from '../../store/store'
import { useSelector } from 'react-redux'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'

interface IService {
  items: TServiceItem[] | []
}

const Service: React.FC<IService> = ({ items }) => {
  const loading = useSelector((state: RootState) => state.AboutSlice.loadings)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [itemss, setItemss] = useState<any>()
  console.log(items)
  return (
    <>
      <SkeletonLoading
        style={{ minHeight: '605px', borderRadius: '16px' }}
        loading={checkLoadings(loading)}
      >
        <CContainer fluid>
          <div className={styles.service}>
            <div className={styles.service__header}>Наши услуги</div>
            <div className={styles.service__items}>
              {/* <CContainer> */}
              {items?.length
                ? items.map((item, index) => {
                    return (
                      <ServiceItem
                        key={index + ' serviceItem'}
                        payload={item}
                        setShowEditModal={setShowEditModal}
                        setItems={setItemss}
                      />
                    )
                  })
                : null}
              {/* </CContainer> */}
            </div>
          </div>
        </CContainer>
      </SkeletonLoading>
      <ServiceModal
        payload={itemss}
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
      />
    </>
  )
}

export default Service
