/* eslint-disable prettier/prettier */
import { CContainer, CForm, CFormCheck, CFormInput, CFormTextarea } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { TNomenclatures, TSliderItem } from '../../api/mock/typesApi'
import Modal from '../common/Modal/Modal'
import SliderEditList from '../slider/SliderEditList'
import styles from './GalleryBig.module.scss'
import InputImage from '../common/InputImage/InputImage'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { CreateSlide, getSlider } from '../../store/slices/SliderSlice'
import { SaveFile } from '../../store/slices/FileSlice'
import { editAboutMain, getAboutMain, getGalleryImages } from '../../store/slices/AboutSlice'
import EditGalleryImage from './GalleryEditImage'
import { EditOrderPhoto } from '../../store/slices/CategoriesSlice'

interface IGalleryBigModal {
  open: boolean
  handleClose: (val?: any) => void
  payload: any | null
  saveHandler: (payload: TSliderItem) => void
}

const GalleryBigModal: React.FC<IGalleryBigModal> = ({
  open,
  handleClose,
  payload,
  saveHandler,
}) => {
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<any>(payload)
  const [file, setFile] = useState<any>(null)
  const [orderList, setOrderList] = useState<any[] | []>([])
  const [currentOrder, setCurrentOrder] = useState<any>([])
  const [currentItem, setCurrentItem] = useState<any>()
  useEffect(() => {
    setModel(payload)
    console.log(model)
    console.log(payload)
  } ,[])
  useEffect(() => {
    setOrderList(
      payload?.length
        ? payload?.map((images: any, idx: any) => {
            return {
              ...images,
              idx: idx,
            }
          })
        : [],
    )
  }, [payload])
  function dragStartHandler(e: any, item: any, index: number) {
    setCurrentOrder({ item: item, index: index })
    console.log('dragsS', item)
  }

  function dragLeaveHandler(e: any) {
    e.currentTarget.classList.remove(styles.item__over)
  }

  function dragEndHandler(e: any) {
    // e.target.style.background = 'white'
  }

  function dragOverHandler(e: any) {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.classList.add(styles.item__over)
  }
  function dropHandler(e: any, item: any, index: number) {
    const buffer = (item: any) => {
      return { ...item, index: index }
    }
    console.log(buffer(item).index)
    e.currentTarget.classList.remove()
    e.preventDefault()
    let arr: any[] = []
    if (item !== currentItem) {
      arr = orderList.map((i) => {
        //@ts-ignore
        if (buffer(item).index < currentOrder.index) {
          if (i.idx < buffer(item).index) {
            return { ...i }
          }
          
          //@ts-ignore
          if (i.idx === currentOrder.index) {
            return { ...i, idx: buffer(item).index }
          }
          
          //@ts-ignore
          if (i.idx >= buffer(item).index && i.idx < currentOrder.index) {
            return { ...i, idx: i.idx + 1 }
          }
          
          return { ...i }
        } else {
          //@ts-ignore
          if (buffer(item).index > currentOrder.index) {
            if (i.idx > buffer(item).index) {
              return { ...i }
            }
            
            //@ts-ignore
            if (i.idx === currentOrder.index) {
              return { ...i, idx: buffer(item).index }
            }
            
            //@ts-ignore
            if (i.idx <= buffer(item).index && i.idx > currentOrder.index) {
              return { ...i, idx: i.idx - 1 }
            }
            
            return { ...i }
          }
        }
        
      })
    } else {
      arr = orderList
      
    }
    console.log(orderList)
    console.log(item)
    console.log(arr)
    const SortOrder = (a: any, b: any) => {
      if (b.idx > a.idx) {
        return 1
      } else {
        return -1
      }
    }
    dispatch(
      EditOrderPhoto({
        dataType: 'InformationArticle',
        elementId: 'Gallery',
        fileNames: arr.sort(SortOrder).map((item) => item.name),
      }),
    ).then(
      () =>
        dispatch(
          getGalleryImages(),
        ),
      setOrderList(arr),
    )
  }

  return (
    <Modal
      header={'Редактирование порядка'}
      open={open}
      handleClose={handleClose}
      onOk={() => console.log('')}
      okText={'Сохранить'}
    >
      <CContainer fluid>
      <div className={styles.gridArea}>
                {payload?.map((item: any, index: any) => (
                  <div
                    key={index + ' li'}
                    id={String(index)}
                    className={styles.liDiv}
                    draggable={true}
                    onDragStart={(e) => dragStartHandler(e, item, index)}
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragEnd={(e) => dragEndHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, item, index)}
                    title={item?.name}
                  >
                    <EditGalleryImage item={item} idx={index} />
                  </div>
                ))}
              </div>
      </CContainer>
    </Modal>
  )
}

export default GalleryBigModal
