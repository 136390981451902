// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SeoComponent_main__-e3-K {
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.SeoComponent_main__-e3-K .SeoComponent_selector__e6vMA {
  width: 330px;
}
.SeoComponent_main__-e3-K .SeoComponent_title__rBXeV {
  width: 400px;
}
.SeoComponent_main__-e3-K .SeoComponent_description__4d80T {
  width: 500px;
}`, "",{"version":3,"sources":["webpack://./src/components/SeoComponent/SeoComponent.module.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAAI;EACI,YAAA;AAER;AAAI;EACI,YAAA;AAER;AAAI;EACI,YAAA;AAER","sourcesContent":[".main{\n    padding: 20px 20px 20px 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    .selector{\n        width: 330px;\n    }\n    .title{\n        width: 400px;\n    }\n    .description{\n        width: 500px;\n    }\n    .keywords{\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `SeoComponent_main__-e3-K`,
	"selector": `SeoComponent_selector__e6vMA`,
	"title": `SeoComponent_title__rBXeV`,
	"description": `SeoComponent_description__4d80T`
};
export default ___CSS_LOADER_EXPORT___;
