// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReviewEditModal_form__formInput__h0i7g {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ReviewCard/ReviewEditModal.module.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR","sourcesContent":[".form{\n    &__formInput{\n        margin-top: 10px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form__formInput": `ReviewEditModal_form__formInput__h0i7g`
};
export default ___CSS_LOADER_EXPORT___;
