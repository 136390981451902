import Service from '../../components/Service/Service'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getService } from '../../store/slices/AboutSlice'
import { RootState } from '../../store/store'
import { CContainer } from '@coreui/react'
import { getCookie } from '../../helpers/cookies'
import { openNotification } from '../../components/common/Notification/Notification'

interface IService {}

const ServiceView: React.FC<IService> = () => {
  const service = useSelector((state: RootState) => state.AboutSlice.serviceInfo)
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(getService())
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  return (
    <div>
      <CContainer fluid className="bg-white p-10 m-0 rounded" style={{ paddingBottom: '100px' }}>
        <Service items={service} />
      </CContainer>
    </div>
  )
}

export default ServiceView
