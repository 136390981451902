export const paths = {
  baseURL: 'https://api.satellityug.ru/api/',
  // baseURL: 'http://192.168.222.210:13003/api/',
  accountService: {
    authorization: 'account/login',
  },
  categoriesService: {
    // categories: 'categories',
    category: 'category',
    tab: 'tab',
    tabInfo: 'tabInfo',
  },
  sliderService: {
    slide: 'slide',
    nomenclature: 'nomenclature',
  },
  fileService: {
    saveFile: 'file/SaveFileByte',
    deleteFile: 'file/DeleteFile',
    orderFile: 'file/editFilesOrder',
  },
  reviewsService: {
    review: 'review',
  },
  informationService: {
    information: 'requisites',
    contacts: 'contacts',
    contact: 'contact',
  },
  informationArticleService: {
    informationArticle: 'informationArticle',
  },
  aboutService: {
    about: 'aboutPoster',
  },
  aboutNew: {
    aboutNew: 'about',
  },
  seoService: {
    seo: 'ceo',
  },
}
