import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Tab } from 'react-tabs'
import styles from './TabsComponent.module.scss'
import Trash from '../../assets/icons/trash.svg'
import { CContainer, CForm, CFormInput } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCheckAlt, cilPencil } from '@coreui/icons'
import JoditEditor from 'jodit-react'
import { EditTabContent, getCategoryItem } from '../../store/slices/CategoriesSlice'
import { useDispatch } from 'react-redux'

interface ITabContentComponent {
  item: any
  deleteTabContentHandler: Function
  tabId: any
  categoryId: any
}

const TabContentComponent: React.FC<ITabContentComponent> = ({
  item,
  deleteTabContentHandler,
  tabId,
  categoryId,
}) => {
  const dispatch = useDispatch<any>()
  const [editableTitle, setEditableTitle] = useState(item?.title)
  const [editableDescription, setEditableDescription] = useState(item?.description)
  const [edit, setEdit] = useState<boolean>(false)
  const editor = useRef(null)
  const config = {
    readonly: false,
    language: 'ru',
    style: {
      font: '12px Arial',
      color: '#0c0c0c',
      height: '190px',
    },
    toolbarSticky: false,
    ssr: false,
  }
  const acceptEditHandler = () => {
    dispatch(
      EditTabContent({
        title: editableTitle,
        description: editableDescription,
        tabId: tabId,
        id: item.id,
      }),
    ).then(() => {
      dispatch(
        getCategoryItem({
          id: categoryId,
        }),
      )
    })
  }
  return (
    <CContainer
      fluid
      className="bg-white border table-responsive"
      style={{ borderRadius: '16px', margin: '10px 0 10px 0', position: 'relative' }}
    >
      <div className={styles.tabs__tabPanel__contentPanel}>
        {edit ? (
          <div
            className={styles.tabs__tabPanel__contentPanel__EditContentTab}
            onClick={() => {
              setEdit(!edit)
            }}
          >
            <CIcon
              size="xl"
              className={styles.tabs__tabPanel__contentPanel__EditContentTab__AcceptButton}
              icon={cilCheckAlt}
              style={{ color: 'black' }}
              height={19}
              width={19}
              onClick={() => {
                acceptEditHandler()
              }}
            />
          </div>
        ) : (
          <div
            style={{ marginLeft: '5px' }}
            className={styles.tabs__tabPanel__contentPanel__Pencil}
            onClick={() => {
              setEdit(!edit)
            }}
          >
            <CIcon
              icon={cilPencil}
              style={{ color: 'black' }}
              className={styles.tabs__tabPanel__contentPanel__Pencil__PencilButton}
              // height={19}
              // width={19}
            />
          </div>
        )}
        <div className={styles.tabs__tabPanel__contentPanel__trashBlock}>
          <img
            onClick={() => {
              deleteTabContentHandler(item.id)
            }}
            src={Trash}
            height={19}
            width={19}
            alt="delete"
            className={styles.trashButton}
          />
        </div>
        {edit ? (
          <div className={styles.tabs__tabPanel__contentPanel__editableMain}>
            <div className={styles.tabs__tabPanel__contentPanel__editableMain__title}>
              <CForm>
                <CFormInput
                  value={editableTitle}
                  onChange={(e) => {
                    setEditableTitle(e.target.value)
                  }}
                ></CFormInput>
              </CForm>
            </div>
            <div
              className={styles.tabs__tabPanel__contentPanel__editableMain__content}
              style={{ marginLeft: '20px' }}
            >
              <JoditEditor
                ref={editor}
                value={editableDescription}
                //{tab?.content}
                config={config}
                // tabIndex={1} // tabIndex of textarea
                onBlur={(newContent: any) => setEditableDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                // onChange={(newContent: any) => {}}
              />
            </div>
          </div>
        ) : (
          <div className={styles.tabs__tabPanel__contentPanel__main}>
            <div className={styles.tabs__tabPanel__contentPanel__main__titleMain}>{item.title}</div>
            <div
              className={styles.tabs__tabPanel__contentPanel__main__contentMain}
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            >
              {/* {item.description} */}
            </div>
          </div>
        )}
      </div>
    </CContainer>
  )
}

export default TabContentComponent
