/* eslint-disable jsx-a11y/alt-text */

import { cilPencil } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import React, { CSSProperties, RefObject, useState } from 'react'
import { editableBlockStyle, useHover } from '../../helpers/hoverHelper'
import Button from '../common/Button/Button'
import styles from './slider.module.scss'
import SliderEditModal from './SliderEditModal'
import Trash from '../../assets/icons/trash.svg'
import { TNomenclatures, TSliderItem } from '../../api/mock/typesApi'
import { DeleteSlide, getSlider } from '../../store/slices/SliderSlice'
import { useDispatch } from 'react-redux'

export interface ISliderItem {
  id: number
  name: string
  backgroundImage: string
  titleMain: string
  description: string
  itemList: TNomenclatures[] | []
  supportButton: boolean
  catalogButton: boolean
  style?: CSSProperties
  isMobile?: boolean
  nomenclatures?: any
  deleteHandler: () => void
  editSliderHandler: (payload: TSliderItem) => void
}

const SliderItem: React.FC<ISliderItem> = ({
  id,
  name,
  backgroundImage,
  titleMain,
  description,
  itemList,
  supportButton,
  catalogButton,
  style,
  isMobile,
  deleteHandler,
  editSliderHandler,
  nomenclatures,
}) => {
  const sliderHover = useHover(
    { ...editableBlockStyle, left: `calc(9% - 6px)` },
    {
      backgroundColor: '',
      borderRadius: '',
      padding: '',
      outline: '',
      left: '9%',
    },
  )

  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const dispatch = useDispatch<any>()
  const deleteSlider = () => {
    dispatch(
      DeleteSlide({
        id: id,
      }),
    ).then(() => dispatch(getSlider()))
  }
  return (
    <>
      <div className={styles.sliderItemMain}>
        <div
          className={styles.editIcon}
          onMouseEnter={() => {
            sliderHover.onMouseEnter()
          }}
          onMouseLeave={() => {
            sliderHover.onMouseLeave()
          }}
          onClick={() => {
            setShowEditModal(true)
          }}
        >
          <CIcon icon={cilPencil} style={{ color: 'black' }} />
        </div>
        <div className={styles.trashIcon}>
          <img
            onClick={() => {
              deleteSlider()
            }}
            src={Trash}
            className={styles.trashButton__button}
            alt="delete"
          />
        </div>
        {isMobile ? (
          <div
            className={styles.sliderItemBackground}
            style={{
              backgroundImage: `radial-gradient(circle, rgba(0, 0, 0, 0.1) 60%, #1f3b59, transparent 120%), url(${backgroundImage})`,
            }}
          ></div>
        ) : (
          <div
            className={styles.sliderItemBackground}
            style={{
              backgroundImage: `linear-gradient(to right, #1f3b59 25%, transparent 100%), url(${backgroundImage})`,
            }}
          ></div>
        )}
        <div className={styles.sliderItemMain__content} ref={sliderHover.currentRef}>
          <div className={styles.sliderItemMain__content__title}>
            <h1 dangerouslySetInnerHTML={{ __html: titleMain }}></h1>
            {/* <h1>{titleMain}</h1> */}
          </div>
          <div className={styles.sliderItemMain__content__description}>
            <div>{description}</div>
          </div>
          <div className={styles.sliderItemMain__content__itemList}>
            <ul>
              {itemList ? (
                itemList.map((item, index) => {
                  return (
                    <li key={index + ' traktor'}>
                      <span>{item.name}</span>
                    </li>
                  )
                })
              ) : (
                <></>
              )}
            </ul>
          </div>
          <div className={styles.sliderItemMain__content__buttons}>
            {supportButton ? (
              <Button
                className={styles.sliderItemMain__content__buttons__button}
                color={'secondary'}
              >
                Связаться с нами
              </Button>
            ) : null}
            {catalogButton ? (
              <Button className={styles.sliderItemMain__content__buttons__button}>
                Каталог продукции
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <SliderEditModal
        payload={{
          id,
          name,
          backgroundImage,
          titleMain,
          description,
          itemList,
          supportButton,
          catalogButton,
        }}
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        saveHandler={editSliderHandler}
      />
    </>
  )
}

export default SliderItem
