import { getSlider } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { ToastContainer, toast } from 'react-toastify'
import Button from '../../components/common/Button/Button'
import ContactsComponent from '../../components/contacts/ContactsComponent'
import React, { useEffect } from 'react'
import { getCategories } from '../../store/slices/CategoriesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CContainer } from '@coreui/react'
import CategoriesComponent from '../../components/categories/CategoriesComponent'
import AboutCompanyComponent from '../../components/aboutCompany/AboutCompanyComponent'
import { getAboutBlock, getAboutMain, getAboutPoster } from '../../store/slices/AboutSlice'
import AboutMainComponent from '../../components/aboutCompanyMain/About'
import { getCookie } from '../../helpers/cookies'
import { openNotification } from '../../components/common/Notification/Notification'

const AboutMain: React.FC = ({}) => {
  const dispatch = useDispatch<any>()
  useEffect(() => {
    dispatch(getAboutMain())
  }, [])
  useEffect(() => {
    if (getCookie('authTokenUrl') === undefined) {
      window.location.replace('/login')
      openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
    }
  }, [])
  const aboutMain = useSelector((state: RootState) => state.AboutSlice.aboutMain)
  const loadings = useSelector((state: RootState) => state.ReviewsSlice.loadings)
  return (
    <CContainer fluid className="bg-white p-0 m-0 rounded">
      <AboutMainComponent payload={aboutMain} loadings={loadings} />
    </CContainer>
  )
}

export default AboutMain
