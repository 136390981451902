/* eslint-disable react/jsx-key */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styles from './TabsComponent.module.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { TCategoryItem } from '../../api/mock/typesApi'
import JoditEditor, { Jodit } from 'jodit-react'
import CIcon from '@coreui/icons-react'
import { cilCheckAlt, cilPencil, cilPlus } from '@coreui/icons'
import Trash from '../../assets/icons/trash.svg'
import { CContainer, CForm, CFormInput } from '@coreui/react'
import { useDispatch } from 'react-redux'
import {
  CreateTab,
  CreateTabContent,
  DeleteTab,
  DeleteTabContent,
  getCategoryItem,
} from '../../store/slices/CategoriesSlice'
import TabComponent from './Tab'
import TabContentComponent from './TabContent'

export type TTabsItem = {
  id?: number
  title: string
  content?: any
}
interface ITabsComponent {
  items?: TTabsItem[] | []
  category?: TCategoryItem
}

const TabsComponent: React.FC<ITabsComponent> = ({ items, category }) => {
  const dispatch = useDispatch<any>()
  const [newItem, setNewItem] = useState<boolean>(false)
  const [newItemValue, setNewItemValue] = useState<string>('')
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [createTabBlock, setCreateTabBlock] = useState<boolean>(false)
  const [newTitle, setNewTitle] = useState<any>('')
  const [newDescription, setNewDescription] = useState<any>('')
  const config = {
    readonly: false,
    language: 'ru',
    style: {
      font: '12px Arial',
      color: '#0c0c0c',
      height: '190px',
    },
    toolbarSticky: false,
    ssr: false,
  }
  const setNewItemHandler = (val: string) => {
    setNewItem(false)
    setNewItemValue('')
  }
  const PutTab = () => {
    dispatch(
      CreateTab({
        title: newItemValue,
        categoryId: category?.id,
      }),
    ).then(() =>
      dispatch(
        getCategoryItem({
          id: category?.id,
        }),
      ),
    )
    setNewDescription('')
    setNewTitle('')
    setNewItem(false)
  }

  console.log(items)
  const editor = useRef(null)
  // if (items?.length) {
  return (
    <Tabs className={styles.tabs} onSelect={(index: number) => setSelectedIndex(index)}>
      <CContainer fluid className="bg-white border table-responsive">
        <TabList className={styles.tabs__tabList}>
          {items?.map((tab, index) => {
            const deleteTabHandler = () => {
              dispatch(
                DeleteTab({
                  id: tab?.id,
                }),
              ).then(() =>
                dispatch(
                  getCategoryItem({
                    id: category?.id,
                  }),
                ),
              )
            }
            return (
              // eslint-disable-next-line react/jsx-key
              <Tab
                key={index + ' tab'}
                className={styles.tabs__tabList__tab}
                style={{ position: 'relative' }}
              >
                <TabComponent
                  index={index}
                  selectedIndex={selectedIndex}
                  deleteTabHandler={deleteTabHandler}
                  tab={tab}
                  category={category}
                />
              </Tab>
            )
          })}
          <div className={styles.newTab}>
            {!newItem ? (
              <div className={styles.plusIcon}>
                <CIcon
                  size="xl"
                  icon={cilPlus}
                  onClick={() => {
                    setNewItem(true)
                  }}
                />
              </div>
            ) : (
              <div className={styles.newItem}>
                <CFormInput
                  value={newItemValue}
                  onChange={(e) => setNewItemValue(e.target.value)}
                  className={styles.newItem__input}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      setNewItemHandler(newItemValue)
                    }
                  }}
                />
                <CIcon
                  size="xl"
                  className={styles.check}
                  icon={cilCheckAlt}
                  onClick={() => {
                    PutTab()
                  }}
                />
              </div>
            )}
          </div>
        </TabList>
      </CContainer>

      {items?.map((tab, index) => {
        const setUndefined = () => {
          setNewDescription('')
          setNewTitle('')
          setCreateTabBlock(false)
        }
        const deleteTabContentHandler = (id: any) => {
          dispatch(
            DeleteTabContent({
              id: id,
            }),
          ).then(() =>
            dispatch(
              getCategoryItem({
                id: category?.id,
              }),
            ),
          )
        }
        const createTabContentHandler = () => {
          dispatch(
            CreateTabContent({
              title: newTitle,
              description: newDescription,
              tabId: tab?.id,
            }),
          )
            .then(() =>
              dispatch(
                getCategoryItem({
                  id: category?.id,
                }),
              ),
            )
            .then(() => setUndefined())
        }
        return (
          <TabPanel key={index + ' tabContent'} className={styles.tabs__tabPanel}>
            {tab?.content?.map((item: any) => {
              return (
                <>
                  <TabContentComponent
                    item={item}
                    deleteTabContentHandler={deleteTabContentHandler}
                    tabId={tab?.id}
                    categoryId={category?.id}
                  />
                </>
              )
            })}
            <CContainer
              fluid
              className="bg-white border table-responsive"
              style={{
                borderRadius: '16px',
                margin: '10px 0 10px 0',
                height: '450px',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                backgroundColor: 'white',
                position: 'relative',
              }}
            >
              {createTabBlock ? (
                <div className={styles.AddBlock}>
                  <CIcon
                    size="xl"
                    className={styles.check}
                    icon={cilCheckAlt}
                    onClick={() => {
                      createTabContentHandler()
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {!createTabBlock ? (
                <div className={styles.tabs__tabPanel__plusIconNew}>
                  <CIcon
                    className={styles.tabs__tabPanel__plusIconNew__icon}
                    size="xl"
                    width={48}
                    height={48}
                    icon={cilPlus}
                    onClick={() => {
                      setCreateTabBlock(!createTabBlock)
                    }}
                  />
                </div>
              ) : (
                <div className={styles.tabs__tabPanel__contentPanel__new}>
                  <div className={styles.tabs__tabPanel__contentPanel__new__titleNew}>
                    <CForm>
                      <CFormInput
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                      ></CFormInput>
                    </CForm>
                  </div>
                  <div
                    className={styles.tabs__tabPanel__contentPanel__new__contentNew}
                    style={{ marginLeft: '20px' }}
                  >
                    <JoditEditor
                      ref={editor}
                      value={newDescription}
                      //{tab?.content}
                      config={config}
                      // tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent: any) => setNewDescription(newContent)} // preferred to use only this option to update the content for performance reasons
                      // onChange={(newContent: any) => {}}
                    />
                  </div>
                </div>
              )}
            </CContainer>
          </TabPanel>
        )
      })}
    </Tabs>
  )
  // } else {
  //   return <></>
  // }
}

export default TabsComponent
