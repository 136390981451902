import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { TDeleteFile, TSaveFile } from '../../api/mock/typesApi'
import { instanceAxiosClose } from '../api/instances'
import { paths } from '../api/paths'
import { getCookie } from '../../helpers/cookies'
import path from 'path'
import { openNotification } from '../../components/common/Notification/Notification'

export interface IFileSlice {
  loadings: {
    saveFile: boolean
  }
  fileInfo: TSaveFile | undefined
  deleteFileInfo:
    | {
        Type: string
        Flag: string
        Id: number
        Name: string
      }
    | undefined
}
const initialState: IFileSlice = {
  loadings: {
    saveFile: false,
  },
  fileInfo: undefined,
  deleteFileInfo: undefined,
}
export const DeleteFile = createAsyncThunk('file/deleteFile', async (body: TDeleteFile) => {
  const data = await instanceAxiosClose().post(paths.fileService.deleteFile, body)
  return data?.data
})

export const SaveFile = createAsyncThunk<any, { formData: any }>(
  'file/saveFile',
  async (formData) => {
    const newFormData = new FormData()
    newFormData.append('Files', formData.formData.Files)
    newFormData.append('Json', formData.formData.Json)
    var myHeaders = new Headers({
      Authorization: `Bearer ${String(getCookie('authTokenUrl'))}`,
    })
    const prom = fetch(paths.baseURL + paths.fileService.saveFile, {
      method: 'POST',
      headers: myHeaders,
      body: newFormData,
    })

    return prom
    // const { data } = await instanceAxiosClose().post(paths.fileService.saveFile)
    // return data?.data
  },
)

export const SaveFilesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(SaveFile.fulfilled, (state, action) => {
      state.loadings.saveFile = false
      openNotification({ type: 'success', text: 'Файл успешно загружен' })
    })
    builder.addCase(SaveFile.pending, (state, action) => {
      state.loadings.saveFile = true
    })
    builder.addCase(SaveFile.rejected, (state, action) => {
      state.loadings.saveFile = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
    builder.addCase(DeleteFile.fulfilled, (state, action) => {
      state.loadings.saveFile = false
      openNotification({ type: 'success', text: 'Файл успешно удален' })
    })
    builder.addCase(DeleteFile.pending, (state, action) => {
      state.loadings.saveFile = true
    })
    builder.addCase(DeleteFile.rejected, (state, action) => {
      state.loadings.saveFile = false
      if (action?.error?.message?.includes('401')) {
        window.location.replace('/login')
        openNotification({ type: 'error', text: 'Не авторизованный пользователь' })
      } else {
        openNotification({ type: 'error', text: 'Произошла ошибка, повторите позже' })
      }
    })
  },
})

export default SaveFilesSlice.reducer
