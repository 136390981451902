/* eslint-disable react/jsx-key */
import { cilCheck, cilCheckAlt, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CFormInput, CFormSelect } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import styles from '../slider/slider.module.scss'
import Trash from '../../assets/icons/trash.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getNomenclatures } from '../../store/slices/SliderSlice'
import { RootState } from '../../store/store'
import { TNomenclatures } from '../../api/mock/typesApi'

interface ISeoEditList {
  items: string[] | []
  setItems: (val: string[] | []) => void
}

const SeoEditList: React.FC<ISeoEditList> = ({ items, setItems }) => {
  const handleDelete = (index: number) => {
    setItems(items.filter((_item, idx) => idx !== index))
  }
  const [newItem, setNewItem] = useState<boolean>(false)
  const [newItemValue, setNewItemValue] = useState<string>('')
  const setNewItemHandler = (val: string) => {
    setItems([...items, val])
    setNewItem(false)
    setNewItemValue('')
  }
  const [currentItem, setCurrentItem] = useState<any>()

  return (
    <div className={styles.itemList}>
      <ul>
        {items?.length > 0 ? (
          items?.map((item, index) => {
            return (
              <div
                key={index + ' li'}
                id={String(index)}
                className={styles.liDiv}
                title={item?.length >= 12 ? item : ''}
              >
                <div className={styles.trashButton}>
                  <img
                    onClick={() => {
                      handleDelete(index)
                    }}
                    src={Trash}
                    className={styles.trashButton__button}
                    alt="delete"
                  />
                </div>
                <li>
                  <span>{item}</span>
                </li>
              </div>
            )
          })
        ) : (
          <></>
        )}
        {!newItem ? (
          <div className={styles.plusIcon}>
            <CIcon
              size="xl"
              icon={cilPlus}
              onClick={() => {
                setNewItem(true)
              }}
            />
          </div>
        ) : (
          <div className={styles.newItem}>
            <CFormInput
              value={newItemValue}
              onChange={(e) => setNewItemValue(e.target.value)}
              className={styles.newItem__input}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  setNewItemHandler(newItemValue)
                }
              }}
            />
            <CIcon
              size="xl"
              className={styles.check}
              icon={cilCheckAlt}
              onClick={() => {
                setNewItemHandler(newItemValue)
              }}
            />
          </div>
        )}
      </ul>
    </div>
  )
}

export default SeoEditList
