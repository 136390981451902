import React, { useEffect, useState } from 'react'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import FullsizeGallery from '../FullsizeGallery/FullsizeGallery'
import styles from './GalleryBig.module.scss'
import ArrowBottom from '../../assets/icons/ArrowBottom.svg'
import Trash from '../../assets/icons/trash.svg'
import CIcon from '@coreui/icons-react'
import { cilPencil, cilPlus } from '@coreui/icons'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { deleteGalleryImages, getGalleryImages } from '../../store/slices/AboutSlice'
import { SaveFile } from '../../store/slices/FileSlice'
import GalleryBigModal from './GallryBigModal'
import EditGalleryImage from './GalleryEditImage'
import { EditOrderPhoto } from '../../store/slices/CategoriesSlice'

interface IGalleryBig {
  images: string[]
}

const GalleryBig: React.FC<IGalleryBig> = ({ images }) => {
  const loading = useSelector((state: RootState) => state.AboutSlice.loadings)
  const dispatch = useDispatch<any>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openOrderModal, setOpenOrderModal] = useState<boolean>(false)
  const [swiper, setSwiper] = useState<any>(null)
  const [grab, setGrab] = useState<boolean>(false)
  const [openPreview, setOpenPreview] = useState<number | null>(null)
  const [orderList, setOrderList] = useState<any[] | []>([])
  const [currentOrder, setCurrentOrder] = useState<any>([])
  const [currentItem, setCurrentItem] = useState<any>()
  const hiddenFileInputMain: any = React.createRef()
  const [image, setImage] = useState<File>()

  useEffect(() => {
    console.log(images)
  }, [images])

  const pagination = {
    clickable: true,
    horizontalClass: styles.paginationBlock,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + ' ' + styles.paginationBullet + '">' + '</span>'
    },
  }

  const handlePrev = () => {
    if (!swiper) return
    swiper.slidePrev()
  }

  const handleNext = () => {
    if (!swiper) return
    swiper.slideNext()
  }

  const manuallyChangeSlide = (activeIndex: number) => {
    swiper.slideTo(activeIndex)
  }
  useEffect(() => {
    if (image) {
      console.log(hiddenFileInputMain.current?.focus())
      hiddenFileInputMain.current?.focus()
      const reader = new FileReader()
      reader.readAsDataURL(image)
    }
    console.log(image)
    const formData = new FormData()
    if (image) {
      formData.append('Files', image)
      formData.append(
        'Json',
        JSON.stringify({
          Flag: 'I',
          Type: 'InformationArticle',
          Id: 'Gallery',
          Files: [
            {
              Name: image.name,
              Extention: image.name.split('.')[image.name.split('.').length - 1],
              DataType: 2,
              Rewrite: true,
            },
          ],
        }),
      )
      let FormDataNew = {
        Files: image,
        Json: JSON.stringify({
          Flag: 'I',
          Type: 'InformationArticle',
          Id: 'Gallery',
          Files: [
            {
              Name: image.name,
              Extention: image.name.split('.')[image.name.split('.').length - 1],
              DataType: 2,
              Rewrite: true,
            },
          ],
        }),
      }
      dispatch(
        SaveFile({
          formData: FormDataNew,
        }),
      ).then(() => dispatch(getGalleryImages()))
    }
  }, [image])

  //Порядок
  useEffect(() => {
    setOrderList(
      images?.length
        ? images?.map((images: any, idx: any) => {
            return {
              images,
              idx: idx,
            }
          })
        : [],
    )
    console.log(
      images.map((images: any, idx: any) => {
        return {
          images,
          idx: idx,
        }
      }),
    )
  }, [images])
  function dragStartHandler(e: any, item: any, index: number) {
    setCurrentOrder({ item: item, index: index })
    console.log('dragsS', item)
  }

  function dragLeaveHandler(e: any) {
    e.currentTarget.classList.remove(styles.item__over)
  }

  function dragEndHandler(e: any) {
    // e.target.style.background = 'white'
  }

  function dragOverHandler(e: any) {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.classList.add(styles.item__over)
  }
  function dropHandler(e: any, item: any, index: number) {
    const buffer = (item: any) => {
      return { item, index: index }
    }
    console.log(buffer(item).index)
    e.currentTarget.classList.remove()
    e.preventDefault()
    let arr: any[] = []
    if (item !== currentItem) {
      arr = orderList.map((i) => {
        //@ts-ignore
        if (buffer(item).index < currentOrder.index) {
          if (i.idx < buffer(item).index) {
            return { ...i }
          }

          //@ts-ignore
          if (i.idx === currentOrder.index) {
            return { ...i, idx: buffer(item).index }
          }

          //@ts-ignore
          if (i.idx >= buffer(item).index && i.idx < currentOrder.index) {
            return { ...i, idx: i.idx + 1 }
          }

          return { ...i }
        } else {
          //@ts-ignore
          if (buffer(item).index > currentOrder.index) {
            if (i.idx > buffer(item).index) {
              return { ...i }
            }

            //@ts-ignore
            if (i.idx === currentOrder.index) {
              return { ...i, idx: buffer(item).index }
            }

            //@ts-ignore
            if (i.idx <= buffer(item).index && i.idx > currentOrder.index) {
              return { ...i, idx: i.idx - 1 }
            }

            return { ...i }
          }
        }
      })
    } else {
      arr = orderList
    }
    console.log(orderList)
    console.log(item)
    console.log(arr)
    const SortOrder = (a: any, b: any) => {
      if (a.idx > b.idx) {
        return 1
      } else {
        return -1
      }
    }
    dispatch(
      EditOrderPhoto({
        dataType: 'InformationArticle',
        elementId: 'Gallery',
        fileNames: arr
          ?.sort(SortOrder)
          ?.map((item) => item?.images.split('/')[item?.images?.split('/').length - 1]),
      }),
    ).then(() => dispatch(getGalleryImages()), setOrderList(arr))
  }
  return (
    <>
      <div className={styles.sliderCards} style={{ marginTop: '3px' }}>
        <div className={styles.title} style={{ marginTop: '10px' }}>
          Галерея
        </div>
        <>
          <SkeletonLoading
            style={{ minHeight: '605px', borderRadius: '16px' }}
            loading={checkLoadings(loading)}
          >
            <div className={styles.mainSlider}>
              <Swiper
                pagination={pagination}
                modules={[Pagination]}
                spaceBetween={0}
                slidesPerView={1}
                initialSlide={0}
                onInit={(val: any) => setSwiper(val)}
                className={styles.sliderSwiper}
                onSlideChange={(val: any) => manuallyChangeSlide(val.activeIndex)}
              >
                {images?.length
                  ? images.map((image, index) => {
                      const deleteGalleryHandler = () => {
                        dispatch(
                          deleteGalleryImages({
                            id: 'Gallery',
                            name: image.split('/')[image.split('/').length - 1],
                            type: 'InformationArticle',
                            flag: 'D',
                          }),
                        ).then(() => dispatch(getGalleryImages()))
                      }
                      return (
                        <SwiperSlide key={index + 1 + ' card'}>
                          <div
                            className={styles.sliderImage}
                            style={grab ? { cursor: 'grabbing !important' } : {}}
                            onMouseDown={() => setGrab(true)}
                            onMouseUp={() => setGrab(false)}
                          >
                            <div
                              className={styles.edit}
                              onClick={(e) => {
                                e.stopPropagation()
                                setOpenModal(true)
                              }}
                            >
                              <CIcon
                                icon={cilPlus}
                                style={{ color: 'black' }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  hiddenFileInputMain.current?.click()
                                }}
                              />
                              <input
                                type="file"
                                ref={hiddenFileInputMain}
                                onChange={(e: any) => {
                                  const file = e?.target?.files[0]
                                  if (file && file.type.substring(0, 5) === 'image') {
                                    setImage(file)
                                  } else {
                                    setImage(undefined)
                                  }
                                }}
                                style={{ display: 'none' }}
                              />
                            </div>
                            {/* <div
                              className={styles.pencil}
                              onClick={(e) => {
                                e.stopPropagation()
                                setOpenOrderModal(true)
                              }}
                            >
                              <CIcon icon={cilPencil} style={{ color: 'black' }} />
                            </div> */}
                            <div
                              className={styles.trashIcon}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                            >
                              <img
                                onClick={() => {
                                  deleteGalleryHandler()
                                }}
                                src={Trash}
                                width={20}
                                height={20}
                                //   className={styles.trashButton__button}
                                alt="delete"
                              />
                            </div>
                            <img
                              src={image}
                              className={styles.sliderImage__img}
                              // objectPosition={'inherit'}
                              alt="category"
                              onClick={() => setOpenPreview(index)}
                            />
                          </div>
                        </SwiperSlide>
                      )
                    })
                  : null}
              </Swiper>
              {images?.length > 1 ? (
                <>
                  <div className={styles.prevArrow} onClick={handlePrev}>
                    <div className={styles.prevArrow__img}>
                      <img className={styles.prevArrow__img__svg} src={ArrowBottom} alt="arrow" />
                    </div>
                  </div>
                  <div className={styles.nextArrow} onClick={handleNext}>
                    <div className={styles.nextArrow__img}>
                      <img className={styles.nextArrow__img__svg} src={ArrowBottom} alt="arrow" />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </SkeletonLoading>
        </>
        <div className={styles.paginationEmptyBlock}></div>
        <div className={styles.title} style={{ marginTop: '10px' }}>
          Изменение порядка
        </div>
        <div className={styles.gridArea}>
          {images?.map((item: any, index: any) => (
            <div
              key={index + ' li'}
              id={String(index)}
              className={styles.liDiv}
              draggable={true}
              onDragStart={(e) => dragStartHandler(e, item, index)}
              onDragLeave={(e) => dragLeaveHandler(e)}
              onDragEnd={(e) => dragEndHandler(e)}
              onDragOver={(e) => dragOverHandler(e)}
              onDrop={(e) => dropHandler(e, item, index)}
              title={item?.name}
            >
              <EditGalleryImage item={item} idx={index} />
            </div>
          ))}
        </div>
      </div>
      <GalleryBigModal
        handleClose={() => setOpenOrderModal(false)}
        payload={images}
        open={openOrderModal}
        saveHandler={() => console.log('')}
      />
    </>
  )
}

export default GalleryBig
