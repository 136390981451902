// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_rating__Cjh0g {
  display: flex;
  align-items: center;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/Rating/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AACJ","sourcesContent":[".rating {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rating": `styles_rating__Cjh0g`
};
export default ___CSS_LOADER_EXPORT___;
