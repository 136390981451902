import { configureStore } from '@reduxjs/toolkit'
import SidebarSlice from './slices/SidebarSlice'
import AuthSlice from './slices/AuthSlice'
import ContactsSlice from './slices/ContactsSlice'
import SliderSlice from './slices/SliderSlice'
import CategoriesSlice from './slices/CategoriesSlice'
import ReviewsSlice from './slices/RatingSlice'
import AboutSlice from './slices/AboutSlice'
import SaveFileSlice from './slices/FileSlice'
import SeoSlice from './slices/SeoSlice'

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    SidebarSlice,
    AuthSlice,
    ContactsSlice,
    SliderSlice,
    CategoriesSlice,
    ReviewsSlice,
    AboutSlice,
    SaveFileSlice,
    SeoSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
