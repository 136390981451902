import React from 'react'
import styles from './Achievements.module.scss'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import Trash from '../../assets/icons/trash.svg'
import noPhoto from '../../assets/icons/placeholder.webp'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteAchievement, getAchievements } from '../../store/slices/AboutSlice'
import SkeletonLoading from '../Skeleton'
import { checkLoadings } from '../../helpers/loadings'
import { RootState } from '../../store/store'

interface IAchievementCard {
  payload: any
  openModal: Function
  deleteHandler: Function
  setItem: Function
}

const AchievementCart: React.FC<IAchievementCard> = ({
  payload,
  openModal,
  deleteHandler,
  setItem,
}) => {
  const loading = useSelector((state: RootState) => state.AboutSlice.loadings)
  const dispatch = useDispatch<any>()
  const handleOpenModal = () => {
    openModal()
    setItem(payload)
  }
  const deleteAchievementHandler = () => {
    dispatch(
      DeleteAchievement({
        id: payload.id,
      }),
    ).then(() => dispatch(getAchievements()))
  }
  return (
    <>
      <SkeletonLoading
        style={{ minHeight: '381px', maxWidth: '484px', borderRadius: '16px' }}
        loading={checkLoadings(loading)}
      >
        <div className={styles.achievementCard}>
          <div
            className={styles.achievementCard__edit}
            onClick={(e) => {
              e.stopPropagation()
              handleOpenModal()
            }}
          >
            <CIcon icon={cilPencil} style={{ color: 'black' }} />
          </div>
          <div
            className={styles.achievementCard__trashIcon}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <img
              onClick={() => {
                deleteAchievementHandler()
              }}
              src={Trash}
              width={20}
              height={20}
              //   className={styles.trashButton__button}
              alt="delete"
            />
          </div>
          <div></div>
          <div className={styles.achievementCard__title}>{payload?.title}</div>
          {/* <div className={styles.achievementCard__image}> */}
          {payload?.image ? (
            <img
              style={{ objectPosition: 'inherit' }}
              src={payload?.image}
              width={398}
              height={260}
              // objectPosition={'inherit'}
              alt="achievement"
            />
          ) : (
            <img
              style={{ objectPosition: 'inherit' }}
              src={noPhoto}
              width={'100%'}
              // objectPosition={'inherit'}
              alt="achievement"
            />
          )}

          {/* </div> */}
        </div>
      </SkeletonLoading>
    </>
  )
}

export default AchievementCart
