/* eslint-disable prettier/prettier */
import { CContainer, CForm, CFormCheck, CFormInput, CFormTextarea } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { TNomenclatures, TSliderItem } from '../../api/mock/typesApi'
import Modal from '../common/Modal/Modal'
import SliderEditList from '../slider/SliderEditList'
import styles from './About.module.scss'
import InputImage from '../common/InputImage/InputImage'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { CreateSlide, getSlider } from '../../store/slices/SliderSlice'
import { DeleteFile, SaveFile } from '../../store/slices/FileSlice'
import { editAboutMain, getAboutMain } from '../../store/slices/AboutSlice'

interface IAboutMainEditModal {
  open: boolean
  handleClose: (val?: any) => void
  payload: any | null
  saveHandler: (payload: TSliderItem) => void
  nomenclatures?: any
}

const AboutMainEditModal: React.FC<IAboutMainEditModal> = ({
  open,
  handleClose,
  payload,
  saveHandler,
  nomenclatures,
}) => {
  const dispatch = useDispatch<any>()
  const [model, setModel] = useState<any>(payload)
  const [file, setFile] = useState<any>(null)
  useEffect(() => {
    setModel(payload)
    console.log(model)
  } ,[payload])
  const saveAndCloseHandler = () => {
      dispatch(
        editAboutMain({
          id: model.id,
          titleMain: model.titleMain,
          description: model.description,
          nomenclatures: model.itemList,
        }),
      )
        .then(() => {
          if (file) {
            const formData = new FormData()
            formData.append('Files', file)
            formData.append(
              'Json',
              JSON.stringify({
                Flag: 'I',
                Id: model.id,
                Type: 'Slide',
                Files: [
                  {
                    Name: file?.name,
                    Extention: file?.name.split('.')[file.name.split('.').length - 1].toUpperCase(),
                    dataType: '2',
                    Rewrite: true,
                  },
                ],
              }),
            )
            dispatch(DeleteFile({
              Id: model.id,
              Type: 'Slide',
              Flag: 'D',
              Name: model?.backgroundImage?.split('/')[model?.backgroundImage?.split('/').length - 1],
            }))
            .then(() => dispatch(
              SaveFile({
                formData: {
                  Files: file,
                  Json: JSON.stringify({
                    Flag: 'I',
                    Id: model.id,
                    Type: 'Slide',
                    Files: [
                      {
                        Name: file?.name,
                        Extention: file?.name
                          .split('.')
                          [file.name.split('.').length - 1].toUpperCase(),
                        dataType: '2',
                        Rewrite: true,
                      },
                    ],
                  }),
                },
              }),
            )).then(() =>
              dispatch(getAboutMain())
            )
          }
        })
      handleClose()
    
  }

  return (
    <Modal
      header={payload ? 'Редактирование "О компании"' : 'Создание "О компании"'}
      open={open}
      handleClose={handleClose}
      onOk={() => saveAndCloseHandler()}
      okText={'Сохранить'}
    >
      <CContainer fluid>
        <CForm className={styles.form}>
          <div className={styles.form__formInput}>
            <span className="text-medium-emphasis">Заголовок</span>
            <CFormInput
              value={model?.titleMain}
              onChange={(e) => setModel({ ...model, titleMain: e.target.value })}
              placeholder="Заголовок"
            />
          </div>
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <span className="text-medium-emphasis">Описание</span>
            <CFormTextarea
              value={model?.description}
              rows={6}
              onChange={(e) => setModel({ ...model, description: e.target.value })}
              placeholder="Описание"
            />
          </div>
          <div className={styles.form__formInput} style={{ marginTop: '10px' }}>
            <span className="text-medium-emphasis">Список тракторов</span>
            <SliderEditList
              setItems={(val) => {
                setModel({
                  ...model,
                  itemList: val.map((item, idx) => ({
                    id: item?.id,
                    order: idx,
                    name: item?.name,
                  })),
                })
                console.log(model)
              }}
              items={model?.itemList}
              nomenclatures={nomenclatures}
            />
          </div>
          <div className={styles.form__formInput} style={{marginTop:'10px'}}>
            <InputImage setFile={setFile} />
          </div>
        </CForm>
      </CContainer>
    </Modal>
  )
}

export default AboutMainEditModal
