// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_pagination__mVvOv {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  -webkit-user-select: none;
          user-select: none;
}
.Pagination_pagination__number__INdDw {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 38px;
  height: 38px;
  border-radius: 48px;
}
.Pagination_pagination__dots__8uGtM {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.Pagination_pagination__arrow__0xKrH {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--light-grey-border);
  background-color: white;
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
.Pagination_pagination__arrow__img__88lwU {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 8px;
}

.Pagination_numberActive__VadIU {
  background-color: var(--secondary-color);
}

.Pagination_right__8UvFz {
  transform: rotate(180deg);
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.module.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;EACA,aAAA;EACA,SAAA;EACA,yBAAA;UAAA,iBAAA;AAAJ;AAEI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAAR;AAII;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAFR;AAKI;EACI,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,0CAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAHR;AAKQ;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAHZ;;AAWA;EACI,wCAAA;AARJ;;AAWA;EACI,yBAAA;AARJ","sourcesContent":["\n.pagination {\n    margin-top: 20px;\n    display: flex;\n    gap: 15px;\n    user-select: none;\n\n    &__number {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        cursor: pointer;\n        width: 38px;\n        height: 38px;\n        border-radius: 48px;\n\n    }\n\n    &__dots {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        cursor: pointer;\n        width: 48px;\n        height: 48px;\n        border-radius: 8px;\n    }\n\n    &__arrow {\n        cursor: pointer;\n        position: relative;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border: 1px solid var(--light-grey-border);\n        background-color: white;\n        width: 48px;\n        height: 48px;\n        border-radius: 8px;\n\n        &__img {\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            position: relative;\n            width: 14px;\n            height: 14px;\n            border-radius: 8px;\n        }\n    }\n\n\n\n}\n\n.numberActive {\n    background-color: var(--secondary-color);\n}\n\n.right {\n    transform: rotate(180deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `Pagination_pagination__mVvOv`,
	"pagination__number": `Pagination_pagination__number__INdDw`,
	"pagination__dots": `Pagination_pagination__dots__8uGtM`,
	"pagination__arrow": `Pagination_pagination__arrow__0xKrH`,
	"pagination__arrow__img": `Pagination_pagination__arrow__img__88lwU`,
	"numberActive": `Pagination_numberActive__VadIU`,
	"right": `Pagination_right__8UvFz`
};
export default ___CSS_LOADER_EXPORT___;
