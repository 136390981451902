import { TReviewItem } from '../../api/mock/typesApi'
import React, { useState } from 'react'
import Rating from '../Rating/Raiting'
import styles from './ReviewCard.module.scss'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import ReviewEditModal from './ReviewEditModal'
import Trash from '../../assets/icons/trash.svg'
import { useDispatch } from 'react-redux'
import { DeleteReview, getReviewsInfo } from '../../store/slices/RatingSlice'

interface IReviewCard {
  review: TReviewItem
  width?: string
  deleteHandler: Function
  pagination: any
}

const ReviewCard: React.FC<IReviewCard> = ({ review, width, deleteHandler, pagination }) => {
  const dispatch = useDispatch<any>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [reviewPayload, setReviewPayload] = useState<any>()
  const deleteRaitingHandler = () => {
    dispatch(
      DeleteReview({
        id: review.id,
      }),
    ).then(() => dispatch(getReviewsInfo(pagination)))
  }
  const openModal = () => {
    setShowEditModal(true)
    setReviewPayload(review)
    console.log(review)
  }
  return (
    <>
      <div className={styles.reviewCard} style={{ width: width }}>
        <div className={styles.reviewCard__rating}>
          <Rating rate={review.rating} flag={'none'} />
          <div
            className={styles.reviewCard__rating__trashIcon}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <img
              onClick={() => {
                deleteRaitingHandler()
              }}
              src={Trash}
              width={20}
              height={20}
              //   className={styles.trashButton__button}
              alt="delete"
            />
          </div>
          <div
            className={styles.reviewCard__rating__edit}
            onClick={() => {
              openModal()
            }}
          >
            <CIcon icon={cilPencil} style={{ color: 'black' }} />
          </div>
        </div>
        <div className={styles.reviewCard__text}>{review.reviewText}</div>
        <div className={styles.reviewCard__author}>
          <div className={styles.reviewCard__author__name}>{review.author}</div>
          <div className={styles.reviewCard__author__from}>{review.fromText}</div>
        </div>
      </div>
      <ReviewEditModal
        payload={{
          reviewPayload,
        }}
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        saveHandler={() => console.log()}
        pagination={pagination}
      />
    </>
  )
}

export default ReviewCard
